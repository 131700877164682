import * as React from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import TextBoxInput from '../../Common/ReusableComponents/TextBox';

export class AdminLogInProps {
  public showDialog: any;

  public onOkButton: any;
  public onCancelButton: any;
}
export default class AdminLogIn extends React.Component<AdminLogInProps, any> {

  public state = {
    showDialog: this.props.showDialog,
    password: '',
    alertError: ''
  };
  private handleValueChange(newValue: any, fieldName: string): void {

    this.setState({ [fieldName]: newValue.value });
  }
  public _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      this.logIn();
    }
  }
  public logIn = () => {
    // complare passowrd here with congif data
    if (this.state.password === 'logical12#') {
      this.setState({ showDialog: false });

      this.props.onOkButton();
    } else {
      this.setState({ alertError: 'Wrong password!' })
    }
  }
  public render(): React.ReactElement<any> {
    return (
      <div>

        <Popup
          width={300}
          maxHeight={300}
          minHeight={200}
          height={'auto'}
          showTitle={true}
          showCloseButton={false}
          title={'Admin List  Builder Log In'}
          dragEnabled={false}
          hideOnOutsideClick={false}
          visible={this.state.showDialog}
          // onContentReady={() => this.didMount()}
          //closeOnOutsideClick={true}
          onVisibleChange={this._closeDialog}


        >
          <div className='popContentDiv' style={{ fontSize: '16px' }}>
            <TextBoxInput
              stylingMode='underlined'
              label="Password"
              value={this.state.password}
              mode={'password'}
              onValueChanged={(e: any) => this.handleValueChange(e, 'password')}
              onKeyDown={(e: any) => this._handleKeyDown(e)}
            />
            {this.state.alertError && <span className='red'>Incorrect Password !!</span>}
          </div>


          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
              text: 'Cancel',
              stylingMode: "outlined",
              onClick: this._closeDialog,
            }}
          />

          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
              text: 'Ok',
              type: "success",
              stylingMode: "contained",
              onClick: this.logIn,
            }}
          />

        </Popup>
      </div>
    );
  }

  private _closeDialog = (): void => {
    this.setState({ showDialog: false });
    this.props.onCancelButton();
  }
  //   private _OKcloseDialog = (): void => {
  //     this.setState({ showDialog: false });
  //     this.props.onOkButton();
  //   }
}


