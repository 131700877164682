import React, { useState, useEffect, useRef } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import AlertDialog from '../../../Common/AlertDialog'
import utility from '../../../../classes/utilityOld'
import TextAreaInput from '../../../Common/ReusableComponents/TextArea'
import { Config } from '../../../../classes/config'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  currentUser: any
  workorder: any
  showModal: boolean
  onClick: () => void
  onClose: () => void
  callAfterInit: any
  context: any
  aadHttpClient: any
}

const AddNotes: React.FC<Props> = (props: Props) => {
  const [notesType, setNotesType] = useState('')
  const [notes, setNotes] = useState('')
  const [showButton, setShowButton] = useState(false)
  const [itemID, setItemID] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [hideAlertButton, setHideAlertButton] = useState(false)
  const dialogRef = useRef(null)

  useEffect(() => {
    if (props.workorder) {
      const item = props.workorder
      setShowButton(false)
      setNotes('')
      setItemID(item.id)
    }
  }, [props.workorder])

  const afterInit = () => {
    if (props.workorder) {
      const item = props.workorder
      setShowButton(false)
      setNotes('')
      setItemID(item.id)
    }
  }

  const addNote = () => {
    if (!notes) {
      setAlertMessage('Please enter notes')
      setShowAlert(true)

      return
    }

    const dataToPassToService: any = {}
    dataToPassToService.workorder_id = itemID
    dataToPassToService.status_id = 8
    //dataToPassToService.updatedBy = props.currentUser ? props.currentUser.Email : ''
    //dataToPassToService.created_By = props.currentUser ? props.currentUser.Email : ''
    //dataToPassToService.updated = new Date()
    //dataToPassToService.created = new Date()
    dataToPassToService.comments = notes

    utility.genericPostAPIcall(Config.workorderHistoryList, dataToPassToService).then(
      (results: any) => {
        setHideAlertButton(false)
        setAlertMessage('Work Order note has been updated')
        setShowAlert(true)
        props.onClick()
      },
      (err: any) => {
        console.log(err)
        setAlertMessage('An error occurred. Unable to update details.')
        setShowAlert(true)
        setHideAlertButton(false)
      }
    )
  }

  const clearFilter = () => {
    setNotes('')
    props.onClose()
  }

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={'worxOnline'}
        onClose={_closeDialog}
        hideAlertButton={hideAlertButton}
      />
    )
  }

  const _closeDialog = (): void => {
    setAlertMessage('')
    setShowAlert(false)
  }

  const handleValueChange = (e: any, fieldName: string) => {
    if (fieldName === 'notes') {
      setNotes(e.value)
    }
  }

  useEffect(() => {
    if (props.showModal && dialogRef.current) {
      afterInit()
    }
    
  }, [props.showModal])

  return (
    <div>
      {showAlert && alert()}
      <Dialog
        open={props.showModal}
        onClose={clearFilter}
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby='max-width-dialog-title'
        ref={dialogRef}
      >
        <DialogTitle id='scroll-dialog-title'>
          Add Notes
          <IconButton aria-label='close' onClick={() => props.onClose()} className='closeIcon' sx={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={false}>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextAreaInput
                  stylingMode='outlined'
                  label='Notes'
                  value={notes}
                  onValueChanged={e => handleValueChange(e, 'notes')}
                  spellcheck={true}
                  showClearButton={true}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <div className='action-buttons'>
            <Button onClick={clearFilter} variant='outlined' color='primary' className=''>
              Cancel
            </Button>
            <Button onClick={addNote} variant='outlined' color='primary' className='primary-button'>
              Add
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddNotes
