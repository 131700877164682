import React, { useEffect, useState } from 'react'
import DropDownBox from 'devextreme-react/drop-down-box'
import DataGrid, { Selection, FilterRow, Scrolling } from 'devextreme-react/data-grid'

const gridColumns = ['Select All']

interface Props {
  label?: string
  stylingMode: string | any
  placeholder?: string | any
  displayExpr: string | any
  valueExpr?: string | any
  grouped?: boolean | any
  selectedItem: any
  showClearButton?: boolean | any
  readOnly?: boolean | any
  disabled?: boolean | any
  onChange: any
  required?: any
  items: any
  spellcheck?: boolean | any
}

const MultiSelect: React.FC<Props> = props => {
  const [items, setItems] = useState(props.items)
  const [selectedItem, setSelectedItem] = useState(props.selectedItem)

  useEffect(() => {
    if (props.items && (!items || props.items.length !== items.length)) {
      setItems(props.items)
    }
  }, [props.items, items])

  useEffect(() => {
    if (props.selectedItem && (!selectedItem || props.selectedItem.length !== selectedItem.length)) {
      setSelectedItem(props.selectedItem)
    }
  }, [props.selectedItem, selectedItem])

  const dataGridOnSelectionChanged = (e: any) => {
    setSelectedItem(e.selectedRowKeys.length ? e.selectedRowKeys : [])
    const gridBoxValue = e.selectedRowKeys.length ? e.selectedRowKeys : []
    props.onChange(gridBoxValue)
  }

  const syncDataGridSelection = (e: any) => {
    setSelectedItem(e.value || [])
    const gridBoxValue = e.value || []
    props.onChange(gridBoxValue)
  }

  const dataGridRender = (items: any) => {
    return (
      <DataGrid
        height={430}
        dataSource={items}
        columns={gridColumns}
        hoverStateEnabled={false}
        selectedRowKeys={selectedItem}
        onSelectionChanged={dataGridOnSelectionChanged}
      >
        <Selection mode='multiple' />
        <Scrolling mode='virtual' />
        {/* <SearchPanel visible={true} width={'100%'} /> */}
        {/* <Paging enabled={true} pageSize={10} /> */}
        <FilterRow visible={true} />
      </DataGrid>
    )
  }

  return (
    <DropDownBox
      displayExpr={props.displayExpr}
      placeholder={props.placeholder}
      showClearButton={props.showClearButton}
      stylingMode={props.stylingMode}
      dataSource={items}
      value={selectedItem}
      onValueChanged={syncDataGridSelection}
      contentRender={() => dataGridRender(items)}
      label={props.label}
      labelMode={'floating'}
      disabled={props.disabled}
    />
  )
}

export default MultiSelect
