import React, { useState, useEffect } from 'react'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import AlertDialog from '../../Common/AlertDialog'
import DeleteDialog from '../../Common/DeleteAlert'
import Spinner from '../../Common/ReusableComponents/Spinner'
import AddEditMaterials from './AddEditMaterials'
import global from '../../../classes/global'
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'

interface LoadResult {
  data: any[]
  totalCount: number
}

interface MaterialsProps {
  currentUser: any
  role: string
  hideNewBtn: boolean
}

const Materials = (props: MaterialsProps) => {
  if (props.role !== global.worxOnline_Admin) {
    window.open('#/AccessDenied', '_self')
  }

  const [dataLoaded, setDataLoaded] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [items, setItems] = useState<any[]>([])
  const filterValue = [['is_active', '=', true]]

  const columns = [
    {
      field: 'id',
      title: 'ID',
      // width: 160,
      type: 'string',
      allowHiding: false,
      visibleColumn: false,
      formItem: {
        visible: false
      }
    },
    {
      field: 'resource_code',
      title: 'Resource Code',
      // width: 160,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    {
      field: 'title',
      title: 'Title',
      // width: 160,
      type: 'string',
      allowHiding: false,
      sortOrder: 'asc',
      isRequired: true
    },
    {
      field: 'category',
      title: 'Category',
      // width: 160,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'measuring_unit',
      title: 'Unit Of Measure',
      // width: 160,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'is_active',
      title: 'Is Active',
      // width: 160,
      type: 'boolean',
      allowHiding: true,
      calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
    }
  ]

  useEffect(() => {
    fetchItemsData()
  }, [])

  const fetchItemsData = async () => {
    setLoading(true)
    try {
      const itemsResponse = await utility.genericGetAPICall(Config.materialsListName)
      let items: any[] = []

      for (let r of itemsResponse) {
        items.push({
          ...r,
          id: r.id,
          active: r.is_active ? true : false
        })
      }
      items = utility._sortItems(items, 'id', true)
      setItems(items)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      return {
        data: items,
        totalCount: items.length
      }
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded')
      setLoading(false)
      setDataLoaded(true)
    },
    insert: async (values: any) => {
      try {
        await utility.genericPostAPICall(Config.materialsListName, values)
        await fetchItemsData()
      } catch (error: any) {
        if (error instanceof Error && error.message.startsWith('Network response was not ok')) {
          try {
            const errorData = JSON.parse(error.message.replace('Network response was not ok: ', ''))
            const errorMessage = utility.extractErrorMessage(errorData)
            throw new Error(errorMessage)
          } catch (parseError) {
            throw new Error('Failed to process error response.')
          }
        } else {
          throw new Error('Failed to post data.')
        }
      }
    },
    update: async (key: any, values: any) => {
      try {
        await utility.genericUpdatePatchAPICall(`${Config.materialsListName}/`, key, values)
        await fetchItemsData()
      } catch (error: any) {
        throw error
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.materialsListName, key)
        await fetchItemsData()
      } catch (error: any) {
        throw error
      }
    }
  })

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'Forms'}
              columnChooser={'FormColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              defaultfiltervalue={filterValue}
              showMoreBtn={true}
              visible={dataLoaded}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  )
}

export default Materials
