import React from 'react'
import NumberBox from 'devextreme-react/number-box'

interface Props {
  label?: string
  stylingMode: string | any
  placeholder?: string | any
  mask?: string | any
  useMaskedValue?: string | any
  mode?: string | any
  value: number
  showClearButton?: boolean | any
  readOnly?: boolean | any
  disabled?: boolean | any
  onValueChanged: any
  onKeyDown?: any
  required?: any
  spellcheck?: boolean | any
  min?: any
  max?: any
}

const NumberBoxInput: React.FC<Props> = (props: Props) => {
  return (
    <NumberBox
      labelMode='floating'
      label={props.label}
      stylingMode={props.stylingMode}
      placeholder={props.placeholder}
      value={props.value}
      onValueChanged={props.onValueChanged}
      showClearButton={props.showClearButton}
      readOnly={props.readOnly}
      disabled={props.disabled}
      min={props.min}
      max={props.max}
    >
      {/* {showButton && (
        <Button
          name="test"
          location={buttonLocation}
          options={{
            icon: 'map',
            type: 'default',
            onClick: onClickButton,
          }}
        />
      )} */}
    </NumberBox>
  )
}

export default NumberBoxInput
