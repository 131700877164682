import * as React from 'react';
import utility from '../../../classes/utilityOld';
import { Config } from '../../../classes/config';
import DataTable from '../../Common/CustomASDataGrid';
import AlertDialog from '../../Common/AlertDialog';
import DeleteDialog from '../../Common/DeleteAlert';
import Spinner from '../../Common/ReusableComponents/Spinner';
import AddEditRole from './AddEditRole';
import global from '../../../classes/global';
let _items: any = [];

export default class Roles extends React.Component<any, any> {

  public currentUser: any;
  public columns: any;

  constructor(props: any) {
    super(props);

    this.DeleteDialog = this.DeleteDialog.bind(this);

    this.currentUser = this.props.currentUser;
    if (this.props.role !== global.worxOnline_Admin) {
      window.open('#/AccessDenied', '_self');
    }
    this.columns = [
      {
        field: 'id',
        title: 'ID',
        width: 80,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'title',
        title: 'Title',
        // width: 160,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'isActive',
        title: 'Active',
        width: 100,
        type: 'boolean',
        allowHiding: false,
      },
      {
        field: 'updatedOn',
        title: 'Updated',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true,
        sortOrder: 'desc'
      },
      {
        field: 'updatedBy',
        title: 'Updated By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdOn',
        title: 'Created',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true
      },
    ];
    this.state = {
      items: _items,
      columns: this.columns,
    };
  }

  public componentDidMount() {
    this._getRoleData();
  }

  public DeleteItem(item: any) {
    // console.log("item", item);

    this.setState({
      alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading.." /></div>,
      hideAlertButton: true,
      showAlert: true,
      showDeleteAlert: false,
    });
    utility.genericDeleteAPICall(Config.roleList,
      item
    )
      .then(
        (data: any) => {
          this.setState({
            alertMessage: "Roles has been deleted.",
            showAlert: true,
            showDeleteAlert: false,
            hideAlertButton: false,
            loading: false
          });
          this._getRoleData();
        },
        (err) => {
          console.log(err);
          this.setState({
            alertMessage: "Cannot delete Roles. Please try again later.",
            showAlert: true,
            showDeleteAlert: false,
            hideAlertButton: false,
          });
        }
      );

  }

  // edit activty modal

  private EditItemModal(item: any) {
    //console.log("item", item);
    this.setState({ showModal: true, details: item, mode: "edit" });
  }
  public renderNewModal() {
    return (
      <AddEditRole
        showModal={this.state.showModal}
        onClose={() => this._closeModal()}
        onClick={() => this._getRoleData()}
        message={(e: any) => this.message(e)}
        currentUser={this.currentUser}
        details={this.state.details}
        aadHttpClient={this.props.aadHttpClient}
      />
    );
  }



  private _showModal = (): void => {
    this.setState({ showModal: true, details: null, mode: "edit" });
  };

  private _closeModal = (): void => {
    this.setState({ showModal: false });
  };

  public message(message: any) {
    //console.log(message);
    this.setState({
      alertMessage: message + " successfully",
      showAlert: true,
    });
  }
  private _getRoleData() {
    this.setState({
      loading: true,
    });
    utility.genericGetAPICallForList(Config.roleList, 'GET')
      .then(
        (data: any) => {
          _items = [];

          for (let r of data) {
            _items.push({
              id: r.Id,
              title: r.Title,
              isActive: r.Isactive,
              updatedBy: r.updatedBy,
              updatedOn: r.updated,
              createdBy: r.createdBy,
              createdOn: r.created,

            });
          }
          _items = utility._sortItems(_items, 'title', false);
          this.setState({
            loading: false,
          });
          this.setState({ items: _items, callAfterInit: true, loading: false });
          //console.log(_items);
        },
        (err) => {
          console.log(err);
          this.setState({ loading: false });
        }
      );
  }
  public setSelection = (id: any) => {
    this.setState({
      selectedRow: id
    });
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }



  public render(): React.ReactElement<any> {
    return (
      <React.Fragment>
        {this.renderNewModal()}
        {this.state.showDeleteAlert && this.DeleteAlert()}
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}> Roles </span>
          </div>
        </div>
        <div>
          {!this.state.loading ?
            <DataTable
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              dataSource={this.state.items}
              columns={this.state.columns}
              fileName={'Roless'}
              columnChooser={'rolesColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              callAfterInit={this.state.callAfterInit}
              stopCommsAfterInit={() => this.stopCommsAfterInit()}
              loading={this.state.loading}
              allowExportSelectedData={false}
              showNewModal={() => this._showModal()}
              deleteItem={(a: any) => this.DeleteDialog(a)}
              editItem={(a: any) => this.EditItemModal(a)}
              // viewFile={(e:any)=>this.viewDocument(e)}
              showCommandBtn={false}
              showNewBtn={false}
              hideNewBtn={this.state.showModal || this.state.showModal || this.props.hideNewBtn}
              showViewDetailBtn={false}
            /> :
            <Spinner size='large' label="Loading..." />
          }

        </div>
      </React.Fragment>
    );
  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        hideAlertButton={this.state.hideAlertButton}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }
  public DeleteDialog(item: any) {

    this.setState({
      DeletealertMessage: 'Are you sure you want to delete?',
      showDeleteAlert: true,
      deleteItem: item
    });
  }
  public DeleteAlert() {
    return (
      <DeleteDialog
        showDialog={this.state.showDeleteAlert}
        message={this.state.DeletealertMessage}
        title={"worxOnline"}
        onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
        onOkButton={() => this.DeleteItem(this.state.deleteItem)}
        onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
      />
    );
  }
}