import React, { useState } from 'react'
import ArrayStore from 'devextreme/data/array_store'
import List from 'devextreme-react/list'
import SchedulerCard from './SchedulerCard'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

const ListView = (props: any) => {
  const [group, setGroup] = useState('worktype')

  const handleGroupChange = (value: any) => {
    setGroup(value)
  }

  const dataSourceOptions = {
    store: new ArrayStore({
      data: props.items,
      key: props.items?.id
    }),
    group: !props.mapView ? group : 'worktype',
    searchExpr: ['worktype', 'Workordernumber', 'id', 'text', 'fieldstatus', 'crew', 'priority', 'location']
  }

  const renderListGroup = (group: any) => {
    return <div className='city'>{group.key}</div>
  }

  const renderListItem = (woData: any) => {
    return (
      <SchedulerCard
        key={woData.id}
        woData={woData}
        view={props.view}
        viewItem={props.viewItem}
        viewWO={props.viewWO}
        zoomToSelectWO={props.zoomToSelectWO}
        draggingGroupName={props.draggingGroupName}
        onDragStart={props.onItemDragStart}
        onDragEnd={props.onItemDragEnd}
        mapView={props.mapView}
        //draggingAllowed={true}
        schedulerlistDraggingAllowed={props.schedulerlistDraggingAllowed}
        //onDragStart={props.onDragStart}
        dashBoardCard={props.dashBoardCard}
        //onShowNotesPanel={() => props._onShowNotesPanel(woData)}
        onUnassignedSelection={props.onUnassignedSelection}
        onUnassignedSelectionOption={props.onUnassignedSelectionOption}
        showCrew={props.showCrew}
      />
    )
  }

  return (
    <div className={'p-10'}>
      {!props.mapView && (
        <div className='Tabs-container'>
            <Tabs
              onChange={(event, newValue) => handleGroupChange(newValue)}
              aria-label='Tab group'
              className='tabs-section'
              value={group}
            >
              <Tab
                label='Activity'
                value='worktype'
                className={`schedulerGroup ${group === 'worktype' ? 'selectedGroup' : ''}`}
              />
              <Tab
                label='Priority'
                value='priority'
                className={`schedulerGroup ${group === 'priority' ? 'selectedGroup' : ''}`}
              />
              <Tab
                label='Location'
                value='location'
                className={`schedulerGroup ${group === 'location' ? 'selectedGroup' : ''}`}
              />
            </Tabs>
          
          <br />
        </div>
      )}
      <List
        className='listView'
        selectionMode='single'
        dataSource={dataSourceOptions}
        grouped={true}
        searchEnabled={props.mapView ? false : true}
        selectedItemKeys={props.selectedItemKeys}
        itemRender={(e: any) => renderListItem(e)}
        groupRender={renderListGroup}
        elementAttr={{ class: 'list' }}
        collapsibleGroups={true}
        keyExpr='id'
      />
    </div>
  )
}

export default ListView
