import React, { useEffect, useState } from 'react';
import {
    NavLink
  } from "react-router-dom";
  import { useActiveTab } from './ActiveTabContext';
  import clsx from 'clsx';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import GavelIcon from '@mui/icons-material/Gavel';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventNoteIcon from '@mui/icons-material/EventNote';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';


const AppBarPage = () => {
  const { activeTab } = useActiveTab();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isScrolled, setIsScrolled] = useState(false);
  
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 0) {
            setIsScrolled(true);
          } else {
            setIsScrolled(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
  
    const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
      handleMobileMenuClose();
    };
  
    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
      setMobileMoreAnchorEl(event.currentTarget);
    };
    const logOut = () => {
      //instance.logoutRedirect({ postLogoutRedirectUri: "/" })
      //window.open('/#/SignIn', '_self');
    }

    const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <NavLink className="is-active" to="/Profile">
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
    </NavLink>
      <MenuItem onClick={logOut}>Logout</MenuItem>
      <div className='button-container'>
        <NavLink to="/CreateWorkOrder" className="CreateWOButton">Create Workorder</NavLink>
      </div>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

      <MenuItem>
        {/* <Notification isMobile={true} /> */}
        <p>Notifications</p>
      </MenuItem>
      <NavLink className="is-active" to="/Profile">
        <MenuItem>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </NavLink>
      <MenuItem onClick={logOut}>
        <IconButton
          aria-label="logout"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
      <div className='button-container'>
        <NavLink to="/CreateWorkOrder" className="CreateWOButton">Create Workorder</NavLink>
      </div>
    </Menu>
  );

  return (
    <>
      <AppBar position="static"
        className={`${isScrolled ? 'scrolledAppBar' : ''}`}
      >
        <Toolbar>
          <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <h2>{`${activeTab.main}${activeTab.sub ? ` - ${activeTab.sub}` : ''}`}</h2>  
          </Grid>
            <Grid item xs={6} sm={6} md={6}>
            <>
                  <div className={'sectionDesktop'}>
                    {/* <Notification isMobile={false} /> */}
                    <NavLink to="/Profile">
                      <IconButton
                        edge="start"
                        aria-label="Go to profile"
                        // aria-controls={menuId}
                        // aria-haspopup="true"
                        // onClick={handleProfileMenuOpen}
                        color="inherit"
                      >
                        <AccountCircle className={'navIcon'} />
                      </IconButton >
                    </NavLink>
                    <IconButton
                      edge="start"
                      aria-label="Log out"
                      // aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={logOut}
                      color="inherit"
                    >
                      <ExitToAppIcon className={'navIcon'} />
                    </IconButton >
                    
                    {(activeTab.main === 'Home' || activeTab.main === 'Search' || activeTab.main === 'Scheduler') && (
                      <div className='button-container'>
                        <NavLink to="/CreateWorkOrder" className="CreateWOButton">Create Workorder</NavLink>
                      </div>
                    )}
 
                  </div>
                  {/* <div className={'sectionMobile'}>
                    <IconButton
                      aria-label="show more"
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      color="inherit"
                    >
                      <MoreIcon className={'navIcon'} />
                    </IconButton>
                  </div> */}
                </> 
              
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      </>
  )
  
}

export default AppBarPage