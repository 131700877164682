import React, { useState, useEffect, useRef } from 'react'
import utility from '../../classes/utilityOld'
import { Config } from '../../classes/config'
import Grid from '@mui/material/Grid'
import AlertDialog from '../Common/AlertDialog'
import TextBoxInput from '../Common/ReusableComponents/TextBox'
import { DropzoneComponent } from 'react-dropzone-component'
import Spinner from '../Common/ReusableComponents/Spinner'
import ScrollView from 'devextreme-react/scroll-view'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

interface UploadedFile {
  file: File
}

interface AddAttachmentProps {
  details: any
  currentUser: any
  workorderID: string
  showModal: any
  onClose: any
  updateDoc: () => void
  message: () => void
  context: any
  aadHttpClient: any
}

const AddAttachment: React.FC<AddAttachmentProps> = ({
  details,
  currentUser,
  workorderID,
  showModal,
  onClose,
  updateDoc,
  message
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
  const [dataURL, setDataURL] = useState<any[]>([])
  const [fileTitle, setFileTitle] = useState<string>('')
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<any>('')
  const [hideAlertButton, setHideAlertButton] = useState<boolean>(false)
  const dialogRef = useRef(null)

  useEffect(() => {
    setUploadedFiles([])
    setDataURL([])
    setFileTitle('')
    setShowAlert(false)
  }, [showModal])

  const uploadAttachment = () => {
    const file = uploadedFiles
    const WOID = workorderID
    let accpeted
    if (uploadedFiles) {
      uploadedFiles.forEach((item: any) => {
        if (item.file.accepted === false) {
          accpeted = false
        }
      })
      if (accpeted === false) {
        setAlertMessage('Selected file is not accepted. Please select another file.')
        setShowAlert(true)

        return
      }
    }
    if (uploadedFiles.length > 5) {
      setAlertMessage('You cannot select more than 5 files.')
      setShowAlert(true)

      return
    }
    if (!fileTitle) {
      setAlertMessage('Please enter title')
      setShowAlert(true)

      return
    }
    if (uploadedFiles.length === 0) {
      setAlertMessage('Please attach a document')
      setShowAlert(true)

      return
    }
    setAlertMessage(<Spinner label='Uploading Document(s)' />)
    setHideAlertButton(true)
    setShowAlert(true)

    const formData = new FormData()
    const dataToPassToService: any = {}

    for (let i = 0; i < file.length; i++) {
      const tempFileExt = file[i].file.name.split('.')
      tempFileExt.reverse()
      const fileExtension = `.${tempFileExt[0]}`
      const fileName = fileTitle

      //dataToPassToService.workorder_id = +WOID

      //dataToPassToService.file_name = fileName + '_' + details.workorderNumber
      //dataToPassToService.document_type = 'Photo'
      //dataToPassToService.title = tempFileExt[1]
      formData.append('data', JSON.stringify({ workorder_id: +WOID, title: tempFileExt[1], document_type: 'photo' }))
      console.log('data', JSON.stringify({ workorder_id: +WOID, title: tempFileExt[1], document_type: 'photo' }))
      formData.append('files', file[i].file)
      console.log('files', file[i].file)

      utility
        .genericPostAPIcallForWODocument(Config.workorderDocumentList, formData)
        .then((data: any) => {
          setAlertMessage('')
          setHideAlertButton(false)
          setShowAlert(false)
          updateDoc()
          message()
          clearFilter()
        })
        .catch((err: any) => {
          console.log(err)
          setShowAlert(false)
        })
    }
  }

  const didMount = () => {
    setUploadedFiles([])
    setDataURL([])
    setHideAlertButton(false)
  }

  const handleEvent = (file: any) => {
    onDropGeneral(file)
    const newUploadedFile: UploadedFile = { file }
    setUploadedFiles(prevUploadedFiles => [...prevUploadedFiles, newUploadedFile])
    setFileTitle(file.name)
  }

  const onDropGeneral = (file: any) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = event => {
      setDataURL(prevDataURL => [...prevDataURL, { file: file.name, dataUrl: event ? event.currentTarget : '' }])
    }
  }

  const clearFilter = () => {
    setFileTitle('')
    onClose()
  }

  const deleteFile = (file: any) => {
    let deletingIndex = 0
    uploadedFiles.forEach((element: any, index: any) => {
      if (element.file.name === file.name) {
        deletingIndex = index
      }
    })
    uploadedFiles.splice(deletingIndex, 1)
    dataURL.forEach((element: any, index: any) => {
      if (element.file.name === file.name) {
        deletingIndex = index
      }
    })
    dataURL.splice(deletingIndex, 1)
  }

  const alert = () => (
    <AlertDialog
      showDialog={showAlert}
      message={alertMessage}
      title={Config.productTitle}
      onClose={() => setShowAlert(false)}
      hideAlertButton={hideAlertButton}
    />
  )

  const _fieldChanged = (event: any, fieldName: string) => {
    setFileTitle(event.value)
  }

  const _textAreaChanged = (e: React.ChangeEvent<HTMLTextAreaElement>, fieldName: string) => {
    // Not sure if this function is being used or not as it's not mentioned in the original code
    console.log(e.target.value)
  }

  const componentConfig = {
    showFiletypeIcon: true,
    postUrl: 'no-url'
  }

  const djsConfig = {
    autoProcessQueue: false,
    addRemoveLinks: true,
    maxFilesize: 5
  }

  const eventHandlers = {
    init: function (dz: any) {},
    addedfile: (file: any) => {
      handleEvent(file)
    },
    removedfile: (file: any) => {
      deleteFile(file)
    }
  }

  useEffect(() => {
    if (showModal && dialogRef.current) {
      didMount()
    }
  }, [showModal])

  return (
    <div>
      {showAlert && alert()}
      <Dialog
        open={showModal}
        onClose={clearFilter}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby='max-width-dialog-title'
        ref={dialogRef}
      >
        <DialogTitle id='scroll-dialog-title'>Add Attachment</DialogTitle>
        {/* <IconButton aria-label="close" onClick={clearFilter} className="closeIcon">
            <CloseIcon />
          </IconButton> */}
        <DialogContent dividers={false}>
          <ScrollView width='100%' height='100%'>
            <div className='popContentDiv'>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <TextBoxInput
                        stylingMode='outlined'
                        label='Title *'
                        value={fileTitle}
                        onValueChanged={_fieldChanged}
                        required={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={11}>
                      <DropzoneComponent
                        eventHandlers={eventHandlers}
                        djsConfig={djsConfig}
                        config={componentConfig}
                        className='dz-message icon ion-upload'
                        action='Drop files here or click to upload.'
                      />
                      <span>Max no. of file: 5. Max file size: 5Mb</span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </ScrollView>
        </DialogContent>
        <DialogActions>
          <div className='action-buttons'>
            <Button onClick={clearFilter} variant='contained'>
              Cancel
            </Button>
            <Button onClick={uploadAttachment} variant='outlined' color='primary' className='primary-button'>
              Add
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddAttachment
