import React, { useState, useEffect } from 'react';

const LoginDetails: React.FC<any> = (props) => {
  return (
    <div className={'ui-container'}>
      <div className={'headingDiv'}>
        <span className={'heading'}> Login Details </span>
      </div>
    </div>
  );
}
export default LoginDetails;
// import utility from '../../../classes/utilityOld';
// import { Config } from '../../../classes/config';

// import DataTable from '../../Common/CustomASDataGrid';
// import AlertDialog from '../../Common/AlertDialog';
// import Spinner from '../../Common/ReusableComponents/Spinner';

// import global from '../../../classes/global';

// const LoginDetails: React.FC<any> = (props) => {
//   const currentUser = props.currentUser;
//   const columns = [
//     {
//       field: 'id',
//       title: 'ID',
//       width: 80,
//       type: 'string',
//       allowHiding: false,
//     },
//     {
//       field: 'userFullName',
//       title: 'User Name',
//       width: 250,
//       type: 'string',
//       allowHiding: false,
//     },
//     {
//       field: 'loggedInFrom',
//       title: 'Logged in From',
//       width: 160,
//       type: 'datetime',
//       allowHiding: true,
//     },
//     {
//       field: 'loggedInTime',
//       title: 'Logged In Time',
//       width: 160,
//       type: 'datetime',
//       allowHiding: true,
//       sortOrder: 'desc',
//     },
//     {
//       field: 'loggedOutTime',
//       title: 'Logged Out Time',
//       width: 160,
//       type: 'datetime',
//       allowHiding: true,
//     },
//     {
//       field: 'updatedBy',
//       title: 'Updated By',
//       width: 160,
//       type: 'string',
//       visibleColumn: false,
//       allowHiding: true,
//     },
//     {
//       field: 'updated',
//       title: 'Updated',
//       width: 160,
//       type: 'datetime',
//       visibleColumn: false,
//       allowHiding: true,
//       sortOrder: 'desc',
//     },
//   ];

//   const [items, setItems] = useState<any[]>([]);
//   const [loading, setLoading] = useState<boolean>(false);
//   const [showAlert, setShowAlert] = useState<boolean>(false);
//   const [alertMessage, setAlertMessage] = useState<string>('');
//   const [callAfterInit, setCallAfterInit] = useState<boolean>(false);
//   const [selectedRow, setSelectedRow] = useState<any>(null);

//   useEffect(() => {
//     if (props.role !== global.worxOnline_Admin) {
//       window.open('#/AccessDenied', '_self');
//     } else {
//       _getItemData();
//     }
//   }, []);

//   const _getItemData = () => {
//     setLoading(true);
//     let dataToPassToService: any = {};
//     utility
//       .genericGetAPICallForList(Config.searchUserlogin, 'POST', dataToPassToService)
//       .then(
//         (data: any) => {
//           let _items: any[] = [];
//           if (data.length === null) {
//             if (_items.length <= 0) {
//               setAlertMessage('No results found for this criteria');
//               setShowAlert(true);
//             }
//             setItems(_items);
//             setLoading(false);
//             return;
//           } else {
//             for (let r of data) {
//               _items.push({
//                 id: r.id,
//                 loggedInFrom: r.loggedInFrom,
//                 loggedInTime: r.loggedInTime,
//                 loggedOutTime: r.loggedOutTime,
//                 userFullName: r.userFullName,
//                 updatedBy: r.updatedBy,
//                 updatedOn: r.updated ? r.updated : null,
//                 createdBy: r.createdBy,
//                 createdOn: r.created ? r.created : null,
//               });
//             }
//           }
//           _items = utility._sortItems(_items, 'updatedOn', true);
//           setItems(_items);
//           setCallAfterInit(true);
//           setLoading(false);
//         },
//         (err) => {
//           console.log(err);
//           setLoading(false);
//         }
//       );
//   };

//   const stopCommsAfterInit = () => {
//     setCallAfterInit(false);
//   };

//   const alert = () => {
//     return (
//       <AlertDialog
//         showDialog={showAlert}
//         message={alertMessage}
//         title={Config.productTitle}
//         hideAlertButton={false}
//         onClose={() => {
//           setAlertMessage('');
//           setShowAlert(false);
//         }}
//       />
//     );
//   };

//   return (
//     <React.Fragment>
//       {showAlert && alert()}
//       <div className={'SearchDiv'}>
//         <div className={'headingDiv'}>
//           <span className={'heading'}> Login Details </span>
//         </div>
//       </div>
//       <div>
//         {!loading ? (
//           <DataTable
//             style={{ width: '100%' }}
//             filteralbe={true}
//             groupable={true}
//             dataSource={items}
//             columns={columns}
//             fileName={'LoginDetails'}
//             columnChooser={'LoginDetailsColumns'}
//             selectionMode={false}
//             showHideColumnMenu={true}
//             callAfterInit={callAfterInit}
//             stopCommsAfterInit={stopCommsAfterInit}
//             loading={loading}
//             allowExportSelectedData={false}
//             showCommandBtn={false}
//             showNewBtn={false}
//             hideNewBtn={false}
//             showViewDetailBtn={false}
//           />
//         ) : (
//           <Spinner size="large" className="loader" />
//         )}
//       </div>
//     </React.Fragment>
//   );
// };

// export default LoginDetails;
