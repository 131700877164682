// ActiveTabContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

type ActiveTabProviderProps = {
  children: ReactNode;
};

interface ActiveTabContextProps {
  activeTab: {
    main: string;
    sub: string;
  };
  setTabs: (mainTab: string, subTab: string) => void;
}

const ActiveTabContext = createContext<ActiveTabContextProps | undefined>(undefined);

export const useActiveTab = (): ActiveTabContextProps => {
  const context = useContext(ActiveTabContext);
  if (!context) {
    throw new Error('useActiveTab must be used within an ActiveTabProvider');
  }
  return context;
};

export const ActiveTabProvider: React.FC<ActiveTabProviderProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState({
    main: 'Home',
    sub: '',
  });

  const setTabs = (mainTab: string, subTab: string) => {
    setActiveTab({
      main: mainTab,
      sub: subTab,
    });
  };

  return (
    <ActiveTabContext.Provider value={{ activeTab, setTabs }}>
      {children}
    </ActiveTabContext.Provider>
  );
};
