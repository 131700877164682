import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom';

import moment from 'moment'
import * as PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import utility from '../../../../classes/utility'
import { Config } from '../../../../classes/config'
import global from '../../../../classes/global'
import MapModal from '../../../Map/Map'
import AlertDialog from '../../../Common/AlertDialog'
import DisplayDetailsModal from '../../../Common/DisplayDetailModal/DisplayDetailModal'
import Spinner from '../../../Common/ReusableComponents/Spinner'
import DataTable from '../../../Common/CustomASDataGrid'
import LoadIndicator from 'devextreme-react/load-indicator'
import AssetMap from '../../Assets/AssetMap'
import GoogleMapComponent from '../../../Common/MapComponent/GoogleMapComponent'
import AssignPlant from './AssignVehicle'
import AddNotes from './AddNotes'
import EditWorkOrder from '../EditWorkOrder/EditWO'
import ConfirmDialog from '../../../Common/ConfirmDialog'
import AddAttachment from '../../../AddAttachment/AddAttachment'
import ReadMore from '../../../Common/ReusableComponents/ReadMore';

//@mui imports
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabPanel from '../../../Common/TabPanel'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import 'devextreme/dist/css/dx.light.css'

import EditIcon from '@mui/icons-material/Edit';
import NoteAddRounded from '@mui/icons-material/NoteAddRounded'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import BuildIcon from '@mui/icons-material/Build'
import PlaceOutlined from '@mui/icons-material/PlaceOutlined'

import {ReactComponent as LocationIcon} from '../../../../images/main/Location.svg'

export const _WOhistorycolumns = [
  {
    field: 'Status',
    title: 'Status',
    width: 160,
    type: 'string'
  },
  {
    field: 'CreatedInDB',
    title: 'Added Date',
    width: 260,
    type: 'datetime',
    sortOrder: 'desc'
  },

  {
    field: 'createdBy',
    title: 'Added By',
    width: 260,
    type: 'string'
  },
  {
    field: 'Comments',
    title: 'Comments',

    // width: 160,
    type: 'string'
  }
]
export const _Docphotoscolumns = [
  {
    field: 'id',
    title: 'ID',

    // width: 160,
    type: 'string'
  },
  {
    field: 'document',
    title: 'Document',

    // width: 160,
    type: 'string',
    link: 'file'
  },
  {
    field: 'comments',
    title: 'Comments',

    // width: 160,
    type: 'string'
  },
  {
    field: 'uploadedon',
    title: 'Uploaded On',

    // width: 160,
    type: 'datetime'
  },
  {
    field: 'uploadedby',
    title: 'Uploaded By',

    // width: 160,
    type: 'string'
  }
]
export const _worktimescolumns = [
  {
    field: 'employee',
    title: 'Employee',
    type: 'string',
    allowHiding: false,
    groupIndex: 0
  },
  {
    field: 'type',
    title: 'Type',
    type: 'string',
    allowHiding: true
  },
  {
    field: 'hoursworked',
    title: 'Hours worked',
    type: 'string',
    allowHiding: true
  },

  {
    field: 'starttime',
    title: 'Start Time',
    type: 'datetime',
    allowHiding: true
  },
  {
    field: 'endtime',
    title: 'End Time',
    type: 'datetime',
    allowHiding: true
  }
]
export const _plantusagecolumns = [
  {
    field: 'plantnumber',
    title: 'Plant and Fleet #',

    // width: 160,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'title',
    title: 'Title',

    // width: 160,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'Regonumber',
    title: 'Rego #',

    // width: 280,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'hoursWorked',
    title: 'Hours Worked',

    // width: 280,
    type: 'string',
    allowHiding: false
  },

  {
    field: 'createdOn',
    title: 'Created On',
    width: 160,
    type: 'datetime',
    allowHiding: true
  },
  {
    field: 'createdBy',
    title: 'Created By',
    width: 160,
    type: 'string',
    allowHiding: true
  }
]
export const _relatedWOcolumns = [
  {
    field: 'id',
    title: 'Work Order #',

    // width: 160,
    type: 'string',
    allowHiding: false,
    link: 'workorderLink'
  },
  {
    field: 'title',
    title: 'Title',

    // width: 280,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'WOPriority',
    title: 'Priority',

    // width: 280,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'activitycode',
    title: 'Activity',

    // width: 280,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'responsibleperson',
    title: 'Crew',

    // width: 280,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'status',
    title: 'status',

    // width: 280,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'schstartdate',
    title: 'Scheduled Start Date',

    // width: 280,
    type: 'datetime',
    allowHiding: false
  }
]
export const _AssetWOcolumns = [
  {
    field: 'AssetNumber',
    title: 'Asset #',
    type: 'string',
    link: 'assetLink',
    allowHiding: false
  },
  {
    field: 'AssetTitle',
    title: 'Title',

    // width: 280,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'Register',
    title: 'Register',

    // width: 280,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'MaintenanceScheduleCode',
    title: 'Maintenance Schedule Code',

    // width: 280,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'AttrCode',
    title: 'Attr Code',

    // width: 280,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'Ownership',
    title: 'Ownership',

    // width: 280,
    type: 'string',
    allowHiding: false
  }
]
export const _materialcolumns = [
  {
    field: 'id',
    title: 'ID',

    // width: 160,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'materialName',
    title: 'Material Name',

    // width: 280,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'quantity',
    title: 'Quantity',

    // width: 280,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'measuringUnit',
    title: 'Measuring Unit',

    // width: 280,
    type: 'string',
    allowHiding: false
  },
  {
    field: 'comments',
    title: 'Comments',

    // width: 280,
    type: 'string',
    allowHiding: false
  }
]

export async function getServerSideProps(context: any) {
  const { query } = context
  const { WOID } = query

  return {
    props: {
      WOID
    }
  }
}

interface SearchWODetailProps {
  department?: string
  context?: any
  aadHttpClient?: any
  WOID?: string
}

interface WOProps {
  role?: any
  Department?: any
  currentUser?:any
  pageListItems?:any
  isContractor?:any
  team?:any
}

const SearchWODetail: React.FC<SearchWODetailProps & WOProps> = props => {
  const location = useLocation();

  //const { department, currentUser, WOID } = props
  const [worktypeItems, setWorktypeItems] = useState<any[]>([])
  const [crewItems, setCrewItems] = useState<any[]>([])
  const [statusItems, setStatusItems] = useState<any[]>([])
  const [priorityItems, setPriorityItems] = useState<any[]>([])
  const [WOhistoryItems, setWOhistoryItems] = useState<any[]>([])
  const [materialWOItems, setMaterialWOItems] = useState<any[]>([])
  const [WOhistoryCount, setWOhistoryCount] = useState<number>(0)
  const [DocPhotosItems, setDocPhotosItems] = useState<any[]>([])
  const [DocPhotosCount, setDocPhotosCount] = useState<number>(0)
  const [plantusageItems, setPlantusageItems] = useState<any[]>([])
  const [plantusageCount, setPlantusageCount] = useState<number>(0)
  const [relatedWOItems, setRelatedWOItems] = useState<any[]>([])
  const [relatedWOCount, setRelatedWOCount] = useState<number>(0)
  const [worktimeItems, setWorktimeItems] = useState<any[]>([])
  const [worktimeCount, setWorktimeCount] = useState<number>(0)
  const [materialUsageItems, setMaterialUsageItems] = useState<any[]>([])
  const [MatUsageCount, setMatUsageCount] = useState<number>(0)
  const [assetWOItems, setAssetWOItems] = useState<any[]>([])
  const [AssetWOCount, setAssetWOCount] = useState<number>(0)
  const [DefectsWOItems, setDefectsWOItems] = useState<any[]>([])
  const [purchaseRequisitionItems, setPurchaseRequisitionItems] = useState<any[]>([])
  const [selected, setSelected] = useState<number>(0)

  const AssetsRef = useRef<any>(null)

  const [callAfterInit, setCallAfterInit] = useState<boolean>(false)
  const [loadingMaterialWO, setLoadingMaterialWO] = useState<boolean>(false)
  const [worktypeName, setWorktypeName] = useState<string>('')
  const [defectDetails, setDefectDetails] = useState<any>(null)
  const [showDefectDetailsModal, setShowDefectDetailsModal] = useState<boolean>(false)
  const [defectResolvedDetails, setDefectResolvedDetails] = useState<any>(null)
  const [showDefectResolvedDetailsModal, setShowDefectResolvedDetailsModal] = useState<boolean>(false)
  const [mode, setMode] = useState<any>('')
  const [priorityId, setPriorityId] = useState<string>('')
  const [priority, setPriority] = useState<string | null>(null)
  const [statusId, setStatusId] = useState<string>('')
  const [statusText, setStatusText] = useState<string>('')
  const [plantWOItems, setPlantWOItems] = useState<any[]>([])
  const [formattedAssetId, setFormattedAssetId] = useState<string | null>(null)
  const [workOrderDetails, setWorkOrderDetails] = useState<any | null>(null)
  const [marker, setMarker] = useState<{ lng: number; lat: number }>({ lng: 0, lat: 0 })
  const [loadingAssetDetail, setLoadingAssetDetail] = useState(false)
  const [workReqlink, setWorkReqlink] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [plantUsageLoading, setPlantUsageLoading] = useState(false)
  const [plantUsageCount, setPlantUsageCount] = useState<number>(0)
  const [plantUsageItems, setPlantUsageItems] = useState<any[]>([])
  const [timesheetLoading, setTimesheetLoading] = useState(false)
  const [historyLoading, setHistoryLoading] = useState(false)
  const [details, setDetails] = useState<any>(null)
  const [showModal, setShowModal] = useState(false)
  const [loadingrelatedWO, setLoadingRelatedWO] = useState(false)
  const [documentloading, setDocumentLoading] = useState(false)
  const [showConfirmAlert, setShowConfirmAlert] = useState(false)
  const [ConfirmalertMessage, setConfirmAlertMessage] = useState('')
  const [Asset, setAsset] = useState<any>(null)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState<string | JSX.Element>('')
  const [hideAlertButton, setHideAlertButton] = useState(false)
  const [showTimesheetModal, setShowTimesheetModal] = useState(false)
  const [timeEditdetails, setTimeEditdetails] = useState<any>(null)
  const [timeEditForPlantdetails, setTimeEditForPlantdetails] = useState(null)
  const [showPlantEditTimeModal, setShowPlantEditTimeModal] = useState(false)
  const [showCommsModal, setShowCommsModal] = useState(false)
  const [workOrderID, setWorkOrderID] = useState<string>('')
  const [SRID, setSRID] = useState('')
  const [allocationReferences, setAllocationReferences] = useState('')
  const [showNotesPanel, setShowNotesPanel] = useState<boolean>(false)
  const [openMapModal, setOpenMapModal] = useState<boolean>(false)
  const [openPlantAssignModal, setOpenPlantAssignModal] = useState(false)
  const [CrewName, setCrewName] = useState('')
  const [DepartmentName, setDepartmentName] = useState('')
  const [showModalAttachment, setShowModalAttachment] = useState<boolean>(false)
  const [purchaseReqDetails, setPurchaseReqDetails] = useState<any>(null)
  const [showModalofPurchaseReq, setShowModalofPurchaseReq] = useState(false)
  const [iFramePanelOpened, setIFramePanelOpened] = useState(false)
  const [selectParent, setSelectParent] = useState<any>(null)
  const [selectModeMap, setSelectModeMap] = useState(false)
  const [newasset, setNewAsset] = useState('')
  const [previousPage, setPreviousPage] = useState(false)
  const [showAssetPanel, setShowAssetPanel] = useState(false)
  const [Assets, setAssets] = useState<any>(null)
  const [latLong, setLatLong] = useState<{ lat: number; lng: number } | null>(null)
  const [lat, setLat] = useState<number | null>(null)
  const [lng, setLng] = useState<number | null>(null)
  const [assetLocation, setAssetLocation] = useState<any | null>(null)
  const [showMap, setShowMap] = useState(false)

  useEffect(() => {
    if (!props.department) {
      //window.open('#/AccessRequest', '_self')
    }
  }, [props.department])

  useEffect(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const workid = params.get('WOID');

    if (workid) {
      setWorkOrderID(workid);
    }
  }, [location.search]);

  useEffect(() => {
    const fetchData = async (woid: any) => {
      setAllocationReferences('');
      await _getStatusDataAsync();
      await loadWorkOrderDetails(woid);
      await getWorkOrderHistory(woid);
      await getWODocument(woid);
      await getTimesheetdata(woid);
      await getWOMaterialdata(woid);
      await getPlantUsageData(woid);
      await getRelatedWO(woid);
    };

    if (workOrderID) {
      fetchData(workOrderID);
    }
  }, [workOrderID]);

  const getWOMaterialdata = async (woid: any) => {
    const dataToPassToService: any = {}
    dataToPassToService.workorder_id = [+woid] // Assuming workOrderID is a state variable
    try {
      const data: any = await utility.genericGetDataByIdAPICall(
        Config.workorderMateriallistname,
        dataToPassToService.workorder_id
      )
      let _materialWOItem: any[] = []
      if (data !== null && data.length !== 0) {
        data.forEach((item: any) => {
          _materialWOItem.push({
            Workordernumber: item.workorder_id,
            materialName: item.material_id,
            //title: item.workOrderTitle,
            id: item.id
            //measuringUnit: item.measuringUnit,
            //quantity: item.quantity,
            //comments: item.comments
          })
        })
        _materialWOItem = utility._sortItems(_materialWOItem, 'createdOn', true)
        setMaterialWOItems(_materialWOItem)
        setCallAfterInit(true)
        setLoadingMaterialWO(false)
      }
    } catch (err) {
      console.log(err)
      setLoadingMaterialWO(false)
    }
  }

  const _getStatusDataAsync = async () => {
    try {
      const data: any = await utility.genericGetAPICall(Config.statuslist)
      const statusDataItems = data
        .filter((r: any) => r.is_active)
        .map((r: any) => ({ id: r.id, text: r.title }))
        .sort((a: any, b: any) => a.text.localeCompare(b.text))
      setStatusItems(statusDataItems)
    } catch (err) {
      console.log(err)
    }
  }

  const _getworkTypeDataAsync = async (workorderTypeId: any) => {
    try {
      const data: any = await utility.genericGetDataByIdAPICall(Config.WTlistname, workorderTypeId)
      if (data && data.title) {
        setWorktypeName(data.title)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const viewDefectDetials = (item: any, mode: any) => {
    setDefectDetails(item)
    setShowDefectDetailsModal(true)
    setMode(mode)
  }

  const viewDefectResolvedDetials = (item: any) => {
    setDefectResolvedDetails(item)
    setShowDefectResolvedDetailsModal(true)
  }

  const _getPrioritiesData = async (priority: any) => {
    try {
      const data: any = await utility.genericGetAPICall(Config.PriorityListName)
      const filteredItems = data.filter((r: any) => r.type === 'WorkOrder')
      const priorityItems = filteredItems.map((r: any) => ({ id: r.id, text: r.title }))
      const priorityItem = priorityItems.find((item: any) => item.id === priority)
      setPriorityItems(priorityItems)
      setPriorityId(priorityItem ? priorityItem.id : null)
      setPriority(priorityItem ? priorityItem.text : null)
    } catch (err) {
      console.log(err)
    }
  }

  const loadWorkOrderDetails = async (woid: any) => {
    try {
      setLoading(true)
      setCallAfterInit(true)
      const dataToPassToService: any = {}
      dataToPassToService.id = [+woid]

      const items: any = await utility.genericPostAPICall(Config.WOSearchAPI, dataToPassToService)
      const data = items[0]
      if (data.asset_ids && Array.isArray(data.asset_ids) && data.asset_ids.length > 0){loadAssetDetails(data.asset_ids) };
      if (data && Object.keys(data).length > 0) {
        _getworkTypeDataAsync(data.workorder_type_id)
        getcrew(data)

        if (statusItems && statusItems.length > 0) {
          const filteredStatusItems = statusItems.filter((f: any) => f.id === data.workorder_status_id)
          setStatusId(filteredStatusItems[0].id)
          setStatusText(filteredStatusItems[0].text)
        }

        _getPrioritiesData(data.priority_id)

        // if (
        //   (data.assets_info && data.assets_info != null && data.assets_info.AssetID != null) ||
        //   data.assets_info.AssetID != ''
        // ) {
        //   loadAssetDetails(data.assets_info.AssetID)
        // }

        if (data.assets_info !== null) {
          const JSONAssetData = data.assets_info
          const assetWOItemsData: any[] = []

          if (JSONAssetData && Object.keys(JSONAssetData).length > 0) {
            assetWOItemsData.push({
              AssetNumber: JSONAssetData.AssetID,
              AssetTitle: JSONAssetData.Description ? JSONAssetData.Description : '',
              Register: JSONAssetData.Register ? JSONAssetData.Register : '',
              MaintenanceScheduleCode:
                JSONAssetData.ERPInfo && JSONAssetData.ERPInfo.MaintenanceScheduleCode
                  ? JSONAssetData.ERPInfo.MaintenanceScheduleCode
                  : '',
              AttrCode: JSONAssetData.ERPInfo?.AttrCode ? JSONAssetData.ERPInfo.AttrCode : '',
              Ownership: JSONAssetData.GISInfo ? JSONAssetData.GISInfo.properties.Ownership : ''
            })
          }

          setAssetWOCount(assetWOItemsData.length)
          setAssetWOItems(assetWOItemsData)
        }

        if (data.plant_operators != null) {
          const plantWOItemsData: any[] = []

          plantWOItemsData.push({
            operatorFullName: data.plant_operators.operator_full_name ? data.plant_operators.operator_full_name : '',
            operatorId: data.plant_operators.operator_id ? data.plant_operators.operator_id : '',
            plantResourceCode: data.plant_operators.plant_resource_code ? data.plant_operators.plant_resource_code : '',
            plantTitle: data.plant_operators.plant_title ? data.plant_operators.plant_title : ''
          })

          setPlantWOItems(plantWOItemsData)
        }

        setWorkOrderDetails(data)
        setMarker({
          lng:
            data.assets_info &&
            data.assets_info.GISInfo &&
            data.assets_info.GISInfo.geometry &&
            data.assets_info.GISInfo.geometry.coordinates.length > 0
              ? data.assets_info.GISInfo.geometry.coordinates[0].lng
              : 0,
          lat:
            data.assets_info &&
            data.assets_info.GISInfo &&
            data.assets_info.GISInfo.geometry &&
            data.assets_info.GISInfo.geometry.coordinates.length > 0
              ? data.assets_info.GISInfo.geometry.coordinates[0].lat
              : 0
        })

        setLoadingAssetDetail(false)
        setWorkReqlink('/WRDetail?ReqId=' + data.work_request_id)
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
      setAlertMessage('unable to load Asset Details')
    }
  }

  const loadAssetDetails = (assetIDs: any) => {
    setLoadingAssetDetail(true)

    const dataToPassToService: any = {}
    dataToPassToService.id = assetIDs
    utility
      .genericPostAPICall(Config.AssetSearchAPI, dataToPassToService)
      .then((data: any) => {
        const assetDetailsItems: any[] = []
        if (data.length > 0) {
          data.forEach((item: any) => {
            assetDetailsItems.push({
              Register: item.asset_register_id,
              AssetNumber: item.id,
              id: item.id,
              locationId: item.locationId,
              AssetClass: item.asset_class?.title,
              AssetID: item.id,
              AssetTitle: item.title,
              erpAssetId: item.erp_asset_id,
              erpExtAssetId: item.ext_system_id,
              ServiceStatus: item.asset_status?.status,
              Description: item.description,
              Location: item.locationDescription,
              Category: item.asset_category?.title,
              erpExtSystemName: item.erpExtSystemName,
              maintenanceType: item.maintenance_asset_type,
              accntSystemId: item.accntSystemId,
              AssetType: item.asset_type?.title,
              //geometry: item.geometry,
              //workGroupIds: item.workGroupIds
            })
          })

          setAssetWOItems(assetDetailsItems)
          setLoadingAssetDetail(false)
        } else {
          //setAssetdetails([])
          setLoadingAssetDetail(false)
        }
      })
      .catch((err: any) => {
        console.log(err)
        //setAssetdetails([])
        setLoadingAssetDetail(false)
      })
  }

  const getPlantUsageData = async (woid: any) => {
    try {
      setPlantUsageLoading(true)
      const dataToPassToService: any = {}
      dataToPassToService.workorderId = +woid

      const woplant: any = await utility.genericGetDataByIdAPICall(
        Config.WOequipmentSearchAPI,
        dataToPassToService.workorderId
      )

      let plantusageItems: any[] = []
      if (woplant !== null && woplant.length !== 0) {
        woplant.forEach((item: any) => {
          plantusageItems.push({
            //Workordernumber: item.workorderId,
            //techoneWorkordernumber: item.erpWorkorderNumber,
            //title: item.title,
            //planttype: item.title,
            id: item.id,
            //plantnumber: item.resourceCode,
            //Category: item.category,
            //Regonumber: item.regoNumber,
            //Plantid: item.equipmentId,
            Comments: item.comments,
            starttime: item.start_time,
            endtime: item.end_time,
            viewTimesheet: '',
            hours_worked: item.hoursWorked,
            //Editedhoursworked: item.hoursWorked ? item.hoursWorked : item.hoursWorked,
            //Editedstarttime: item.startTime,
            //Editedendtime: item.endTime,
            modifiedOn: item.modified,
            updatedBy: item.modified_by,
            createdBy: item.created_by,
            createdOn: item.created
          })
        })
        plantusageItems = utility._sortItems(plantusageItems, 'createdOn', true)
        setPlantUsageCount(woplant.length)
        setPlantUsageItems(plantusageItems)
        setPlantUsageLoading(false)
      } else {
        setPlantUsageCount(0)
        setPlantUsageItems([])
        setPlantUsageLoading(false)
      }
    } catch (err) {
      console.log(err)
      setPlantUsageLoading(false)
    }
  }

  const getTimesheetdata = async (woid: any) => {
    try {
      setTimesheetLoading(true)
      const dataToPassToService: any = {}
      dataToPassToService.workorder_id = [+woid]

      const wotimesheet: any = await utility.genericGetDataByIdAPICall(
        Config.WOTimeSheetAPI,
        dataToPassToService.workorder_id
      )

      let worktimeItemsData: any[] = []
      let empFullName = ''
      if (wotimesheet !== null && wotimesheet.length !== 0) {
        wotimesheet.forEach(async (item: any) => {
          // const starttime: any = new Date(item.start_time)
          // const endTime: any = new Date(item.end_time)
          // let delta: any = Math.abs(endTime - starttime) / 1000

          // // calculate (and subtract) whole days
          // const days = Math.floor(delta / 86400)
          // delta -= days * 86400

          // // calculate (and subtract) whole hours
          // const hours = Math.floor(delta / 3600) % 24
          // delta -= hours * 3600

          // // calculate (and subtract) whole minutes
          // const minutes = Math.floor(delta / 60) % 60
          // delta -= minutes * 60

          const employeeIdData = item.employee_id

          if (employeeIdData && employeeIdData != null) {
            const wotimesheetuserdata: any = await utility.genericGetDataByIdAPICall(Config.UserById, employeeIdData)
            if (wotimesheetuserdata !== null && wotimesheetuserdata.length !== 0) {
              empFullName = wotimesheetuserdata[0].full_name
            }
          }

          worktimeItemsData.push({
            id: item.Id,
            Workordernumber: item.workorder_id,
            type: item.type,
            employee: empFullName,
            starttime: item.start_time,
            endtime: item.end_time,
            viewTimesheet: '',
            //plants: item.plants,
            hoursworked: item.hours_worked,
            //updatedBy: item.updatedBy,
            //updatedOn: item.updated,
            createdBy: item.created_by,
            createdOn: item.created
          })
        })
        worktimeItemsData = utility._sortItems(worktimeItemsData, 'createdOn', true)
        setWorktimeCount(wotimesheet.length)
        setWorktimeItems(worktimeItemsData)
        setTimesheetLoading(false)
      } else {
        setWorktimeCount(0)
        setWorktimeItems([])
        setTimesheetLoading(false)
      }
    } catch (err) {
      console.log(err)
      setTimesheetLoading(false)
    }
  }

  const getWorkOrderHistory = async (woid: any) => {
    try {
      setHistoryLoading(true)
      const dataToPassToServiceForWOHistory: any = {}
      dataToPassToServiceForWOHistory.workorder_id = +woid

      const wohistory: any = await utility.genericGetDataByIdAPICall(
        Config.WOHistorySearchAPI,
        dataToPassToServiceForWOHistory.workorder_id
      )

      let WOhistoryItems: any[] = []
      if (wohistory !== null && wohistory.length !== 0) {
        wohistory.forEach((item: any) => {
          WOhistoryItems.push({
            id: item.id,
            Status: item.status ? item.status.title : '',
            createdBy: item.created_by,
            CreatedInDB: item.date_record_created_in_db,
            Comments: item.comments ? item.comments : ' '
          })
        })
        WOhistoryItems = utility._sortItems(WOhistoryItems, 'CreatedInDB', true)
        setWOhistoryCount(wohistory.length)
        setWOhistoryItems(WOhistoryItems)
        setHistoryLoading(false)
      } else {
        setWOhistoryCount(0)
        setWOhistoryItems([])
        setHistoryLoading(false)
      }
    } catch (err) {
      console.log(err)
      setHistoryLoading(false)
    }
  }

  const getRelatedWO = async (woid: any) => {
    try {
      setLoadingRelatedWO(true)
      const dataToPassToServiceForRWO: any = {}
      dataToPassToServiceForRWO.workorder_id = +woid

      const relatedWO: any = await utility.genericGetDataByIdAPICall(
        Config.SearchRelatedWOAPI,
        dataToPassToServiceForRWO.workorder_id
      )

      let relatedWOItems: any[] = []
      if (relatedWO !== null && relatedWO.length !== 0) {
        relatedWO.forEach((item: any) => {
          if (item.Id !== details?.Id) {
            relatedWOItems.push({
              id: item.id,
              woid: item.id,
              erpWorkorderNumber: item.erp_workorder_number,
              title: item.title ? item.title : '',
              createdOn: item.created,
              responsibleperson: item.crew ? item.crew.title : '',
              activitycode: item.workorder_type ? item.workorder_type.title : '',
              WOPriority: item.priority ? item.priority.title : '',
              status: item.workorder_status ? item.workorder_status.title : '',
              schstartdate: item.date_scheduled_start,
              workrequest: item.work_request ? item.work_request.id : '',
              relatedWO: 'Yes'
            })
          }
        })
        relatedWOItems = utility._sortItems(relatedWOItems, 'createdOn', true)
        setRelatedWOCount(relatedWO.length)
      }

      setRelatedWOItems(relatedWOItems)
      setLoadingRelatedWO(false)
    } catch (err) {
      console.log(err)
      setLoadingRelatedWO(false)
    }
  }

  const getWODocument = async (woid: any) => {
    const dataToPassToService: any = {}
    dataToPassToService.workorder_id = +woid
    try {
      setDocumentLoading(true)
      const wodoc: any = await utility.genericGetDataByIdAPICall(
        Config.workorderDocumentListGet,
        dataToPassToService.workorder_id
      )

      let DocPhotosItemsData: any[] = []
      if (wodoc !== null && wodoc.length !== 0) {
        wodoc.forEach((item: any) => {
          if (item.file_url != null) {
            DocPhotosItemsData.push({
              document: item.file_name ? item.file_name : '',
              //uploadedon: item.created,
              id: item.id ? item.id : ' ',
              //uploadedby: item.updatedBy ? item.updatedBy : null,
              fileURL: item.file_url ? item.file_url : '',
              title: item.title ? item.title : '',
              comments: item.comments ? item.comments : ''
            })
          }
        })
        DocPhotosItemsData = utility._sortItems(DocPhotosItemsData, 'document', true)
        setDocPhotosCount(wodoc.length)
        setDocPhotosItems(DocPhotosItemsData)
        setDocumentLoading(false)
      } else {
        setDocPhotosCount(0)
        setDocPhotosItems([])
        setDocumentLoading(false)
      }
    } catch (err) {
      console.log(err)
      setDocumentLoading(false)
    }
  }

  const viewDetails = (item: any) => {
    setDetails(item)
    setShowModal(true)
  }

  const confirmDialogProps = {
    showDialog: showConfirmAlert,
    message: ConfirmalertMessage,
    title: 'worxOnline',
    onClose: () => {
      setConfirmAlertMessage('')
      setShowConfirmAlert(false)
    },
    onOkButton: () => selectAssets(Asset),
    onCancelButton: () => {
      setConfirmAlertMessage('')
      setShowConfirmAlert(false)
    }
  }

  const alertDialogProps = {
    showDialog: showAlert,
    message: alertMessage,
    title: Config.productTitle,
    onClose: () => {
      setAlertMessage('')
      setShowAlert(false)
    },
    hideAlertButton: hideAlertButton
  }

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={Config.productTitle}
        onClose={() => {
          setAlertMessage('')
          setShowAlert(false)
        }}
        hideAlertButton={hideAlertButton}
      />
    )
  }

  const ConfirmAlert = () => {
    return (
      <ConfirmDialog
        showDialog={showConfirmAlert}
        message={ConfirmalertMessage}
        title={'worxOnline'}
        onClose={() => {
          setConfirmAlertMessage('')
          setShowConfirmAlert(false)
        }}
        onOkButton={() => selectAssets(Asset)}
        onCancelButton={() => {
          setConfirmAlertMessage('')
          setShowConfirmAlert(false)
        }}
      />
    )
  }

  const viewDocument = (item: any) => {
    if (item) {
      const dataToPassToService: any = {}
      if (!item.document) {
        setAlertMessage('Unable to view this document. \n File URL or File name does exist')
        setShowAlert(true)

        return
      }
      // dataToPassToService.fileName = item.document
      // dataToPassToService.fileUrl = item.fileURL

      // utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {
      //   window.open(doc, '_blank')
      // })

      const fileURL = item.fileURL.toLowerCase()
      window.open(fileURL, '_blank')
      // fetch(fileURL)
      //   .then(response => response.blob())
      //   .then(blob => {
      //     const blobURL = URL.createObjectURL(blob)
      //     window.open(blobURL, '_blank')
      //   })
      //   .catch(error => {
      //     console.error('Error downloading or viewing file:', error)
      //     setAlertMessage('Error downloading or viewing the file')
      //     setShowAlert(true)
      //   })
    }
  }

  const viewTimeDetails = (item: any) => {
    setTimeEditdetails(item)
    setShowTimesheetModal(true)
  }

  const renderTimesheetDetailsModal = () => {
    return (
      <DisplayDetailsModal
        columns={_worktimescolumns}
        details={timeEditdetails}
        showModal={showTimesheetModal}
        onClick={() => setShowTimesheetModal(false)}
      />
    )
  }

  const openWOModal = () => {
    setShowCommsModal(true)
    setCallAfterInit(true)
  }

  const viewTimeForPlantDetails = (item: any) => {
    setTimeEditForPlantdetails(item)
    setShowPlantEditTimeModal(true)
  }

  const renderPlantEditTimeModal = () => {
    return (
      <DisplayDetailsModal
        columns={_plantusagecolumns} // Replace `_plantusagecolumns` with the actual variable or value
        details={timeEditForPlantdetails}
        showModal={showPlantEditTimeModal}
        onClick={() => setShowPlantEditTimeModal(false)}
      />
    )
  }

  const _closeCommsModal = () => {
    setShowCommsModal(false)
    setCallAfterInit(false)
  }

  const _closeNotesModal = () => {
    setShowNotesPanel(false)
    setCallAfterInit(false)
  }

  const stopCommsAfterInit = () => {
    setCallAfterInit(false)
  }

  const renderMapModal = () => {
    return (
      <>
        {workOrderDetails?.workorder_location != null ? (
          <MapModal
            polylines={false}
            center={marker}
            showModal={openMapModal}
            onClick={() => setOpenMapModal(false)}
            address={workOrderDetails.workorder_location}
            //showMarker={true}
            singlemarker={marker}
            zoom={19}
          />
        ) : (
          'Map Not Found'
        )}
      </>
    )
  }

  const workorderUpdated = () => {
    setShowCommsModal(false)
    setShowNotesPanel(false)
    setOpenPlantAssignModal(false)

    setTimeout(() => {
      loadWorkOrderDetails(workOrderID)
      getWorkOrderHistory(workOrderID)
      getPlantUsageData(workOrderID)
      getTimesheetdata(workOrderID)
    }, 500)
  }

  const getcrew = async (data: any) => {
    const dataToPassToService: any = {}
    dataToPassToService.id = data.crew_id
    await utility.genericGetDataByIdAPICall(Config.crewlistname, dataToPassToService.id).then(
      (crewdata: any) => {
        if (crewdata && crewdata.length > 0) {
          const _crewItemData: any[] = []
          crewdata.forEach((element: any) => {
            if (data.crew_id === element.id) {
              setCrewName(element.title)
              setDepartmentName(element.department?.title)
            }

            _crewItemData.push({
              id: element.id,
              text: element.title,
              teamId: element.team?.id,
              departmentId: element.team?.department_id
            })
          })

          setCrewItems(utility._sortItems(_crewItemData, 'text', false))
          //setCrewItems(sortedItems)
          //console.log('crew Items in detail : ' + crewItems + 'or' + sortedItems)
        }
      },
      err => {
        console.log(err)
        setLoading(false)
      }
    )
  }

  const renderEditWOPanel = () => {
    return (
      <EditWorkOrder
        workorder={workOrderDetails}
        showModal={showCommsModal}
        selectedcrew={CrewName}
        callAfterInit={callAfterInit}
        onClick={workorderUpdated}
        onClose={workorderUpdated}
        currentUser={props.currentUser}
        context={props.context}
        aadHttpClient={props.aadHttpClient}
        priorityId={priorityId}
        statustext={statusText}
        statusId={statusId}
        crewItems={crewItems}
        StatusItems={statusItems}
        AssetDetails={assetWOItems}
        priorityItems={priorityItems}
      />
    )
  }

  const renderPlantAssignModal = () => {
    return (
      <AssignPlant
        workorder={workOrderDetails}
        showModal={openPlantAssignModal}
        onClick={workorderUpdated}
        onClose={closePlantAssignModal}
        context={props.context}
        currentUser={props.currentUser}
        aadHttpClient={props.aadHttpClient}
        WorkOrderID={workOrderID}
      />
    )
  }

  const closePlantAssignModal = (): void => {
    setOpenPlantAssignModal(false)
  }

  const _closeModalofAttachment = (item: any): void => {
    setShowModalAttachment(false)
    if (item === true) {
      setAlertMessage('Attachment(s) uploaded successfully.')
      setShowAlert(true)
    }
  }

  const renderNotes = () => {
    return (
      <AddNotes
        workorder={workOrderDetails}
        showModal={showNotesPanel}
        callAfterInit={callAfterInit}
        onClick={workorderUpdated}
        onClose={_closeNotesModal}
        currentUser={props.currentUser}
        context={props.context}
        aadHttpClient={props.aadHttpClient}
      />
    )
  }

  const renderAttachmentModal = () => {
    return (
      <AddAttachment
        showModal={showModalAttachment}
        onClose={_closeModalofAttachment}
        workorderID={workOrderID}
        context={props.context}
        details={workOrderDetails}
        aadHttpClient={props.aadHttpClient}
        currentUser={props.currentUser}
        message={message}
        updateDoc={() => setTimeout(getWODocument, 2000)}
      />
    )
  }

  const message = () => {
    setAlertMessage('Work order document has been added')
    setShowAlert(true)
  }

  const handleSelect = (event: any, newValue: any) => {
    setSelected(newValue)
  }

  const renderDetialsModal = () => {
    return (
      <DisplayDetailsModal
        columns={DefectsWOItems}
        details={defectDetails}
        //mode={mode}
        showModal={showDefectDetailsModal}
        onClick={() => {
          setShowDefectDetailsModal(false)
          setDefectDetails(null)
        }}
      />
    )
  }

  const viewRequistionDetail = (item: any) => {
    setShowModalofPurchaseReq(true)
    setPurchaseReqDetails(item)
  }

  const renderPurchaseRequistionModal = () => {
    return (
      <DisplayDetailsModal
        columns={purchaseRequisitionItems}
        details={purchaseReqDetails}
        showModal={showModalofPurchaseReq}
        onClick={() => setShowModalofPurchaseReq(false)}
      />
    )
  }

  const renderAssetMap = () => {
    return (
      <AssetMap
        address={workOrderDetails?.workorder_location ? workOrderDetails.workorder_location : ''}
        polylines={false}
        selectAssets={(Asset: any) => selectAssetsonMap(Asset)}
        iFramePanelOpened={iFramePanelOpened}
        onClose={_onDismissMap}
        selectParent={selectParent}
        selectModeMap={selectModeMap}
      />
    )
  }

  const _onDismissMap = () => {
    setIFramePanelOpened(false)
  }

  const selectAssetsonMap = (selectedAssetArray: any) => {
    const flatCoordinates: any = []
    let tempAsset: any
    selectedAssetArray.forEach((selectedAsset: any) => {
      const Asset = JSON.parse(selectedAsset.properties)

      // get cordinates in lat/lng format
      const flatCoordinatesArray = Asset.geometry.flatCoordinates

      flatCoordinatesArray.forEach((element: any, i: any) => {
        if (i % 2 === 0) {
          flatCoordinates.push({
            lat: flatCoordinatesArray[i + 1],
            lng: flatCoordinatesArray[i]
          })
        }
      })
      tempAsset = {
        Register: 'Asset',
        AssetID: selectedAsset.id,
        AssetNumber: Asset.UFI ? Asset.UFI : Asset.UFI_1 ? Asset.UFI_1 : Asset.UFI_US,
        AssetType: Asset.Asset_Type,
        AssetClass: Asset.Asset_Class,
        Description: selectedAsset.name,
        Suburb: Asset.Asset_Suburb,
        Location: Asset.Asset_Location,
        GISInfo: {
          type: 'Feature',
          geometry: {
            type: selectedAsset.shape,
            coordinates: flatCoordinates
          },
          properties: {
            AssetID: selectedAsset.id,
            Name: selectedAsset.name,
            Type: Asset.Asset_Type,
            Hierarchy: '1.0',
            Zone: Asset.Asset_Suburb,
            RoadGroupName: selectedAsset.name,
            Link: null,
            GID: Asset.GmlID,
            ExternalOSID: null,
            Location: Asset.Location,
            Ownership: 'Council as CoM',
            ExtAssetID: '',
            subclass: Asset.Asset_Subclass
          }
        },
        ERPInfo: {
          MaintenanceScheduleCode: 'NR_BWT_M_INSP',
          MaintenanceHistoryUniqueID: '0',
          PercentAllocation: '1.000000',
          AttrCode: 'ASSET_TYPE',
          SearchPath: 'Buildings'
        },
        AssetQuestions: [],
        AttachmentUrls: []
      }
      if (Asset.Location) {
        setAssetLocation(Asset.Location)
      }
    })
    const Parseddata = JSON.parse(selectedAssetArray[0].properties)

    setAssets(tempAsset)
    AssetsRef.current = tempAsset
    setLat(selectedAssetArray[0].center[1].toString())
    setLng(selectedAssetArray[0].center[0].toString())
    setLatLong(flatCoordinates[0]) 
    setAssetLocation(Parseddata.Asset_Location)
    setIFramePanelOpened(false)
    setSelectParent(false)
    setSelectModeMap(false)

    updateWorkOrder()
  }

  // useEffect(() => {
  //   updateWorkOrder()
  // }, [selectAssetsonMap])

  const selectAssets = (Asset: any) => {
    setNewAsset(Asset.AssetNumber)
    const dataToPassToService: any = {}
    dataToPassToService.workorderId = workOrderID
    utility
      .genericUpdatePortalAPICall(Config.AddEditAset, dataToPassToService)
      .then((data: any) => {
        updateHistoryAsset()
      })
      .catch((err: any) => {
        console.log(err)
        setAlertMessage('Something went wrong. Please try again')
        setShowAlert(true)
        setHideAlertButton(false)
        setLoading(false)
        setConfirmAlertMessage('')
        setShowConfirmAlert(false)
      })
  }

  const updateHistoryAsset = () => {
    const currentDateTime = new Date()
    const dataToPassToServiceForHistory: any = {}
    dataToPassToServiceForHistory.status_id = '8'
    //dataToPassToServiceForHistory.createdBy = props.currentUser ? props.currentUser.Email : 'naga@atensystems.com'
    //dataToPassToServiceForHistory.updatedBy = props.currentUser ? props.currentUser.Email : 'naga@atensystems.com'
    dataToPassToServiceForHistory.comments = 'Asset changed'
    //dataToPassToServiceForHistory.tenantId = Config.tenantId
    dataToPassToServiceForHistory.workorder_id = +workOrderID
    //dataToPassToServiceForHistory.created = currentDateTime.toISOString()
    //dataToPassToServiceForHistory.updated = currentDateTime.toISOString()
    dataToPassToServiceForHistory.date_record_created_in_db = currentDateTime.toISOString()
    utility
      .genericPostAPICall(Config.workorderHistoryList, dataToPassToServiceForHistory)
      .then((data: any) => {
        loadWorkOrderDetails(workOrderID)
        setAlertMessage('Asset has been added.')
        setShowAlert(true)
        setHideAlertButton(false)
        setPreviousPage(true)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const _onClosePanel = (): void => {
    setShowAssetPanel(false)
  }

  const loaderTab = (title: any, items: any, isLoading: any) => {
    const count = items.length
    if (isLoading) {
      return (
        <span className='tabHeading'>
          {title} <LoadIndicator id='small-indicator' height={15} width={15} />
        </span>
      )
    } else {
      return (
        <span className='tabHeading'>
          {title} [{count}]
        </span>
      )
    }
  }

  // const handleSelectTopTab = (event: any, newValue: any) => {
  //   setSelectedTopTab(newValue)
  // }

  const renderPlantEditTimeModall = () => {
    return (
      <DisplayDetailsModal
        columns={_plantusagecolumns}
        details={timeEditForPlantdetails}
        showModal={showPlantEditTimeModal}
        onClick={() => setShowPlantEditTimeModal(false)}
      />
    )
  }

  const updateWorkOrder = () => {
    setAlertMessage(<Spinner label='Adding Asset' />)
    setHideAlertButton(true)
    setShowAlert(true)

    let JSONAsset = ''
    const dataToPassToService: any = {}
    const woID = workOrderID
    dataToPassToService.id = +woID
    //dataToPassToService.tenantId = Config.tenantId
    dataToPassToService.location_geom = { x: lat ? lat : 0, y: lng ? lng : 0 }
    dataToPassToService.workorder_location = assetLocation
    //dataToPassToService.updated = new Date()
    //dataToPassToService.updatedBy = props.currentUser ? props.currentUser.Email : 'naga@atensystems.com'
    if (Assets || AssetsRef.current) {
      JSONAsset = Assets ? Assets : AssetsRef.current
      dataToPassToService.assets_info = JSONAsset
    }

    utility.genericUpdateNatureStripAPICallForWO(Config.UpdateWO, +woID, dataToPassToService).then(() => {
      updateHistoryAsset()
    })
      .catch((err: any) => {
        console.log(err)
        setAlertMessage('<Something went wrong. Please try again')
        setHideAlertButton(false)
        setShowAlert(true)
        setLoading(false)
      })
  }

  const handleToggleMap = async () => {
    setShowMap(prevShowMap => !prevShowMap)
  }

  return (
    // <div>
    //   {renderAssetMap()}
    //   {showAlert && renderTimesheetDetailsModal()}
    //   {showConfirmAlert && <ConfirmDialog {...confirmDialogProps} />}
    //   {showAlert && <AlertDialog {...alertDialogProps} />}
    // </div>
    <div>
      {showDefectDetailsModal && renderDetialsModal()}
      {showTimesheetModal && renderTimesheetDetailsModal()}
      {showPlantEditTimeModal && renderPlantEditTimeModall()}
      {showModalofPurchaseReq && renderPurchaseRequistionModal()}
      {openMapModal && renderMapModal()}
      {showAlert && alert()}
      <Grid direction='row' container spacing={3}>
        <Grid item xs={12} sm={6}>
          <h2 id='asset-search-types'>
            Work Order Details
            {workOrderDetails && (
              <>
                <span>
                  {' '}
                  - {workOrderDetails.id} ({workOrderDetails.title})
                </span>
              </>
            )}
          </h2>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className='icon-buttons-list'>
            <Tooltip title='Edit Work Order'>
              <IconButton
                onClick={openWOModal}
                disabled={workOrderDetails != null && workOrderDetails.status === 'Complete' ? true : false}
              >
                <EditIcon titleAccess='Edit Work Order' className={'iconcolor'} />
              </IconButton>
            </Tooltip>
            <Tooltip title='Add Notes'>
              <IconButton
                onClick={() => setShowNotesPanel(true)}
                disabled={workOrderDetails != null && workOrderDetails.status === 'Complete' ? true : false}
              >
                <NoteAddRounded titleAccess='Add Notes' className={'iconcolor'} />
              </IconButton>
            </Tooltip>
            <Tooltip title='Attach Document'>
              <IconButton
                onClick={() => setShowModalAttachment(true)}
                disabled={workOrderDetails != null && workOrderDetails.status === 'Complete' ? true : false}
              >
                <AttachFileOutlinedIcon titleAccess='Attach Document' className={'iconcolor'} />
              </IconButton>
            </Tooltip>
            <Tooltip title='Add/Edit Asset'>
              <IconButton
                onClick={() => {
                  setIFramePanelOpened(true)
                  setSelectModeMap(true)
                  setSelectParent(false)
                }}
                disabled={workOrderDetails != null && workOrderDetails.status === 'Complete' ? true : false}
              >
                <EditIcon titleAccess='Add / Edit Asset' className={'iconcolor'} />
                
              </IconButton>
            </Tooltip>
            <Tooltip title='Assign/Modify Plant/Vehicle'>
              <IconButton onClick={() => setOpenPlantAssignModal(true)}>
              <BuildIcon titleAccess='Assign/Modify Plant/Vehicle' className={'iconcolor'} />
               
              </IconButton>
            </Tooltip>
            <Tooltip title='Show Map'>
              <IconButton onClick={handleToggleMap}>
              <LocationIcon className={'iconcolor'} />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      <div className={'ui-container wo-details'}>
        {workOrderDetails && workOrderDetails != null ? (
          <div className='detailFieldDiv'>
            <Grid container  spacing={1} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
              {!showMap ? (
                <>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Work Order #</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{workOrderDetails.id}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Work Req #</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.work_request_id ? 
                            <NavLink className="WOlink" to={workReqlink}>{workOrderDetails.workRequestId}</NavLink> : ''}
                          
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Created By</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{workOrderDetails.created_by}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Created On</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.created !== '' && workOrderDetails.created !== null
                            ? moment(workOrderDetails.created).format('DD/MM/YY h:mm a')
                            : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Location</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>{workOrderDetails.workorder_location}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Activity</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.workorder_type ? workOrderDetails.workorder_type.title : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Description</Typography>
                      </Grid>
                      <Grid item>
                      <ReadMore text={workOrderDetails.description} maxWords={10}/>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Priority</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.priority ? workOrderDetails.priority.title : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Status</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.workorder_status ? workOrderDetails.workorder_status.title : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Risk Assessment</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.risk_assessment_required === true ? 'Yes' : 'No'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Before Photo</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.photo_required_before === true ? 'Yes' : 'No'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>After Photo</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.photo_required_after === true ? 'Yes' : 'No'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Schedule Start</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.date_scheduled_start
                            ? moment(workOrderDetails.date_scheduled_start).format('DD/MM/YY h:mm a')
                            : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Required Date</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.required_by_date
                            ? moment(workOrderDetails.required_by_date).format('DD/MM/YY h:mm a')
                            : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Completion Date</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.date_completed
                            ? moment(workOrderDetails.date_completed).format('DD/MM/YY h:mm a')
                            : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {plantWOItems && plantWOItems.length > 0 ? (
                    <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                      <Grid container direction='column' item spacing={1}>
                        <Grid item>
                          <Typography variant='body1'>Plant</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant='body2'>
                            {plantWOItems.map((p: any, index: number) => {
                              return (
                                <span key={index}>
                                  {`${p.plantResourceCode} - ${p.plantTitle} (${p.operatorFullName})`}
                                  <br />
                                </span>
                              )
                            })}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'> ERP Work Order #:</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.erp_workorder_number ? (
                            <a
                              href={global.AsseticWOLink + workOrderDetails.erp_workorder_number}
                              className='WOlink'
                              rel='noreferrer'
                              target='_blank'
                            >
                              {workOrderDetails.erp_workorder_number}
                            </a>
                          ) : (
                            'N/A'
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>ERP Req #</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.work_request_id ? (
                            <a
                              href={global.AsseticWRLink + workOrderDetails.work_request_id}
                              className='WOlink'
                              rel='noreferrer'
                              target='_blank'
                            >
                              {workOrderDetails.work_request_id}
                            </a>
                          ) : (
                            'N/A'
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Department</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.department ? workOrderDetails.department.title : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Team</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.team ? workOrderDetails.team.title : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ flex: '0 0 20%' }}>
                    <Grid container direction='column' item spacing={1}>
                      <Grid item>
                        <Typography variant='body1'>Crew</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body2'>
                          {workOrderDetails.crew ? workOrderDetails.crew.title : ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid container item xs={12}>
                  {marker && marker.lat !== 0 ? (
                    <GoogleMapComponent
                      singleMarkers={marker}
                      center={marker}
                      //useStyle={useStyle}
                      iconColor={'33cc33'}
                      zoom={15}
                      height='35vh'
                      showMarker={true}
                      showMap={showMap}
                    />
                  ) : (
                    <h3>No Position Data</h3>
                  )}
                </Grid>
              )}
            </Grid>
          </div>
        ) : (
          <div>
            <Spinner size='large' label='Loading details' />
            <br />
          </div>
        )}
      </div>

      <div className='display-container asset-details'>
        <h2>Other Details</h2>
        <Grid item xs={12} sm={6}>
            <Tabs value={selected} variant='fullWidth' onChange={handleSelect} className='results-container tabs-section'>
              <Tab value={0} label={loaderTab('History', WOhistoryItems, historyLoading)} />
              <Tab value={1} label={loaderTab('Documents', DocPhotosItems, documentloading)} />
              <Tab value={2} label={loaderTab('Timesheets', worktimeItems, timesheetLoading)} />
              <Tab value={3} label={loaderTab('Plant & Fleet usage', plantusageItems, plantUsageLoading)} />
              <Tab value={4} label={loaderTab('Material Usage', materialWOItems, loadingMaterialWO)} />
              <Tab value={5} label={loaderTab('Related Work Orrders', relatedWOItems, loadingrelatedWO)} />
              <Tab value={6} label={loaderTab('Assets', assetWOItems, loadingAssetDetail)} />
              <Tab value={7} label={<span className='tabHeading'>Comment</span>} />
            </Tabs>
            <TabPanel value={selected} index={0}>
              {!historyLoading ? (
                <>
                  {WOhistoryItems && WOhistoryItems.length > 0 && (
                    <div className='results-container full-width'>
                      <DataTable
                        //style={{ width: '100%' }}
                        filteralbe={false}
                        groupable={false}
                        dataSource={WOhistoryItems}
                        columns={_WOhistorycolumns}
                        // fileName={'Timesheet'}
                        // columnChooser={'prestartchecklistColumns'}
                        selectMode={false}
                        showHideColumnMenu={false}
                        callAfterInit={callAfterInit}
                        stopCommsAfterInit={() => stopCommsAfterInit()}
                        loading={historyLoading}
                        allowExportSelectedData={false}
                        showNewBtn={false}
                        hideExport={true}
                        hideSearchPanel={true}
                        showCommandBtn={false}
                        showViewDetailBtn={false}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <br />
                  <Spinner size='large' className='loader' />
                </>
              )}
            </TabPanel>
            <TabPanel value={selected} index={1}>
              {!loading ? (
                <>
                  {DocPhotosItems && DocPhotosItems.length > 0 && (
                    <div className='results-container full-width'>
                      <DataTable
                        //style={{ width: '100%' }}
                        filteralbe={false}
                        groupable={false}
                        dataSource={DocPhotosItems}
                        columns={_Docphotoscolumns}
                        selectMode={false}
                        showHideColumnMenu={false}
                        callAfterInit={callAfterInit}
                        stopCommsAfterInit={stopCommsAfterInit}
                        loading={loading}
                        allowExportSelectedData={false}
                        showNewBtn={false}
                        hideExport={true}
                        hideSearchPanel={true}
                        showCommandBtn={false}
                        showViewDetailBtn={false}
                        viewFile={(e: any) => viewDocument(e)}
                      />
                    </div>
                  )}
                </>
              ) : (
                <Spinner size='large' className='loader' />
              )}
            </TabPanel>
            <TabPanel value={selected} index={2}>
              {!loading ? (
                <>
                  {worktimeItems && worktimeItems.length > 0 && (
                    <div className='results-container full-width'>
                      <DataTable
                        //style={{ width: '100%' }}
                        filteralbe={true}
                        groupable={true}
                        dataSource={worktimeItems}
                        columns={_worktimescolumns}
                        selectMode={false}
                        showHideColumnMenu={false}
                        callAfterInit={callAfterInit}
                        stopCommsAfterInit={() => stopCommsAfterInit()}
                        loading={loading}
                        allowExportSelectedData={false}
                        showNewBtn={false}
                        hideExport={true}
                        hideSearchPanel={true}
                        showCommandBtn={false}
                        showViewDetailBtn={false}
                      />
                    </div>
                  )}
                </>
              ) : (
                <Spinner size='large' className='loader' />
              )}
            </TabPanel>
            <TabPanel value={selected} index={3}>
              {plantusageItems && plantusageItems.length > 0 && (
                <div className='results-container full-width'>
                  <DataTable
                    //style={{ width: '100%' }}
                    filteralbe={false}
                    groupable={false}
                    dataSource={plantusageItems}
                    columns={_plantusagecolumns}
                    // fileName={'Timesheet'}
                    // columnChooser={'prestartchecklistColumns'}
                    selectMode={false}
                    showHideColumnMenu={false}
                    callAfterInit={callAfterInit}
                    stopCommsAfterInit={() => stopCommsAfterInit()}
                    loading={loading}
                    allowExportSelectedData={false}
                    showNewBtn={false}
                    hideExport={true}
                    hideSearchPanel={true}
                    showCommandBtn={false}
                    showViewDetailBtn={false}
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel value={selected} index={4}>
              {materialWOItems && materialWOItems.length > 0 && (
                <div className='results-container full-width'>
                  <DataTable
                    //style={{ width: '100%' }}
                    filteralbe={false}
                    groupable={false}
                    dataSource={materialWOItems}
                    columns={_materialcolumns}
                    selectMode={false}
                    showHideColumnMenu={false}
                    callAfterInit={callAfterInit}
                    stopCommsAfterInit={() => stopCommsAfterInit()}
                    loading={loadingMaterialWO}
                    allowExportSelectedData={false}
                    showNewBtn={false}
                    hideExport={true}
                    hideSearchPanel={true}
                    showCommandBtn={false}
                    showViewDetailBtn={false}
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel value={selected} index={5}>
              {relatedWOItems && relatedWOItems.length > 0 && (
                <div className='results-container full-width'>
                  <DataTable
                    //style={{ width: '100%' }}
                    filteralbe={false}
                    groupable={false}
                    dataSource={relatedWOItems}
                    columns={_relatedWOcolumns}
                    selectMode={false}
                    showHideColumnMenu={false}
                    callAfterInit={callAfterInit}
                    stopCommsAfterInit={() => stopCommsAfterInit()}
                    loading={loadingrelatedWO}
                    allowExportSelectedData={false}
                    showNewBtn={false}
                    hideExport={true}
                    hideSearchPanel={true}
                    showCommandBtn={false}
                    showViewDetailBtn={false}
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel value={selected} index={6}>
              {assetWOItems && assetWOItems.length > 0 && (
                <div className='results-container full-width'>
                  <DataTable
                    //style={{ width: '100%' }}
                    filteralbe={false}
                    groupable={false}
                    dataSource={assetWOItems}
                    columns={_AssetWOcolumns}
                    selectMode={false}
                    showHideColumnMenu={false}
                    callAfterInit={callAfterInit}
                    stopCommsAfterInit={() => stopCommsAfterInit()}
                    loading={loadingAssetDetail}
                    allowExportSelectedData={false}
                    showNewBtn={false}
                    hideExport={true}
                    hideSearchPanel={true}
                    showCommandBtn={false}
                    showViewDetailBtn={false}
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel value={selected} index={7}>
              <div className='results-container full-width'>
                {workOrderDetails && workOrderDetails.comments ? (
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          {/* <div>{workorderdetails.WOComments}</div> */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${workOrderDetails.comments.replace(/\n/g, '<br />')}`
                            }}
                          ></div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <p className={'alignCenter'}>No comment found</p>
                )}
              </div>
            </TabPanel>
          
        </Grid>
      </div>
      <div>{workOrderDetails && renderEditWOPanel()}</div>
      <div>{renderAttachmentModal()} </div>
      <div>{renderNotes()} </div>
      {openPlantAssignModal && renderPlantAssignModal()}
      {renderAssetMap()}
    </div>
  )
}

export default SearchWODetail
