import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/dropzone.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "./AuthContext";
import Home from './Home'; 
import SignIn from './components/Login/Login';
import './css/home.css';
 import 'devextreme/dist/css/dx.light.css';

ReactDOM.render(
  // <React.StrictMode>
  <AuthProvider>
  <Home />
</AuthProvider>
// </React.StrictMode>
,
  document.getElementById('root')
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
