import React, { useState, useEffect } from 'react'
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../Common/CustomASDataGrid';
import Spinner from '../../Common/ReusableComponents/Spinner';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data';

interface LoadResult {
  data: any[]
  totalCount: number
}
const RiskCategory = (props: any) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [items, setItems] = useState<any[]>([])

    const columns = [
      {
        field: 'id',
        title: 'ID',
        width: 80,
        type: 'string',
        allowHiding: false,
        visibleColumn: false,
        formItem: {
          visible: false
        }
      },
      {
        field: 'title',
        title: 'Title',
        width: 250,
        type: 'string',
        allowHiding: false,
        isRequired: true
      },
      {
        field: 'created',
        title: 'Created',
        width: 160,
        type: 'datetime',
        allowHiding: true,
        formItem: {
          visible: false
        }
      },
      {
        field: 'created_by',
        title: 'Created By',
        type: 'string',
        allowHiding: true,
        formItem: {
          visible: false
        }
      }
    ];
  
    useEffect(() => {
      fetchItemsData()
    }, [])
  
    const fetchItemsData = async () => {
      setLoading(true)
      try {
        const itemsResponse = await utility.genericGetAPICall(Config.RiskCategory)
        let items: any[] = []
  
        for (let r of itemsResponse) {
          items.push({
            ...r,
            id: r.id,
            active: r.is_active ? true : false
          })
        }
        items = utility._sortItems(items, 'id', true)
        setItems(items)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }


    const dataSource = new CustomStore({
      load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
        return {
          data: items,
          totalCount: items.length
        }
      },
      key: 'id',
      onLoaded: () => {
        console.log('Data loaded')
        setLoading(false)
        setDataLoaded(true)
      },
      insert: async (values: any) => {
        try {
          await utility.genericPostAPICall(Config.RiskCategory, values)
          await fetchItemsData()
        } catch (error: any) {
          if (error instanceof Error && error.message.startsWith('Network response was not ok')) {
            try {
              const errorData = JSON.parse(error.message.replace('Network response was not ok: ', ''))
              const errorMessage = utility.extractErrorMessage(errorData)
              throw new Error(errorMessage)
            } catch (parseError) {
              throw new Error('Failed to process error response.')
            }
          } else {
            throw new Error('Failed to post data.')
          }
        }
      },
      update: async (key: any, values: any) => {
        try {
          await utility.genericUpdatePatchAPICall(Config.RiskCategory, key, values)
          await fetchItemsData()
        } catch (error: any) {
          throw error
        }
      },
      remove: async (key: any) => {
        try {
          await utility.genericDeleteAPICall(Config.RiskCategory, key)
          await fetchItemsData()
        } catch (error: any) {
          throw error
        }
      }
    })
  
    return (
      <>
        <div>
          {!loading ? (
            <div className='results-container'>
              <DataTable
                dataSource={dataSource}
                style={{ width: '100%' }}
                filteralbe={true}
                groupable={true}
                columns={columns}
                fileName={'TCP'}
                columnChooser={'TCPColumns'}
                selectionMode={false}
                showHideColumnMenu={true}
                loading={loading}
                allowExportSelectedData={false}
                columnHidingEnabled={true}
                showCommandBtn={false}
                hideSearchPanel={false}
                hideCommandDeleteBtn={false}
                showNewBtn={false}
                showViewDetailBtn={false}
                hideNewBtn={true}
                //defaultfiltervalue={filterValue}
                showMoreBtn={true}
                visible={dataLoaded}
              />
            </div>
          ) : (
            <Spinner size='large' className='loader' />
          )}
        </div>
      </>
    )
  }
 
export default RiskCategory;