import React, { useState, useEffect } from 'react'
import ArrayStore from 'devextreme/data/array_store'
import List from 'devextreme-react/list'
import SchedulerCard from './SchedulerCard'
import Popover from '@mui/material/Popover'
import Button from '@mui/material/Button'
import DateBox from 'devextreme-react/date-box'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import utility from '../../../classes/utilityOld'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface SchedulerWOListViewProps {
  currentDate?: Date
  currentView?: string
  workorders?: any
  selectedWorkorderbyCrew?: any
  onViewDateChange: (e: any, view: any) => void
  stopOnCrewSelected?: () => void
  onDragOver: (e: any) => void
  onDrop: (e: any) => void
  onItemDragStart?: (e: any) => void
  onItemDragEnd?: (e: any) => void
  viewItem?: (e: any) => void
  viewWO?: (e: any) => void
  zoomToSelectWO?: (e: any) => void
  onCrewSelected?: boolean
  view?: any
  draggingGroupName?: any
  mapView?: any
  onDragStart?: any
  dashBoardCard?: any
  _onShowNotesPanel?: (e: any) => void
  unassignWO?: (e: any) => void
  crews?: any
  selectedCrewInSchedulerList?: any
}

const SchedulerWorkOrderListView: React.FC<SchedulerWOListViewProps> = props => {
  const [currentDate, setCurrentDate] = useState<Date | undefined>(props.currentDate)
  const [currentView, setCurrentView] = useState<string | undefined>(
    props.currentView === 'Calendar' ? 'Month' : props.currentView === 'Agenda' ? 'Day' : props.currentView
  )
  const [showMoreSettingMenu, setShowMoreSettingMenu] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [workorderDataOptions, setWorkorderDataOptions] = useState<any>({
    store: new ArrayStore({
      data: props.selectedWorkorderbyCrew
        ? utility._sortItems(props.selectedWorkorderbyCrew, 'fieldstatus', true)
        : props.selectedWorkorderbyCrew,
      key: 'Workordernumber'
    }),
    group: 'crewtitle',
    searchExpr: ['worktype', 'Workordernumber', 'text', 'fieldstatus', 'suburb', 'crew', 'priority']
  })

  useEffect(() => {
    setCurrentDate(props.currentDate)
    setCurrentView(
      props.currentView === 'Calendar' ? 'Month' : props.currentView === 'Agenda' ? 'Day' : props.currentView
    )
    setWorkorderDataOptions({
      ...workorderDataOptions,
      store: new ArrayStore({
        data: props.selectedWorkorderbyCrew
          ? utility._sortItems(props.selectedWorkorderbyCrew, 'fieldstatus', true)
          : props.selectedWorkorderbyCrew,
        key: 'Workordernumber'
      })
    })
  }, [props.currentDate, props.currentView, props.selectedWorkorderbyCrew])

  const handleShowHildePopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setShowMoreSettingMenu(true)
  }

  const handleCloseShowHildePopOver = () => {
    setAnchorEl(null)
    setShowMoreSettingMenu(false)
  }

  const onViewChanged = (e: any) => {
    setCurrentView(e.itemData.text)
    setShowMoreSettingMenu(false)
    if (props.onViewDateChange !== undefined) {
      props.onViewDateChange(currentDate, e.itemData.text)
    }
  }

  const renderShowMoreSettingMenu = () => (
    <Popover
      id={'i'}
      open={showMoreSettingMenu}
      anchorEl={anchorEl}
      onClose={handleCloseShowHildePopOver}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <div style={{ width: '120px' }}>
        <List
          selectionMode='single'
          dataSource={[
            { id: 'Day', text: 'Day' },
            { id: 'Work Week', text: 'Work Week' },
            { id: 'Week', text: 'Week' },
            { id: 'Month', text: 'Month' }
          ]}
          onItemClick={(e: any) => onViewChanged(e)}
          elementAttr={{ class: 'list' }}
        />
      </div>
    </Popover>
  )

  return (
    <div>
      {showMoreSettingMenu && renderShowMoreSettingMenu()}
      <div className={'ptlr-20'}>
        Work Orders for crew ({props.selectedWorkorderbyCrew && props.selectedWorkorderbyCrew.length})
      </div>
      <div className={'m-10 p-10'}>
        <div className='displayFlex mb-10'>
          <div>
            <DateBox
              style={{ height: '38px' }}
              value={currentDate}
              stylingMode={'outlined'}
              useMaskBehavior={true}
              onValueChanged={(e: any) => {
                setCurrentDate(e.value)
                props.onViewDateChange(e.value, currentView)
              }}
              displayFormat={currentView === 'Month' ? 'month' : 'longDate'}
              type='date'
            />
          </div>
          <Button
            variant='contained'
            color='primary'
            onClick={handleShowHildePopOver}
            endIcon={<ExpandMoreIcon className={'primary'} />}
          >
            {currentView}
          </Button>
        </div>
        <div onDragOver={(e: any) => props.onDragOver(e)} onDrop={(e: any) => props.onDrop(e)}>
          {!props.onCrewSelected ? (
            <List
              selectionMode='single'
              dataSource={workorderDataOptions}
              grouped={true}
              searchEnabled={true}
              itemRender={(e: any) => (
                <SchedulerCard
                  woData={e}
                  view={props.view}
                  viewItem={props.viewItem}
                  viewWO={props.viewWO}
                  zoomToSelectWO={props.zoomToSelectWO}
                  draggingGroupName={props.draggingGroupName}
                  onItemDragStart={props.onItemDragStart}
                  onItemDragEnd={props.onItemDragEnd}
                  mapView={props.mapView}
                  schedulerlistDraggingAllowed={true}
                  onDragStart={(event: any, woData: any) => props.onDragStart(event, woData)}
                  dashBoardCard={props.dashBoardCard}
                  _onShowNotesPanel={props._onShowNotesPanel}
                  showUnassignWO={true}
                  unassignWO={props.unassignWO}
                />
              )}
              elementAttr={{ class: 'list' }}
              scrollingEnabled={true}
            />
          ) : (
            <LoadIndicator id='large-indicator' height={60} width={60} />
          )}
        </div>
      </div>
    </div>
  )
}

export default SchedulerWorkOrderListView
