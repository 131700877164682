import React, { useState, useEffect, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import utility from '../../../classes/utility'
import global from '../../../classes/global'
import { Config } from '../../../classes/config'
import AlertDialog from '../../Common/AlertDialog'
import DataTable from '../../Common/DataTable'
import DateBoxInput from '../../Common/ReusableComponents/DateBox'
import SelectBoxInput from '../../Common/ReusableComponents/SelectInputBox'
import TextBoxInput from '../../Common/ReusableComponents/TextBox'
import Spinner from '../../Common/ReusableComponents/Spinner'
import moment from 'moment-timezone'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'

const EquipmentAndVehicles: React.FC<any> = props => {
  const searchResultsText = 'Please Search To Populate Results'
  const [items, setItems] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [department, setDepartment] = useState(
    props.role === global.worxOnline_Contractor || props.role === global.worxOnline_ContractorAdmin
      ? props.Department
      : ''
  )
  const [alertMessage, setAlertMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [departmentItems, setDepartmentItems] = useState<any[]>([])
  const [allDepartmentItems, setAllDepartmentItems] = useState<any[]>([])
  const [title, setTitle] = useState('')
  const [inspectionFailed, setInspectionFailed] = useState({ id: false, text: 'No' })
  const [resultText, setResultText] = useState(searchResultsText)

  const inspectionFailedItems = [
    { id: true, text: 'Yes' },
    { id: false, text: 'No' }
  ]

  useEffect(() => {
    _getDepartmentData()
  }, [])

  const columns = [
    {
      field: 'inspectionFailed',
      title: 'Inspection failed',
      width: 110,
      allowHiding: false,
      type: 'boolean'
    },
    {
      field: 'id',
      title: 'ID',
      // width: 160,
      type: 'string',
      allowHiding: false
    },
    {
      field: 'resourceCode',
      title: 'Resource Code',
      // width: 280,
      type: 'string',
      allowHiding: false
    },
    {
      field: 'title',
      title: 'Title',
      // width: 160,
      type: 'string'
    },
    {
      field: 'category',
      title: 'Category',
      // width: 160,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'regoNumber',
      title: 'Rego Number',
      // width: 160,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'department',
      title: 'Department',
      // width: 160,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'active',
      title: 'Is Active',
      // width: 160,
      type: 'boolean',
      allowHiding: true
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: true,
      allowHiding: true
    },
    {
      field: 'createdBy',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: true,
      allowHiding: true
    }
  ]

  const handleValueChange = (e: any, fieldName: string) => {
    switch (fieldName) {
      case 'title':
        setTitle(e.value)
        break
      default:
        break
    }
  }

  const handleDropdownChange = (e: any, fieldName: string) => {
    switch (fieldName) {
      case 'department':
        setDepartment(e.selectedItem)
        break
      case 'inspection_failed':
        setInspectionFailed(e.selectedItem)
        break
      default:
        break
    }
  }

  const getSearchCriteria = () => {
    let criteriaSelected = false
    let dataToPassToService: any = {}
    if (title) {
      dataToPassToService.title = title
      criteriaSelected = true
    }
    if (department) {
      dataToPassToService.department_id = [department.id]
      criteriaSelected = true
    }
    if (inspectionFailed) {
      dataToPassToService.inspection_failed = inspectionFailed.id
      criteriaSelected = true
    }
    if (!criteriaSelected) {
      setAlertMessage('Please select search criteria')
      setShowAlert(true)
      return
    }
    setLoading(true)
    utility.genericPostAPICall(Config.EqipmentSearchAPI, dataToPassToService).then(
      (data: any) => {
        const _items: any[] = []
        if (!data.length) {
          setAlertMessage('No results found for this criteria')
          setShowAlert(true)
          setItems(_items)
          setLoading(false)
          return
        } else {
          data.forEach((item: any) => {
            _items.push({
              title: item.title,
              id: item.id,
              resourceCode: item.resource_code,
              category: item.category,
              regoNumber: item.rego_number,
              department: item.department,
              inspectionFailed: item.inspection_failed,
              active: item.is_active,
              createdBy: item.created_by,
              created: item.created || null
            })
          })
        }
        if (!_items.length) {
          setAlertMessage('No results found for this criteria')
          setShowAlert(true)
        }
        const sortedItems = utility._sortItems(_items, 'updatedOn', true)
        setItems(sortedItems)
        setLoading(false)
      },
      err => {
        console.log(err)
        setLoading(false)
      }
    )
  }

  const clearFilter = () => {
    setItems([])
    setTitle('')
    setDepartment('')
    setInspectionFailed({ id: false, text: 'No' })
  }

  const _getDepartmentData = () => {
    utility.genericGetAPICall(Config.DepartmentList).then((data: any) => {
      let departmentItems = []

      for (let r of data) {
        departmentItems.push({ id: r.id, text: r.title })
      }
      departmentItems = utility._sortItems(departmentItems, 'text', false)
      setDepartmentItems(departmentItems)
      setAllDepartmentItems(departmentItems)
    })
  }

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={Config.productTitle}
        onClose={() => (setShowAlert(false), setAlertMessage(''))}
      />
    )
  }

  return (
    <>
      {showAlert && alert()}
      <div className='ui-container'>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel shrink className='label bold'>
              Title
            </InputLabel>
            <TextBoxInput
              stylingMode='outlined'
              value={title}
              onValueChanged={(e: any) => handleValueChange(e, 'title')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel shrink className='label bold'>
              Department
            </InputLabel>
            <SelectBoxInput
              selectedItem={department}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'department')}
              items={departmentItems}
              displayExpr={'text'}
              placeholder='Select Department'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputLabel shrink className='label bold'>
              Inspection Failed
            </InputLabel>
            <SelectBoxInput
              stylingMode='outlined'
              displayExpr='text'
              valueExpr='id'
              showClearButton={true}
              items={inspectionFailedItems}
              selectedItem={inspectionFailed}
              onSelectionChanged={(e: any) => handleDropdownChange(e, 'inspection_failed')}
            />
          </Grid>
        </Grid>
        <div className='actions-container'>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <div className='action-buttons'>
                <Button variant='outlined' color='secondary' onClick={clearFilter}>
                  Clear
                </Button>
                <Button variant='outlined' color='primary' className='primary-button' onClick={getSearchCriteria}>
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className=''>
        <h2 className='pl-10'>Results</h2>
        <Typography>{resultText}</Typography>
        {!loading ? (
          <div>
            {items.length > 0 && (
              <div className='results-container'>
                <DataTable
                  dataSource={items}
                  style={{ width: '100%' }}
                  filteralbe={true}
                  groupable={true}
                  columns={columns}
                  fileName={'Equipments'}
                  columnChooser={'EquipmentColumns'}
                  showHideColumnMenu={true}
                  loading={loading}
                  allowExportSelectedData={false}
                  columnHidingEnabled={true}
                  showNewBtn={false}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              </div>
            )}
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  )
}

export default EquipmentAndVehicles
