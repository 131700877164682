import React, { useState, useEffect } from 'react'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import Spinner from '../../Common/ReusableComponents/Spinner'
import global from '../../../classes/global'
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'
import { set } from 'react-hook-form'

interface Crews {
  crew_id: number
  crew_code: string
  crew_name: string
  team_id: number
  department_id: number
}

interface Dept {
  department_id: number
  department_code: string
  department_name: string
}

interface Teams {
  team_id: number
  team_code: string
  team_name: string
  department_id: number
}

interface LoadResult {
  data: any[]
  totalCount: number
}

const Equipment = (props: any) => {
  const [dataLoaded, setDataLoaded] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [items, setItems] = useState<any[]>([])
  const [depts, setDepts] = useState<Dept[]>([])
  const [teams, setTeams] = useState<Teams[]>([])
  const [crews, setCrews] = useState<Crews[]>([])
  const [prestartForms, setPrestartForms] = useState<any[]>([])

  const getFilteredTeams = (options: { data?: any }) => ({
    store: teams,
    filter: options.data ? ['department_id', '=', options.data.department_id] : null
  })

  const getFilteredCrews = (options: { data?: any }) => ({
    store: crews,
    filter: options.data ? ['team_id', '=', options.data.team_id] : null
  })

  const setTeamValue = (rowData: any, value: number) => {
    rowData.team_id = value || null
    rowData.crew_id = null // Reset crew_id to null
    const team = teams.find(t => t.team_id === value)
    if (team) {
      rowData.team_name = team.team_name
      const filteredCrews = crews.filter(c => c.team_id === value)
      if (filteredCrews.length === 1) {
        rowData.crew_id = filteredCrews[0].crew_id
        rowData.crew_name = filteredCrews[0].crew_name
      }
    }
  }

  const setCrewValue = (rowData: any, value: number) => {
    rowData.crew_id = value || null
    const crew = crews.find(c => c.crew_id === value)
    if (crew) {
      rowData.crew_name = crew.crew_name
    }
  }

  const setDepartmentValue = (rowData: any, value: number) => {
    rowData.department_id = value || null
    rowData.team_id = null // Reset team_id to null
    rowData.crew_id = null // Reset crew_id to null
    const dept = depts.find(d => d.department_id === value)
    if (dept) {
      rowData.department_name = dept.department_name
      const filteredTeams = teams.filter(t => t.department_id === value)
      if (filteredTeams.length === 1) {
        rowData.team_id = filteredTeams[0].team_id
        rowData.team_name = filteredTeams[0].team_name
        const filteredCrews = crews.filter(c => c.team_id === filteredTeams[0].team_id)
        if (filteredCrews.length === 1) {
          rowData.crew_id = filteredCrews[0].crew_id
          rowData.crew_name = filteredCrews[0].crew_name
        }
      }
    }
  }

  const setPrestartValue = (rowData: any, value: number) => {
    rowData.prestart_form_id = value || null
    const prestart = prestartForms.find(p => p.prestart_form_id === value)
    if (prestart) {
      rowData.prestart_form = prestart.prestart_form
    }
  }

  const columns = [
    {
      field: 'id',
      title: 'ID',
      width: 80,
      type: 'string',
      allowHiding: true,
      visibleColumn: false,
      formItem: {
        visible: false
      }
    },
    {
      field: 'resource_code',
      title: 'Resource Code',
      // width: 280,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    {
      field: 'title',
      title: 'Title',
      // width: 160,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    {
      field: 'category',
      title: 'Category',
      // width: 160,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'rego_number',
      title: 'Rego Number',
      // width: 160,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'department_id',
      title: 'Department',
      // width: 280,
      type: 'string',
      allowHiding: true,
      setCellValue: setDepartmentValue,
      Lookup: {
        dataSource: depts,
        valueExpr: 'department_id',
        displayExpr: 'department_name'
      }
    },
    {
      field: 'team_id',
      title: 'Team',
      // width: 280,
      type: 'string',
      allowHiding: true,
      setCellValue: setTeamValue,
      Lookup: {
        dataSource: getFilteredTeams,
        valueExpr: 'team_id',
        displayExpr: 'team_name'
      }
    },
    {
      field: 'crew_id',
      title: 'Crew',
      // width: 280,
      type: 'string',
      allowHiding: true,
      setCellValue: setCrewValue,
      Lookup: {
        dataSource: getFilteredCrews,
        valueExpr: 'crew_id',
        displayExpr: 'crew_name'
      }
    },
    {
      field: 'prestart_form_id',
      title: 'Prestart Form',
      // width: 160,
      type: 'string',
      allowHiding: true,
      setCellValue: setPrestartValue,
      Lookup: {
        dataSource: prestartForms,
        valueExpr: 'prestart_form_id',
        displayExpr: 'prestart_form'
      }
    },
    {
      field: 'is_active',
      title: 'Is Active',
      width: 80,
      type: 'boolean',
      allowHiding: true,
      calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
    },
    {
      field: 'inspection_failed',
      title: 'Inspection failed',
      width: 110,
      type: 'boolean',
      allowHiding: true,
      calculateCellValue: (data: any) => data.inspection_failed ?? false, // Default to false
      setCellValue: (newData: any, value: any) => {
        newData.inspection_failed = value
      }
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    },
    {
      field: 'createdBy',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const deptResponse = await utility.genericGetAPICall(`${Config.DepartmentList}`)
        //const deptData = await deptResponse.load();
        const deptData = deptResponse
          .filter((r: any) => r.is_active === true)
          .map((r: any) => ({
            department_id: r.id,
            department_code: r.code,
            department_name: r.title
          }))
        setDepts(deptData)

        const teamResponse = await utility.genericGetAPICall(`${Config.TeamList}`)
        const teamData = teamResponse
          .filter((r: any) => r.is_active === true)
          .map((r: any) => ({
            team_id: r.id,
            team_code: r.code,
            team_name: r.title,
            department_id: r.department.id
          }))
        setTeams(teamData)

        const crewResponse = await utility.genericGetAPICall(`${Config.crewlistname}`)
        const crewData = crewResponse
          .filter((r: any) => r.is_active === true)
          .map((r: any) => ({
            crew_id: r.id,
            crew_code: r.code,
            crew_name: r.title,
            team_id: r.team.id,
            department_id: r.team.department.id
          }))
        setCrews(crewData)

        const prestartFormResponse: any = await utility.genericGetAPICall(Config.PrestartFormsListName)
        const prestartFormData = prestartFormResponse
          .filter((r: any) => r.is_active === true)
          .map((r: any) => ({
            prestart_form_id: r.id,
            prestart_form: r.title
          }))
        const sortedItems: any = utility._sortItems(prestartFormData, 'prestart_form', false)
        setPrestartForms(sortedItems)

        await fetchItemsData()
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const fetchItemsData = async () => {
    setLoading(true)
    try {
      const itemsResponse = await utility.genericPostAPICall(Config.equipmentwithprestartForms, {})
      let items: any[] = []

      for (let r of itemsResponse) {
        items.push({
          ...r,
          id: r.id,
          active: r.is_active ? true : false,
          default: r.is_default ? true : false
        })
      }

      items = utility._sortItems(items, 'id', true)
      setItems(items)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      return {
        data: items,
        totalCount: items.length
      }
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded')
      setLoading(false)
      setDataLoaded(true)
    },
    insert: async (values: any) => {
      try {
        await utility.genericPostAPICall(`${Config.EquipmentsListName}/`, values)
        await fetchItemsData()
      } catch (error: any) {
        if (error instanceof Error && error.message.startsWith('Network response was not ok')) {
          try {
            const errorData = JSON.parse(error.message.replace('Network response was not ok: ', ''))
            const errorMessage = utility.extractErrorMessage(errorData)
            throw new Error(errorMessage)
          } catch (parseError) {
            throw new Error('Failed to process error response.')
          }
        } else {
          throw new Error('Failed to post data.')
        }
      }
    },
    update: async (key: any, values: any) => {
      try {
        await utility.genericUpdatePatchAPICall(`${Config.EquipmentsListName}/`, key, values)
        await fetchItemsData()
      } catch (error: any) {
        throw error
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.EquipmentAPi, key)
        await fetchItemsData()
      } catch (error: any) {
        throw error
      }
    }
  })

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'Equipments'}
              columnChooser={'EquipmentColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              //defaultfiltervalue={filterValue}
              showMoreBtn={true}
              visible={dataLoaded}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  )
}

export default Equipment
