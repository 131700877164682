import React, { useState, useEffect, useRef, useCallback } from 'react';
import TreeView, { TreeView as TreeViewInstance, TreeViewTypes} from 'devextreme-react/tree-view';

interface TreeViewComponentProps {
    cellData: any;
    treeViewItems: any[];
    selectedKeys: string[];
    onUpdateSelectedKeys: (selectedKeys: any[]) => void;
}

const TreeViewComponent: React.FC<TreeViewComponentProps> = ({ cellData, treeViewItems, selectedKeys, onUpdateSelectedKeys }) => {
    const treeViewRef = useRef<TreeViewInstance | null>(null);
    const [selectedItems, setSelectedItems] = useState(selectedKeys);
   
    useEffect(() => {
        if (treeViewRef.current) {
            selectedKeys.forEach((key) => {
                treeViewRef.current?.instance?.selectItem(key);
            });
        }
    }, [selectedKeys]);

    const renderTreeViewItem = (item: { title: any;}) => `${item.title}`;

    const syncSelection = useCallback((treeView:any) => {
        const syncSelectedRiskGroups = treeView.getSelectedNodes()
        .map((node: any) => {
            if (typeof node.itemData.id === 'string' && node.itemData.id.includes('-')) {
                return parseInt(node.itemData.id.split('-')[1]); // Extract numeric ID part if '-' exists in string form
            } else if (typeof node.itemData.id === 'string' && !isNaN(parseInt(node.itemData.id))) {
                return parseInt(node.itemData.id); // Parse as integer if it's a string representation of a number
            } else if (typeof node.itemData.id === 'number') {
                return node.itemData.id; // Use as is if it's already a number
            }
            return null; // Handle unrecognized format
        })
        .filter((id: any) => id !== null);
        setSelectedItems(syncSelectedRiskGroups);
      }, [setSelectedItems]);

      useEffect(() => {
        onUpdateSelectedKeys(selectedItems); // Trigger callback when selectedItems change
    }, [selectedItems, onUpdateSelectedKeys]);
    
      const treeViewSelectionChanged = useCallback((e: TreeViewTypes.SelectionChangedEvent) => {
        cellData.data.setValue(e.component.getSelectedNodeKeys());
        syncSelection(e.component);
      }, [syncSelection]);
    
      const treeViewContentReady = useCallback((e: TreeViewTypes.ContentReadyEvent) => {
        syncSelection(e.component);
      }, [syncSelection]);
   

    return (
        <TreeView
            id="treeview"
            ref={treeViewRef}
            width={'auto'}
            height={300}
            items={treeViewItems}
            //dataStructure="plain"
            // keyExpr="id"
            // parentIdExpr="parentId"
            // displayExpr="title"
            selectNodesRecursive={true}
            selectByClick={false}
            showCheckBoxesMode="normal"
            selectionMode="multiple"
            itemRender={renderTreeViewItem}
            searchMode="contains"
            searchEnabled={true}
            searchExpr="title"
            onContentReady={treeViewContentReady}
            onSelectionChanged={treeViewSelectionChanged}
        />
    );
};

export default TreeViewComponent;
