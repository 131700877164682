import React from 'react'
import TextArea from 'devextreme-react/text-area'

interface Props {
  label?: string
  stylingMode?: string | any
  placeholder?: string | any
  value: string
  showClearButton?: boolean | any
  readOnly?: boolean | any
  disabled?: boolean | any
  onValueChanged: (e: any) => void
  spellcheck?: boolean
}

const TextAreaInput: React.FC<Props> = ({
  label,
  stylingMode,
  placeholder,
  value,
  showClearButton,
  readOnly,
  disabled,
  onValueChanged,
  spellcheck
}) => {
  return (
    <TextArea
      placeholder={placeholder}
      value={value}

      // stylingMode={stylingMode}
      // height={90}
      minHeight={90}
      maxHeight={120}
      spellcheck={spellcheck}
      onValueChanged={onValueChanged}
      showClearButton={showClearButton}
      labelMode={'floating'}
      label={label}
      readOnly={readOnly}
      disabled={disabled}
      autoResizeEnabled={true}
    />
  )
}

export default TextAreaInput
