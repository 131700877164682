import React, { useState, useEffect } from 'react'
import SchedulerCard from './SchedulerCard'
import global from '../../../classes/global'

interface MapSchedulerListProps {
  items?: any[]
  currentDate?: string
  currentView?: string
  stopOnCrewSelected?: () => void
  viewItem?: (e: any) => void
  viewWO?: (e: any) => void
  zoomToSelectWO?: (e: any) => void
  mapView?: any
  dashBoardCard?: any
  _onShowNotesPanel?: (woData: any) => void
  onUnassignedSelection?: (e: any) => void
  onUnassignedSelectionOption?: boolean
  unassignWO?: (woData: any) => void
  view?: any
  callAfterInit?: any
  stopCommsAfterInit?: any
  schedulerlistDraggingAllowed?: any
}

const MapSchedulerList: React.FC<MapSchedulerListProps> = props => {
  const [isNarrow, setIsNarrow] = useState(false)

  useEffect(() => {
    if (window.innerWidth < 984) {
      setIsNarrow(true)
    }
  }, [])

  const renderListItem = (woData: any, i: number) => {
    const onUnassignedSelectionOption = woData.fieldstatus === global.Unassigned
    const showCrew = woData.crew && woData.crew > 0
    const showUnassignWO = !onUnassignedSelectionOption

    return (
      <div key={i} className={isNarrow ? 'workorderOnMapListCardNarrow' : 'workorderOnMapListCard'}>
        <SchedulerCard
          woData={woData}
          view={props.view}
          viewItem={props.viewItem}
          viewWO={props.viewWO}
          zoomToSelectWO={props.zoomToSelectWO}
          mapView={props.mapView}
          schedulerlistDraggingAllowed={true}
          dashBoardCard={props.dashBoardCard}
          _onShowNotesPanel={props._onShowNotesPanel}
          showUnassignWO={showUnassignWO}
          onUnassignedSelection={props.onUnassignedSelection}
          onUnassignedSelectionOption={onUnassignedSelectionOption}
          showCrew={showCrew}
          unassignWO={props.unassignWO}
          isMapListItem={true}
        />
      </div>
    )
  }

  return (
    <div className={isNarrow ? 'workorderOnMapListNarrow' : 'workorderOnMapList'}>
      {props.items?.map((element, i) => (element.text !== 'No Work Order Found' ? renderListItem(element, i) : null))}
    </div>
  )
}

export default MapSchedulerList
