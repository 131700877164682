import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { createBrowserHistory } from 'history';

import global from './classes/global'
import utility from './classes/utilityOld'
import { Config } from './classes/config'

// ** Types
import { AuthValuesType, RegisterParams, LoginParams, ErrCallbackType, UserDataType } from './types'

type Props = {
  children: ReactNode
}

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  isErrorAlert: false,
  setUser: () => null,
  role: '',
  isAuthenticated: false,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
}

const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ children }:any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(defaultProvider.isAuthenticated);
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user)
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading)
  const [role, setRole] = useState<string>(defaultProvider.role)
  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(defaultProvider.isErrorAlert)

  const history = createBrowserHistory();

  useEffect(() => {
    const initAuth = async () => {
      const storagedata = localStorage.getItem(global.accessToken);
      const currentUserEmail = localStorage.getItem('currentDetail');
  
      if (storagedata !== null && currentUserEmail !== null) {
        const data = JSON.parse(storagedata);
        const currentDateTime = new Date();
        const endDate = data.expires_at;
  
        let expiredate = new Date(0);
        expiredate.setUTCSeconds(endDate);
        expiredate = new Date(expiredate.getTime() - 30 * 60000);
  
        if (currentDateTime.getTime() >= expiredate.getTime()) {
          try {
            const res = await utility.tokenRefreshAPI(storagedata);
            if (res) {
              const apiName = `${Config.UserDetails}`;
              const userData = await utility.genericGetAPICallResponse(apiName);
              setUser({ ...userData });
              setRole('admin');
              setIsAuthenticated(true)
            }
          } catch (error) {
            console.error(error);
              localStorage.removeItem('currentDetail')
              localStorage.removeItem(global.accessToken)
              setUser(null)
              setLoading(false)
              setIsAuthenticated(false)
          }
        } else {
          if(data && data.access_token){
            const apiName = `${Config.UserDetails}`;
            const userData = await utility.genericGetAPICallResponse(apiName);
            setUser({ ...userData });
            setRole('admin');
            setIsAuthenticated(true);
           
          }
        }
      } else {
        localStorage.removeItem('currentDetail');
        localStorage.removeItem(global.accessToken);
        setUser(null);
        setLoading(false);
        setIsAuthenticated(false)
      }
    }
  initAuth();
}, []);

  const handleLogin = (params: LoginParams, errorCallback?: ErrCallbackType) => {
    utility
      .tokenAPIWithUserDetails(params.email, params.password)
      .then((data: any) => {
        localStorage.setItem(global.accessToken, JSON.stringify(data))
        const apiName = `${Config.UserDetails}`
        utility.genericGetAPICallResponse(apiName).then(
          (data: any) => {
            //console.log(data);
            setUser({ ...data })
            localStorage.setItem('currentDetail', data.email)
            setIsAuthenticated(true)
            // if (router.pathname.includes('login')) {
            //   router.replace('/home')
            // }
            const queryParams = new URLSearchParams(history.location.search);
            const returnUrl = queryParams.get('returnUrl');
            const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'
            history.push(redirectURL as string)
          },
          err => {
            console.log(err)
          }
        )
        //const returnUrl = router.query.returnUrl

        //setUser({ ...response.data.userData })
        //params.rememberMe ? window.localStorage.setItem('userData', JSON.stringify(response.data.userData)) : null

        //const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'

        // const location = window.location.origin
        // const redirectToHome = `${location}/home`
        // window.open(redirectToHome, '_self')
      })
      .catch(err => {
        if (errorCallback) {
          errorCallback(err)
        }
      })
  }

  const handleLogout = () => {
    setUser(null)
    window.localStorage.removeItem('currentDetail')
    window.localStorage.removeItem(global.accessToken)
    window.location.href = '/login'
    //history.push('/login')
  }

  const handleRegister = () => {}

  const values = {
    user,
    loading,
    isErrorAlert,
    setUser,
    role,
    isAuthenticated,
    setLoading,
    login: handleLogin,
    logout: handleLogout,
    register: handleRegister
  }

  return (
    <AuthContext.Provider value={ values }>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);


 export { AuthProvider, useAuth };