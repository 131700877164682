import global from './global'

export const Config: any = {
  productTitle: 'WorxOnline',
  tenantId: 1002,
  ListConfiguration: 'config_list/',
  WOHistorylistname: 'Work Order History',
  WODocumentslistname: 'Work Order Documents',

  //main endpoit must end wtih / e.g. workorders/ but the sub endpoint must not end with / e.g. workorder/get_related_workorders_by_workorderid'
  // for get api
  workorderlistname: 'workorder/',
  workorderMateriallistname: 'workorder_material/get_workorder_material_by_workorderid', //CL change to lower case
  scheduledworklistname: 'scheduled_works',
  suburblistname: 'suburb/',
  townlistname: 'town/',
  crewlistname: 'crew/',
  SearchWorkordersForDashboard: '/workorder/Search_Workorder_for_dashboard',
  crewswithdepartmentList: 'users_crews_teams_departments_view/',
  crewswithdepartment: 'users_crews_teams_departments_view/get_crew_with_department_and_team',
  searchCrewDeptTeam: 'users_crews_teams_departments_view/search_user_by_crew_dept_team',
  employeelistname: 'user/',
  WTlistname: 'workorder_type/', //CL chaange to lowercase
  SearchWTListname: 'workorder_types/search_workorder_types',
  //WRTlistname: 'workRequestTypes',
  SeachWorkrequesttypes: 'work_request_type/search_workrequest_types',
  SearchTimesheetApi:'timesheet/search_timesheet',
  workGroup: 'workgroup/',
  WRTlistname: 'work_request_type/',
  WRlistname: 'work_requests/search_work_requests',
  WRbyWRNo: 'work_requests/get_work_request_by_work_request_number',
  WorkRequestHistories: 'work_request_histories/',
  WRDocuments: 'work_request_documents/get_documents_by_workorder_Id',
  WRList: 'work_requests/',
  RejectWR: 'work_requests/reject_workrequest',
  statuslist: 'workorder_status/',
  WRstatuslist: 'work_request_status/',
  WOMaterialListName: 'materials/',
  SearchFormList:'form/',
  //EquipmentsListName: 'workorder_Equipment/GetWorkorder_Equipments_by_workorderId',
  EquipmentsListName: 'equipment/',
  SearchEquipmentUsage: 'search_equipments_usage/',
  equipmentwithprestartForms: 'search_equipments_with_prestart/',
  assetType: 'asset_type/',
  assetClass: 'asset_class/',
  assetStatus: 'asset_status/',
  unitType: 'unit_type/',
  DepartmentList: 'department/',
  WorkorderEquipments: 'workorder_equipment',
  CounterList: 'counter/',
  TeamList: 'team/',
  SearchTeamList: 'team/search_teams',
  DefectCodeList: 'defect_types/',
  AssetQuestionList: 'asset_fields/',
  workorderHistoryList: 'workorder_history/',
  workorderDocumentList: 'workorder_documents/',
  workorderDocumentListGet: 'workorder_documents/', //CL chaneg this to workorder_documents
  assetDocumentList: 'asset_documents/',
  swmsDocumentList: 'swms_document/',
  searchswmsDocumentList: 'swms_document/',
  tcpDocumentList: 'traffic_control_plans/',
  dowloadDocumentList: 'documents/download_document_from_azure_as_adhocurl',
  FormsListName: 'form/',
  EventsListName: 'events/',
  PrestartFormsListName: 'prestart_forms/',
  PriorityListName: 'priority/',
  workTypeFieldList: 'worktype_field/',
  worktypeSubcategories: 'work_order_types/get_woWorkorder_types_subcategories',
  defectQuestionList: 'defect_fields/',
  roleList: 'role/',
  userRoleList: 'user_role/',
  inventoryLocationList: 'inventory_locations/',
  InspectionFormList: 'common_form/',
  ServiceRequestDetail: 'service_request_detail/',
  MaintenanceSchedule: 'maintenance_schedule/',
  OnHoldReasonDetailList: 'onhold_reasons/details',
  OnHoldReasonList: 'on_hold_reasons/',
  GISLayer: 'gisicons/layer-datasource.json',
  AreaList: 'area',
  ReasoneToAttendList: 'reason_to_attend',
  ScopeList: 'scope',
  FormattedAddressList: 'formatted_address',
  AddressList: 'address',
  UserDetails: 'user/me',
  UserById: 'user/',

  //update api for Schedule
  SearchWRHistory: '/work_request_histories/search_workrequest_history',
  SchedulerWorkUpdateList: 'update_workorder_schedule',
  WorkOrderUpdateList: 'workorders',

  // for searc api
  WOSearchAPI: 'workorder/wo_search', //CLchagne this to lowercase workorder
  WOdetailsSearchAPI: 'workorder/',
  SearchRelatedWOAPI: 'workorder/get_related_workorders_by_workorderid',
  UpdateWO: 'workorder/',
  FcrCategorisation: 'fcr_categorisation',

  //material
  WOMaterialSearchAPI: 'search_material_usage',
  SearchMaterialApi: 'materials/search_materials',
  MaterialCategoryApi: 'material_categories',
  materialsListName: 'materials/',

  //Equipments
  WOequipmentSearchAPI: 'workorder_equipment/get_workorder_equipments_by_workorderid', //CL change this to lower case
  EqipmentSearchAPI: 'search_equipments_with_prestart/',
  EquipmentAPi: 'equipment',
  SearchPrestartChecklist: 'prestart_checklist/prestart_search',
  SearchInspectionForm: 'common_form/search_common_forms_documents',
  SearchEmployeeApi: 'users_crews_teams_departments_view/',
  UpdateEmployeeAPI: 'user_mgmt/update_user_with_role/',
  // CreateEmployeeAPI: 'users/create_user_with_role',
  CreateEmployeeAPI: 'user_mgmt/',
  DeleteEmployeeAPI: 'user_mgmt/',
  //SearchActivitiesApi: 'workorderTypes/SearchWorkorderTypes',
  SearchActivitiesApi: 'search_workorder_types/search_workorder_types/',
  //SearchWorkTypeField: 'worktypefields/SearchWorktypeFields',
  WorkTypeField: 'worktype_field',

  SearchCrewApi: 'crew/search_crew',
  SearchTeamApi: 'search_teams/',
  SearchAssetFields: 'search_asset_fields',
  AssetSearchAPI: 'asset/asset_search',
  GetAssetDetails: 'asset',
  AssetSearchAPIForDetail: '/search_assets',
  AddEditAset: 'assets/add_asset_from_app',
  WOTimesheetSearchAPI: 'timesheets',
  WOTimeSheetAPI: 'timesheet/Get_timesheet_by_workorderId', //CL changet to timesheet
  WOHistorySearchAPI: 'workorder_history/get_workorder_history_by_workorderid', //Cl change to lowercase

  WOSearchScheduleAPI: 'search_scheduled_works',
  SearchControlMeasure: 'search_control_ceasures',
  SearchRiskControlMeasure: 'search_control_measures',
  SearchPurchaseRequisition: 'purchase_requisitions/search_purchase_requisitions',
  searchSuppliersList: 'supplier/search_suppliers',
  Suppliers: 'supplier/',

  //Risk
  RiskGroup: 'risk_groups/',
  RiskGroupWithRisks: 'risk_groups/get_all_risk_groups_with_risks',
  RiskGroupCM: 'risk_groups/get_all_riskgroups_with_risks_and_control_measures',
  RiskCategory: 'risk_categories/',
  //RiskForms: 'RiskForms',
  RiskForms: 'risk_forms/',
  Risks: 'risks',
  AssetAttributes: 'asset_attribute_value/get_asset_attributies_value_by_assetid',
  RiskControlmeasures: 'risk_control_measures/',
  //RiskCategorieswithGroups: 'RiskCategories/GetAllRiskCategoriesWithRiskGroups',
  RiskCategorieswithGroups: 'risk_categories/',
  RiskGroupsWithRiskCategories: 'risk_groups/',
  RiskFormsWithDepartments: 'risk_forms/get_all_risk_forms_with_departments',
  GetAllRisksWithRiskGroups: 'risks/',
  RiskHierarchy: 'risk_hierarchy/',

  //extra
  WTQuestionslistname: 'Activity Questions',
  timesheetListName: 'New Employee Timesheet',
  gisAssetCoordinates: 'GisAsset/getAssetCoordinates',

  //Defects
  searchDefects: 'defects/search_defects',

  TenantApi: 'tenants',
  GloabSearchAPI: 'globalsearch?searchterm=',
  AssetdetailURL: global.homeURL + '/asset_detail?ASSETNO=',
  seacrhWorkOrderURL: global.homeURL + '/search_workorder',

  IntegrationStatusapi: 'integration_status/',
  Notificationapi: 'notifications/search_by_date',

  WeeklyTimesheet:'timesheet/search_timesheet',

  statusColors: {
    Open: '#90C3D4',
    Dispatched: '#90C3D4',
    OffsiteHold: '#FB8E54',
    JobComplete: '#A1D490',
    OffsiteComplete: '#A1D490',
    WorkComplete: '#A1D490',
    Complete: '#A1D490',
    Accepted: '#F4A498',
    Disapatched: '#C9C65F',
    Travelling: '#6666FF',
    WaterOff: '#C733FF',
    WaterOn: '#C733FF',
    WorkPaid: '#C733FF',
    Cancelled: '#C733FF',
    CID: '#C733FF',
    CIP: '#C733FF',
    Closed: '#C733FF',
    Onsite: '#C733FF',
    Queried: '#C733FF',
    Rejected: '#C733FF',
    Restored: '#C733FF',
    ServiceRestored: '#C733FF',
    SewerContained: '#C733FF'
  },
  priorityColors: {
    'Priority 1': '#ff0000',
    'Priority 2': '#e6b800',
    'Priority 3': '#00cc00',
    'Planned within 14 Days': '#0066ff',
    'Planned within 30 Days': '#cc99ff',
    'Planned within 60 Days': '#ff4d88',
    'Proposed Work': '#e60099',
    'Planned Shutdown': '#00b3b3',
    'PWeekly within 7 Days': '#9494b8',
    'Yearly (Due 30 Jun of FY)': '#999999'
  }
}
export default Config
