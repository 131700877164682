import React, { useState, useEffect } from 'react';
import utility from '../../../classes/utilityOld';
import { Config } from '../../../classes/config';
import Grid from '@mui/material/Grid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../Common/AlertDialog';
import Spinner from '../../Common/ReusableComponents/Spinner';
import CheckBoxInput from '../../Common/ReusableComponents/CheckBox';
import TextBoxInput from '../../Common/ReusableComponents/TextBox';
import SelectBoxInput from '../../Common/ReusableComponents/SelectInputBox';
import capitalize from '@mui/material/utils/capitalize';

const Type: any = [
    { id: 'Date', text: 'Date' },
    { id: 'Text', text: 'Text' },
    { id: 'ChoiceBox', text: 'ChoiceBox' },
    { id: 'Choice', text: 'Choice' }
];

const AddEditAssetQuestion: React.FC<any> = (props) => {
    const [state, setState] = useState<any>({
        id: '',
        title: '',
        type: '',
        sequenceno: '',
        collectionTypeCode: '',
        maintenanceScheduleCode: '',
        isActive: true,
        mode: props.mode,
        showAlert: false,
        hideAlertButton: false,
        alertMessage: '',
    });

    useEffect(() => {
     didMount()
    }, [props.details, props.mode]);

    const didMount = () => {
        if (props.details && props.mode === 'edit') {
            populateValue(props.details, props.mode);
        } else {
            setState({
                ...state,
                isActive: true,
                hideAlertButton: false,
                mode: props.mode,
            });
        }
    }

    const populateValue = (details: any, mode?: any) => {
        setState({
            ...state,
            id: props.details.id,
            title: props.details.title,
            type: props.details.type ? { id: props.details.type, text: props.details.type } : '',
            sequenceno: props.details.sequenceno,
            collectionTypeCode: props.details.collectionTypeCode,
            maintenanceScheduleCode: props.details.maintenanceScheduleCode,
            isActive: props.details.active ? true : false,
            mode: props.mode,
        });
    };

    const handleValueChange = (event: any, fieldName: string) => {
        setState({ ...state, [fieldName]: event.value });
    };

    const handleDropdownChange = (e: any, fieldName: string) => {
        setState({ ...state, [fieldName]: e.selectedItem });
    };

    const updateAssetQuestion = () => {
        const dataToPassToService: any = {};

        if (!state.title) {
            setState({
                ...state,
                alertMessage: 'Please enter title',
                showAlert: true,
            });
            return;
        }
        if (!state.type) {
            setState({
                ...state,
                alertMessage: 'Please select type',
                showAlert: true,
            });
            return;
        }
        if (!state.sequenceno) {
            setState({
                ...state,
                alertMessage: 'Please enter sequence #',
                showAlert: true,
            });
            return;
        }
        if (!state.collectionTypeCode) {
            setState({
                ...state,
                alertMessage: 'Please enter collection type code',
                showAlert: true,
            });
            return;
        }
        if (!state.maintenanceScheduleCode) {
            setState({
                ...state,
                alertMessage: 'Please enter maintenance schedule code',
                showAlert: true,
            });
            return;
        }
        setState({
            ...state,
            alertMessage: (
                <div className={'alertLoader'}>
                    <Spinner size='large' label="Loading..." />
                </div>
            ),
            hideAlertButton: true,
            showAlert: true,
        });
        dataToPassToService.Title = state.title;
        dataToPassToService.Type = state.type.id;
        dataToPassToService.CollectionTypeCode = state.collectionTypeCode;
        dataToPassToService.Sequenceno = +state.sequenceno;
        dataToPassToService.MaintenanceScheduleCode = state.maintenanceScheduleCode;
        dataToPassToService.Isactive = state.isActive;
        dataToPassToService.tenantId = Config.tenantId;

        if (state.mode === 'edit') {
            dataToPassToService.id = state.id;
            dataToPassToService.updatedBy = props.currentUser.Email;
            dataToPassToService.updated = new Date();
            utility.genericUpdateAPICall(Config.AssetQuestionList, state.id, dataToPassToService).then(
                (data: any) => {
                    setState({ ...state, showAlert: false });
                    clearFilter();
                    props.onClick();
                    props.message('Asset question has been updated');
                },
                (err: any) => {
                    console.log(err);
                    setState({
                        ...state,
                        alertMessage: 'Something went wrong!',
                        hideAlertButton: false,
                        showAlert: true,
                    });
                }
            );
        } else {
            dataToPassToService.createdBy = props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.AssetQuestionList, dataToPassToService).then(
                (data: any) => {
                    setState({ ...state, showAlert: false });
                    clearFilter();
                    props.onClick();
                    props.message('Asset question has been created');
                },
                (err: any) => {
                    console.log(err);
                    setState({
                        ...state,
                        alertMessage: 'Something went wrong!',
                        hideAlertButton: false,
                        showAlert: true,
                    });
                }
            );
        }
    };

    const clearFilter = () => {
        setState({
            ...state,
            title: '',
            type: '',
            collectionTypeCode: '',
            sequenceno: '',
            maintenanceScheduleCode: '',
        });
        props.onClose();
    };

    const alert = () => {
        return (
            <AlertDialog
                showDialog={state.showAlert}
                message={state.alertMessage}
                title={Config.productTitle}
                onClose={() => setState({ ...state, alertMessage: '', showAlert: false })}
                hideAlertButton={state.hideAlertButton}
            />
        );
    };

    return (
        <div>
            {state.showAlert && alert()}
            <Popup
                width={'80vw'}
                maxHeight={'85vh'}
                minHeight={200}
                height={'auto'}
                showTitle={true}
                showCloseButton={true}
                title={`${state.mode && capitalize(state.mode)} "Asset Question - " ${state.Title}`}
                dragEnabled={true}
                hideOnOutsideClick={false}
                visible={props.showModal}
                onVisibleChange={clearFilter}
                onShowing={() => {
                    if (props.details && props.mode === 'edit') {
                        populateValue(props.details, props.mode);
                    } else {
                        setState({
                            ...state,
                            isActive: true,
                            hideAlertButton: false,
                            mode: props.mode,
                        });
                    }
                }}
            >
                <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                    <div className='popContentDiv'>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextBoxInput
                                    stylingMode='outlined'
                                    label="Title *"
                                    value={state.title}
                                    onValueChanged={(e: any) => handleValueChange(e, 'title')}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextBoxInput
                                    stylingMode='outlined'
                                    label="Collection Type Code *"
                                    value={state.collectionTypeCode}
                                    onValueChanged={(e: any) => handleValueChange(e, 'collectionTypeCode')}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <SelectBoxInput
                                    stylingMode='outlined'
                                    displayExpr="text"
                                    valueExpr="id"
                                    showClearButton={true}
                                    items={Type}
                                    selectedItem={state.type}
                                    label='Type'
                                    onSelectionChanged={(e: any) => handleDropdownChange(e, 'type')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextBoxInput
                                    stylingMode='outlined'
                                    label="Sequence # *"
                                    mode='number'
                                    value={state.sequenceno}
                                    onValueChanged={(e: any) => handleValueChange(e, 'sequenceno')}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextBoxInput
                                    stylingMode='outlined'
                                    label="Maintenance Schedule Code*"
                                    value={state.maintenanceScheduleCode}
                                    onValueChanged={(e: any) => handleValueChange(e, 'maintenanceScheduleCode')}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <br />
                                <CheckBoxInput
                                    value={state.isActive}
                                    onValueChanged={(e: any) => handleValueChange(e, 'isActive')}
                                    text={'Is Active'}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </ScrollView>
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        icon: 'close',
                        text: 'Cancel',
                        stylingMode: 'outlined',
                        onClick: clearFilter,
                    }}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        icon: 'save',
                        text: 'Save',
                        stylingMode: 'contained',
                        onClick: updateAssetQuestion,
                    }}
                />
            </Popup>
        </div>
    );
};

export default AddEditAssetQuestion;
