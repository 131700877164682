import React, { useState, useEffect } from 'react';
import utility from '../../../classes/utility';
import ClearStorageService from './../../../ClearStorageService';
import { Config } from '../../../classes/config';
import DataTable from '../../Common/CustomASDataGrid';
import Spinner from '../../Common/ReusableComponents/Spinner';
import CustomStore from "devextreme/data/custom_store";
import { LoadOptions } from "devextreme/data";

interface LoadResult {
  data: any[];
  totalCount: number;
}

const Departments = (props:any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [depts, setDepts] = useState<any[]>([]);
  const filterValue = [['is_active', '=', true]];

  const [columns] = useState([
    {
      field: 'id',
      title: 'ID',
      width: 80,
      type: 'string',
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'code',
      title: 'Code',
      type: 'string',
      allowHiding: false,
      isRequired: true,
    },
    {
      field: 'title',
      title: 'Title',
      type: 'string',
      isRequired: true,
    },
    {
      field: 'is_active',
      title: 'Active',
      type: 'boolean',
      calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
    },
    {
      field: 'show_on_scheduler',
      title: 'Show On Scheduler',
      type: 'boolean',
      calculateCellValue: (data: any) => data.show_on_scheduler ?? false, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.show_on_scheduler = value
      }
    },
    {
      field: 'updated',
      title: 'Updated',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      sortOrder: 'desc',
      formItem: {
        visible: false,
      }
    },
    {
      field: 'updated_by',
      title: 'Updated By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
    {
      field: 'created_by',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false,
      }
    },
  ]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const deptResponse = await utility.genericGetAPICallForList(Config.DepartmentList, 'GET');
      const deptData = deptResponse;
      // .map((r: any) => ({
      //   ...r,
      //   department_name: r.title,
      //   department_id: r.id,
      // }));
      setDepts(deptData);
      setLoading(false);
      setDataLoaded(true);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      return {
        data: depts,
        totalCount: depts.length
      };
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded');
      setLoading(false);
      setDataLoaded(true);
    },
    insert: async (values: any) => {
      try {
        await utility.genericPostAPICall(Config.DepartmentList, values);
        await fetchData();
      } catch (error:any) {
        if (error instanceof Error && error.message.startsWith('Network response was not ok')) {
          try {
            const errorData = JSON.parse(error.message.replace('Network response was not ok: ', ''));
            const errorMessage = utility.extractErrorMessage(errorData);
            throw new Error(errorMessage);
          } catch (parseError) {
            throw new Error('Failed to process error response.');
          }
        } else {
          throw new Error('Failed to post data.');
        }
    
      }
    },
    update: async (key: any, values: any) => {
      try {
        await utility.genericUpdatePatchAPICall(Config.DepartmentList, key, values);
        await fetchData();
      } catch (error:any) {
        throw error;
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.DepartmentList, key);
        await fetchData();
      } catch (error:any) {
        throw error;
      }
    }
  });

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'Departments'}
              columnChooser={'departmentColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              defaultfiltervalue={filterValue}
              showMoreBtn={true}
              visible={dataLoaded}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  );
};

export default Departments;
