import React, { useState, useEffect } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import global from '../../classes/global'
import GoogleMapComponent from '../Common/MapComponent/GoogleMapComponent'

interface MapModalProps {
  showModal: boolean
  polylines?: boolean
  department?: string
  address: string
  singlemarker?: any
  center?: {
    lat: any
    lng: any
  }
  zoom: number
  onClick: () => void
  googleMapURL?: any
  crewLocation?: any
}

const MapModal: React.FC<MapModalProps> = props => {
  const [useStyle, setUseStyle] = useState(localStorage.getItem('theme') === 'theme-dark')

  useEffect(() => {
    // componentDidMount equivalent
    // console.log('component load');
  }, [])

  return (
    <div>
      <Popup
        width={'60vw'}
        maxHeight={'85vh'}
        minHeight={200}
        height={'auto'}
        showTitle={true}
        showCloseButton={true}
        title={props.polylines ? props.department : props.address}
        dragEnabled={false}
        hideOnOutsideClick={false}
        visible={props.showModal}
        onVisibleChange={props.onClick}
      >
        <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
          {props.polylines || (props.center && props.center.lat !== '40.854885') ? (
            <div>
              <GoogleMapComponent
                singleMarkers={props.singlemarker}
                center={props.center}
                zoom={props.zoom ? props.zoom : 11}
                useStyle={useStyle}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${global.googleAPIKey}&v=3.exp&libraries=geometry,drawing,places`}
                showMarker={true}
                showMap={true}
              />
            </div>
          ) : null}
        </ScrollView>
        <ToolbarItem
          widget='dxButton'
          toolbar='bottom'
          location='after'
          options={{
            icon: 'close',
            text: 'Close',
            stylingMode: 'outlined',
            onClick: props.onClick
          }}
        />
      </Popup>
    </div>
  )
}

export default MapModal
