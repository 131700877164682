import React,{ useState } from 'react'

import AlertDialog from '../Common/AlertDialog'
import { useAuth } from "../../AuthContext";

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Box, { BoxProps } from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import useMediaQuery from '@mui/material/useMediaQuery'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled, useTheme } from '@mui/material/styles'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import Typography, { TypographyProps } from '@mui/material/Typography'
import MuiFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'


interface LoginForm {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid email format'),
  password: yup.string().required('Password is required').min(3, 'Password must be at least 3 characters'),
});

const LoginPage: React.FC = () => {

  const [rememberMe, setRememberMe] = useState<boolean>(true)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [hideAlertButton, setHideAlertButton] = useState<boolean>(false)
  const auth = useAuth()
  const defaultValues = {
    password: password,
    email: email
  }

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  // const { handleSubmit, control, formState: { errors } } = useForm<LoginForm>({
  //   resolver: yupResolver(schema),
  // });

  const onSubmit = (data: LoginForm) => {
    console.log(data); 
    const { email, password } = data
    auth.login({ email, password, rememberMe }, () => {
      setError('email', {
        type: 'manual',
        message: 'Email or Password is invalid'
      })
    })
  };

  const alert = (): JSX.Element => (
    <AlertDialog
      showDialog={showAlert}
      message={alertMessage}
      title='User Not found'
      onClose={() => {
        setAlertMessage('')
        setShowAlert(false)
      }}
      hideAlertButton={hideAlertButton}
    />
  )

  return (

   <Box className='content-right' sx={{ width: '100%', display: 'flex', backgroundColor: '#222a2a' }}>
  
    <Box
        sx={{
          p: 7,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'background.paper',
          margin: '20px auto',
          borderRadius: '34px'
        }}
      >
       
        <Box><h2>Login to WorxOnline</h2>
        <Box
              sx={{ mb: 4, display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
            >
             
            </Box>
      {/* <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
        <Typography
          variant='h2'
          sx={{ ml: 2, md: 6, lineHeight: 1, fontWeight: 700, fontSize: '1.5rem !important', margin: '20px 0' }}
        >
          WorxOnline
        </Typography>
      </Box>
      <Box sx={{ mb: 12 }}>
        <Typography variant='body2'>Please sign-in to your account and start the adventure</Typography>
      </Box> */}
          
      <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <div>
          <FormControl fullWidth sx={{ mb: 4 }}>
          <Controller
            name="email"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <TextField
              autoFocus
              label='Email'
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={Boolean(errors.email)}
              placeholder='abc@test.com'
            />
            )}
            />
          <p>{errors.email?.message}</p>
          </FormControl>
        </div>
        <div>
        <FormControl fullWidth>
              <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.password)}>
                Password
              </InputLabel>
              <Controller
                name='password'
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <OutlinedInput
                    value={value}
                    onBlur={onBlur}
                    label='Password'
                    onChange={onChange}
                    id='auth-login-v2-password'
                    error={Boolean(errors.password)}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          onMouseDown={e => e.preventDefault()}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              {errors.password && (
                <FormHelperText sx={{ color: 'error.main' }} id=''>
                  {errors.password.message}
                </FormHelperText>
              )}
            </FormControl>
        </div>
        <Box
              sx={{ mb: 4, display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between' }}
            >
             
        </Box>
        <Button
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              color='primary'
              className='primary-button'
              sx={{ mb: 7 }}
            >
              Login
            </Button>
      </form>
    </Box>
    </Box>
  </Box>
  );
};

export default LoginPage;
