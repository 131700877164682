import React, { useEffect, useState } from 'react'
import { DataGrid, Column } from 'devextreme-react/data-grid'

interface DetailTemplateProps {
  dataSource: any[]
  data: any
  columns: any[]
}

const DetailTemplate: React.FC<DetailTemplateProps> = props => {
  const [detailItems, setDetailItems] = useState<any[]>([])

  useEffect(() => {
    // Ensure props.data.data is treated as an array
    const dataArray = Array.isArray(props.data.data) ? props.data.data : [props.data.data];

    // Find the item that matches the key
    const detailItem = dataArray.find((item: any) => item.id === props.data.key);

    // Set the detailItems state to the wotype_worktype_field array of the matched item
    setDetailItems(detailItem ? [...detailItem.wotype_worktype_field] : []);
  }, [props.data]);

  const renderColumns = () => {
    const columnsArray = Array.isArray(props.columns) ? props.columns : [props.columns];
    return (
      columnsArray &&
      columnsArray.map(item => {
        return (
          <Column
            key={item.field}
            dataField={item.field}
            width={item.width}
            caption={item.title}
            dataType={item.type}
            visible={item.visibleColumn}
            visibleIndex={item.visibleIndex}
            allowHiding={item.allowHiding}
            format={item.type === 'date' ? 'dd/MM/yyyy' : item.type === 'datetime' ? 'dd/MM/yyyy h:mm a' : ''}

            // cellRender={WOLinkCell}
            // cellRender={(e) => Cell(e, item.link)}
            // editorOptions={{ showClearButton: true }}
          ></Column>
        )
      })
    )
  }

  return (
    <>
      <DataGrid dataSource={detailItems} showBorders={false} columnAutoWidth={true}>
        {props.columns && renderColumns()}
      </DataGrid>
    </>
  )
}

export default DetailTemplate
