// This page is parked at the moment as there are no API created for this.

import React, { useState, useEffect } from 'react'
import utility from '../../../classes/utilityOld'
import { Config } from '../../../classes/config'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import AlertDialog from '../../Common/AlertDialog'
import AssignDatatable from '../../Common/CustomASDataGrid'
import Spinner from '../../Common/ReusableComponents/Spinner'
import DateBoxInput from '../../Common/ReusableComponents/DateBox'
import global from '../../../classes/global'

const WeeklyTimesheet = (props: any) => {
  if (props.role !== global.worxOnline_Admin) {
    window.open('#/AccessDenied', '_self')
  }
  const columns = [
    {
      field: 'fileName',
      title: 'File Name',
      // width: 160,
      type: 'string',
      allowHiding: false,
      link: 'file'
    }
  ]

  const [createdFrom, setcreatedFrom] = useState<any>(null)
  const [createdTo, setcreatedTo] = useState<any>(null)

  const [alertMessage, setAlertMessage] = useState<JSX.Element | string>('')
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [items, setItems] = useState<any>([])
  const [callAfterInit, setCallAfterInit] = useState<boolean>(false)

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={Config.productTitle}
        onClose={() => {
          setAlertMessage('')
          setShowAlert(false)
        }}
      />
    )
  }

  const getWeeklyTimesheet = () => {
    let dataToPassToService: any = {}
    if (createdFrom) {
      dataToPassToService.date_started_from = createdFrom
    }
    if (createdTo) {
      dataToPassToService.date_started_to = createdTo
    }
    let _items: any[] = []
    utility.genericPostAPIcall(Config.WeeklyTimesheet, dataToPassToService).then((data: any) => {
      if (data) {
        _items = data.map((item: any) => {
          return {
            fileName: item.fileName,
            fileURL: item.fileUrl
          }
        })
      }
      setItems(_items)
      setLoading(false)
    })
  }

  const viewFile = (file: any) => {
    let item = file
    let dataToPassToService: any = {}
    if (!utility.validURL(item?.fileURL) || !item?.fileName) {
      setAlertMessage('Unable to view this document. \n File URL or File name does exist')
      setShowAlert(true)
      return
    }
    dataToPassToService.fileName = item.fileName
    dataToPassToService.fileURL = item.fileURL
    utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {
      window.open(doc, '_blank')
    })
  }

  const _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      getWeeklyTimesheet()
    }
  }

  const stopCommsAfterInit = (): void => {
    setCallAfterInit(false)
  }

  const clearFilter = () => {
    setcreatedFrom(null)
    setcreatedTo(null)
  }

  const handleValueChange = (newValue: any, fieldName: string) => {
    if (fieldName === 'createdFrom') {
      setcreatedFrom(newValue.value)
    }
    if (fieldName === 'createdTo') {
      setcreatedTo(newValue.value)
    }
  }

  return (
    <>
      {showAlert && alert()}
      <div className={'ui-container'}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <DateBoxInput
              stylingMode='outlined'
              label={'Created From'}
              value={createdFrom ? new Date(createdFrom) : null}
              type='date'
              displayFormat={'dd/MM/yyyy'}
              placeholder={'DD/MM/YYYY'}
              showClearButton={true}
              onValueChanged={(e: any) => handleValueChange(e, 'createdFrom')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <DateBoxInput
              stylingMode='outlined'
              label={'Created To'}
              value={createdTo ? new Date(createdTo) : null}
              type='date'
              displayFormat={'dd/MM/yyyy'}
              placeholder={'DD/MM/YYYY'}
              showClearButton={true}
              onValueChanged={(e: any) => handleValueChange(e, 'createdTo')}
            />
          </Grid>
        </Grid>
        <div className='actions-container'>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <div className='action-buttons'>
                    <Button onClick={clearFilter} variant='outlined' className={'clearButton'}>
                    Clear
                    </Button>
                    <Button onClick={getWeeklyTimesheet} variant='outlined' color='primary' className={'primary-button'}>
                    Search
                    </Button>
                </div>
            </Grid>
            </Grid>
        </div>
      </div>
      <div>
        {!loading ? (
          <div className={'searchFieldDiv'}>
            {items && items.length > 0 && (
              <AssignDatatable
                style={{ width: '100%' }}
                filteralbe={true}
                groupable={true}
                dataSource={items}
                columns={columns}
                fileName={'WeeklyTimesheet'}
                columnChooser={'WeeklyTimesheetColumns'}
                selectionMode={false}
                showHideColumnMenu={true}
                callAfterInit={callAfterInit}
                stopCommsAfterInit={() => stopCommsAfterInit()}
                loading={loading}
                allowExportSelectedData={false}
                showNewBtn={false}
                // showNewModal = {() => _showModal()}
                // deleteItem = {(a:any) => DeleteDialog(a)}
                // editItem = {(a:any) => EditItemModal(a)}
                viewFile={(e: any) => viewFile(e)}
                showCommandBtn={false}
                showViewDetailBtn={false}
              />
            )}
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  )
}
export default WeeklyTimesheet
