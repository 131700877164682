import * as React from 'react';
import utility from '../../../classes/utilityOld';
import { Config } from '../../../classes/config';
import DataTable from '../../Common/CustomASDataGrid';
import AlertDialog from '../../Common/AlertDialog';
import DeleteDialog from '../../Common/DeleteAlert';
import Spinner from '../../Common/ReusableComponents/Spinner';
import AddEditItem from './AddEditItem';

import { AdminJson } from '../../../classes/AdminlistAutomation'

let _items: any = [];

let pageListItems: any;

export default class ShowListItems extends React.Component<any, any> {

  public currentUser: any;
  public page: any;
  public columns: any;

  constructor(props: any) {
    super(props);

    this.DeleteDialog = this.DeleteDialog.bind(this);

    this.currentUser = this.props.currentUser;

    // get param from url fro page identification and page data config

    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    this.page = params.get('page');

    // console.log(props.pageListItems)
    // console.log(JSON.stringify(props.pageListItems))

    if (props.pageListItems) {
      pageListItems = props.pageListItems.find((adminitem: any) => adminitem.Title === this.page)
    } else {
      pageListItems = AdminJson.find((adminitem: any) => adminitem.Title === this.page);
    }
    this.state = {
      items: _items,
      columns: this.columns,
    };
  }

  public componentDidMount() {
    if (pageListItems) {
      if (localStorage.getItem(pageListItems?.Title)) {
        let temppageListItems: any = localStorage.getItem(pageListItems?.Title) ? localStorage.getItem(pageListItems?.Title) : null;
        this.setState({ pageListItems: JSON.parse(temppageListItems) });
      } else {
        this.updatePageList()
      }
      this._getData();
    }
  }
  //func to get 
  // func to update pagelist and apilist items
  public updatePageList() {
    pageListItems?.AddEditField.forEach((field: any, i: any) => {
      if (field.Type === 'Choices') {
        this._getListItemsData(field, i)
      }
    });
    this.setState({ pageListItems: pageListItems });
    localStorage.setItem(pageListItems?.Title, JSON.stringify(pageListItems));
  }
  public async _getListItemsData(field: any, i: any) {

    await this.getPromiseData(field, i);
  }
  private getPromiseData(field: any, i: any) {
    return new Promise(resolve => {
      utility.genericGetAPICall(field.APIListName).then((data: any) => {
        let tempItems: any = [];
        for (let r of data) {
          tempItems.push({ id: r.Id, text: r.Title, "Select All": r.Title });
        }
        // set api list item value here
        pageListItems.AddEditField[i].APIListNameItems = tempItems

        resolve(tempItems);
        localStorage.setItem(pageListItems.Title, JSON.stringify(pageListItems));
      }, (err) => {
        console.log(err);
      });
    });
  }
  public DeleteItem(item: any) {

    this.setState({
      alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
      hideAlertButton: true,
      showAlert: true,
      showDeleteAlert: false,
    });
    utility.genericDeleteAPICall(this.state.pageListItems?.apiInfo.delete.name,
      Object.values(item)[0]
    )
      .then(
        (data: any) => {
          this.setState({
            alertMessage: `${this.state.pageListItems?.Title} has been deleted.`,
            showAlert: true,
            showDeleteAlert: false,
            hideAlertButton: false,
            loading: false
          });
          this._getData();
        },
        (err) => {
          console.log(err);
          this.setState({
            alertMessage: `Cannot delete ${this.state.configuration?.Title}. Please try again later.`,
            showAlert: true,
            showDeleteAlert: false,
            hideAlertButton: false,
          });
        }
      );

  }

  // edit  modal

  private EditItemModal(item: any) {
    //console.log("item", item);
    this.setState({ showModal: true, details: item, mode: 'edit' });
  }
  public renderNewModal() {
    return (
      <AddEditItem
        showModal={this.state.showModal}
        onClose={() => this._closeModal()}
        onClick={() => this._getData()}
        message={(e: any) => this.message(e)}
        currentUser={this.currentUser}
        details={this.state.details}
        mode={this.state.mode}
        configuration={this.state.pageListItems}
      />
    );
  }


  private _showModal = (): void => {
    this.setState({ showModal: true, details: null, mode: 'add' });
  };

  private _closeModal = (): void => {
    this.setState({ showModal: false });
    if (localStorage.getItem(pageListItems.Title)) {
      let temppageListItems: any = localStorage.getItem(pageListItems.Title) ? localStorage.getItem(pageListItems.Title) : null;
      this.setState({ pageListItems: JSON.parse(temppageListItems) })
    }
  };

  public message(message: any) {
    //console.log(message);
    this.setState({
      alertMessage: message + " successfully",
      showAlert: true,
    });
    if (localStorage.getItem(pageListItems.Title)) {
      let temppageListItems: any = localStorage.getItem(pageListItems.Title) ? localStorage.getItem(pageListItems.Title) : null;
      this.setState({ pageListItems: JSON.parse(temppageListItems) })
    }
  }
  private _getData() {
    this.setState({
      loading: true,
    });
    utility.genericGetAPICallForList(pageListItems.apiInfo.read.name, pageListItems.apiInfo.read.method, JSON.parse(pageListItems.apiInfo.read.dataToPassToService))
      .then(
        (data: any) => {
          _items = data;

          _items = utility._sortItems(_items, 'Dateupdated', false);
          this.setState({ items: _items, callAfterInit: true, loading: false });

          //console.log(_items);
        },
        (err) => {
          console.log(err);
          this.setState({ loading: false });
        }
      );
  }

  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }


  public render(): React.ReactElement<any> {

    return (
      <React.Fragment>
        {this.renderNewModal()}
        {this.state.showDeleteAlert && this.DeleteAlert()}
        {this.state.showAlert && this.alert()}
        <div className={"SearchDiv"}>
          <div className={'headingDiv'}>
            <span className={"heading"}> {pageListItems?.Title} </span>
          </div>
        </div>
        <div>
          {!this.state.loading ?
            <DataTable
              style={{ width: '100%' }}
              // id={this.state.columns && this.state.columns[0]?.field}
              filteralbe={true}
              groupable={true}
              dataSource={this.state.items}
              columns={this.state.columns}
              fileName={pageListItems?.Title}
              columnChooser={`${pageListItems?.Title}Columns`}
              selectionMode={false}
              showHideColumnMenu={true}
              callAfterInit={this.state.callAfterInit}
              stopCommsAfterInit={() => this.stopCommsAfterInit()}
              loading={this.state.loading}
              allowExportSelectedData={false}
              showNewModal={() => this._showModal()}
              deleteItem={(a: any) => this.DeleteDialog(a)}
              editItem={(a: any) => this.EditItemModal(a)}
              // viewFile={(e:any)=>this.viewFile(e)}
              showCommandBtn={true}
              showNewBtn={true}
              hideNewBtn={this.state.showModal || this.state.showModal || this.props.hideNewBtn}
              showViewDetailBtn={false}
            /> :
            <Spinner size='large' label="Loading..." />
          }

        </div>
      </React.Fragment>
    );
  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        hideAlertButton={this.state.hideAlertButton}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
      />
    );
  }
  public DeleteDialog(item: any) {
    this.setState({
      DeletealertMessage: 'Are you sure you want to delete?',
      showDeleteAlert: true,
      deleteItem: item
    });
  }
  public DeleteAlert() {
    return (
      <DeleteDialog
        showDialog={this.state.showDeleteAlert}
        message={this.state.DeletealertMessage}
        title={"worxOnline"}
        onClose={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
        onOkButton={() => this.DeleteItem(this.state.deleteItem)}
        onCancelButton={() => this.setState({ DeletealertMessage: '', showDeleteAlert: false })}
      />
    );
  }
}