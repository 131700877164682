import React, { useState, useEffect, useRef, forwardRef, Ref, ReactElement } from 'react'
import utility from '../../../../classes/utilityOld'
import { Config } from '../../../../classes/config'
import Spinner from '../../../Common/ReusableComponents/Spinner'
import AlertDialog from '../../../Common/AlertDialog'
import SelectBoxInput from '../../../Common/ReusableComponents/SelectInputBox'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import DataTable from '../../../Common/CustomASDataGrid'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import DeleteDialog from '../../../Common/DeleteAlert'
import Slide, { SlideProps } from '@mui/material/Slide'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import CloseIcon from '@mui/icons-material/Close'

interface MyComponentProps {
  workorder: any
  WorkOrderID: string
  onClick: () => void
  currentUser: string
  showModal: boolean
  onClose: () => void
  context: any
  aadHttpClient: any
}

const AssignVehicle: React.FC<MyComponentProps> = (props: any) => {
  const [details, setDetails] = useState<any>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [assignPlantItems, setAssignPlantItems] = useState<any>({})
  const [hideAlertButton, setHideAlertButton] = useState<boolean>(false)
  const [woid, setWoid] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [VehicleOperatorItems, setVehicleOperatorItems] = useState<any[]>([])
  const [callAfterInit, setCallAfterInit] = useState<boolean>(false)
  const [preItems, setPreItems] = useState<any[]>([])
  const [VehicleItems, setVehicleItems] = useState<any[]>([])
  const [allVehicleItems, setAllVehicleItems] = useState<any[]>([])
  const [categoryItems, setCategoryItems] = useState<any[]>([])
  const [allCategoryItems, setAllCategoryItems] = useState<any[]>([])
  const [VehicleOperator, setVehicleOperator] = useState<any>('')
  const [category, setCategory] = useState<any>('')
  const [Vehicle, setVehicle] = useState<any>('')
  const [alertMessage, setAlertMessage] = useState<string | JSX.Element>('')
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false)
  const [disableVehicle, setDisableVehicle] = useState<boolean>(true)
  const [assignVehicleItems, setAssignVehicleItems] = useState('')
  const [data, setData] = useState<any>({})
  const [deleteAlertMessage, setDeleteAlertMessage] = useState('')
  const [deleteItem, setDeleteItem] = useState<any>(null)
  const dialogRef = useRef(null)

  useEffect(() => {
    _getEmployeeData()
    _getVehicletypeData()
  }, [])

  const _Vehiclescolumns = [
    {
      field: 'planttype',
      title: 'Vehicle Type',
      width: 260,
      type: 'string'
    },
    {
      field: 'PlantNumber',
      title: 'Vehicle #',
      width: 160,
      type: 'string'
    },
    {
      field: 'VehicleTitle',
      title: 'Title',
      width: 160,
      type: 'string'
    },
    {
      field: 'Regonumber',
      title: 'Rego Number',
      width: 160,
      type: 'string'
    },
    {
      field: 'createdBy',
      title: 'Vehicle Operator',
      width: 160,
      type: 'string'
    }
  ]

  const viewDetails = (item: any) => {
    setDetails(item)
    setShowModal(true)
  }

  const didMount = () => {
    if (props.workorder && props.workorder.plantOperators) {
      let JSONPlantsData
      const _plantWOItems: any[] = []
      if (props.workorder.plantOperators !== null) {
        JSONPlantsData = JSON.parse(props.workorder.plantOperators)
        if (!Array.isArray(JSONPlantsData)) {
          JSONPlantsData = [JSONPlantsData]
        }
        JSONPlantsData.forEach(plantdata => {
          _plantWOItems.push({
            operatorFullName: plantdata.operatorFullName ? plantdata.operatorFullName : '',
            operatorId: plantdata.operatorId ? plantdata.operatorId : '',
            plantResourceCode: plantdata.plantResourceCode ? plantdata.plantResourceCode : '',
            plantTitle: plantdata.plantTitle ? plantdata.plantTitle : ''
          })
        })
      }
      setAssignPlantItems(_plantWOItems)
      setWoid(props.WorkOrderID)
    }
    setHideAlertButton(false)
    setWoid(props.workorder && props.workorder.Id)
  }

  const _getEmployeeData = () => {
    const dataToPassToService: any = {}
    dataToPassToService.isActive = true

    // console.log("dataToPassToService", dataToPassToService);
    utility
      .genericGetAPICall(Config.SearchEmployeeApi)
      .then((data: any) => {
        const employeedata = data
          .filter((r: any) => r.is_active === true && r.is_plant_operator === true)
          .map((r: any) => ({
            id: r.id,
            text: r.full_name,
            employeeno: r.resource_code,
            loginName: r.login_name,
            pin: r.pin,
            roleId: r.role_id,
            email: r.email
          }))
        const sortedemployeedata = utility._sortItems(employeedata, 'text', false)

        setVehicleOperatorItems(sortedemployeedata)
        setCallAfterInit(true)
        setLoading(false)
        setPreItems(sortedemployeedata)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
        setCallAfterInit(true)
      })
  }

  const _getVehicletypeData = () => {
    utility
      .genericGetAPICallForList(Config.EquipmentsListName)
      .then((data: any) => {
        const VehicleItems: any[] = []
        const categoryItems: any[] = []
        for (const r of data) {
          VehicleItems.push({ id: r.id, text: r.title, category: r.category })
          categoryItems.push({ id: r.category, text: r.category })
        }
        setVehicleItems(VehicleItems)
        setAllVehicleItems(VehicleItems)
        setCategoryItems(categoryItems)
        setAllCategoryItems(categoryItems)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const stopCommsAfterInit = (): void => {
    setCallAfterInit(false)
  }

  const clearFilter = () => {
    setCategory('')
    setVehicleOperator('')
    setVehicle('')
    setAssignVehicleItems('')
  }

  const _fieldChanged = (event: any, fieldName: string, options?: any) => {
    switch (fieldName) {
      case 'category':
        setCategory(event.selectedItem)
        if (event.selectedItem) {
          const tempVehicleItems = allVehicleItems
          const filteredVehicleItems = tempVehicleItems.filter((t: any) => t.category === event.selectedItem.id)
          setDisableVehicle(false)
          setVehicleItems(filteredVehicleItems)
          setVehicle('')
        }
        break
      case 'Vehicle':
        setVehicle(event.selectedItem)
        break
      case 'VehicleOperator':
        setVehicleOperator(event.selectedItem)
        break
      default:
        break
    }
  }

  const deleteAssignVehicle = (item: any) => {
    const dataToPassToService: any = {}
    setAlertMessage(<Spinner label='Deleting...' />)
    setHideAlertButton(true)
    setShowAlert(true)
    dataToPassToService.id = item

    utility
      .genericDeleteAPICall(Config.WorkorderEquipments, item)
      .then((results: any) => {
        setShowAlert(false)
        setShowDeleteAlert(false)
        setLoading(false)
        props.onClick()
        clearFilter()
      })
      .catch((err: any) => {
        setShowAlert(false)
        setShowDeleteAlert(false)
        setLoading(false)
        console.log(err)
      })
  }

  const addAssignVehicle = () => {
    const dataToPassToService: any = {}

    if (!category) {
      setAlertMessage('Please select Category')
      setShowAlert(true)
      setHideAlertButton(false)

      return
    }
    if (!Vehicle) {
      setAlertMessage('Please select Title')
      setShowAlert(true)
      setHideAlertButton(false)

      return
    }
    if (!VehicleOperator) {
      setAlertMessage('Please select operator')
      setShowAlert(true)
      setHideAlertButton(false)

      return
    }
    setAlertMessage(<Spinner label='Adding...' />)
    setHideAlertButton(true)
    setShowAlert(true)
    setLoading(true)
    const tempAssignPlantItems: any = assignPlantItems ? assignPlantItems : {}
    tempAssignPlantItems.operator_full_name = VehicleOperator.text
    tempAssignPlantItems.operator_id = VehicleOperator.id
    tempAssignPlantItems.plant_resource_code = Vehicle.id
    tempAssignPlantItems.plant_title = Vehicle.text

    dataToPassToService.plantOperators = tempAssignPlantItems
    dataToPassToService.id = props.WorkOrderID
    dataToPassToService.updated = new Date()
    dataToPassToService.updatedBy = props.currentUser ? props.currentUser.Email : ''
    utility
      .genericUpdatePatchAPICall(Config.WOdetailsSearchAPI, props.WorkOrderID, dataToPassToService)
      .then((results: any) => {
        setShowAlert(false)
        setLoading(false)
        setHideAlertButton(false)
        props.onClick()
        clearFilter()
      })
      .catch((err: any) => {
        setShowAlert(false)
        setLoading(false)
        setHideAlertButton(false)
        console.log(err)
      })
  }

  const filterChange = (event: any, dataName: any) => {
    setTimeout(() => {
      const filteredData = filterData(dataName, event.filter)
      setData((prevData: any) => ({
        ...prevData,
        [dataName]: filteredData
      }))
    }, 500)
  }

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={Config.productTitle}
        onClose={() => {
          setAlertMessage('')
          setShowAlert(false)
        }}
        hideAlertButton={hideAlertButton}
      />
    )
  }

  const filterData = (dataName: any, filter: any) => {
    const allData = `all${dataName}`
    const data = allVehicleItems.slice()

    return filter(data, filter)
  }

  const deleteDialog = (item: any) => {
    setDeleteAlertMessage('Are you sure you want to delete?')
    setShowDeleteAlert(true)
    setDeleteItem(item)
  }

  const DeleteAlert = () => {
    return (
      <DeleteDialog
        showDialog={showDeleteAlert}
        message={deleteAlertMessage}
        title={'worxOnline'}
        onClose={() => {
          setDeleteAlertMessage('')
          setShowDeleteAlert(false)
        }}
        onOkButton={() => deleteAssignVehicle(deleteItem)}
        onCancelButton={() => {
          setDeleteAlertMessage('')
          setShowDeleteAlert(false)
        }}
      />
    )
  }

  useEffect(() => {
    if (props.showModal && dialogRef.current) {
      didMount()
    }
    
  }, [props.showModal])

  return (
    <div>
      {showAlert && alert()}
      {showDeleteAlert && DeleteAlert()}
      <Dialog
        open={props.showModal}
        onClose={clearFilter}
        // className={'addEditModal'}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby='max-width-dialog-title'
        ref={dialogRef}
      >
        <DialogTitle id='scroll-dialog-title'>
          Assign/Modify Plant/Vehicle
          <IconButton aria-label='close' onClick={() => props.onClose()} className='closeIcon' sx={{ float: 'right' }}>
          <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={false}>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <SelectBoxInput
                  stylingMode='outlined'
                  displayExpr='text'
                  valueExpr='id'
                  showClearButton={true}
                  items={categoryItems}
                  selectedItem={category}
                  label='Category'
                  onSelectionChanged={(e: any) => _fieldChanged(e, 'category', 'categoryItems')}
                />
              </Grid>
              {VehicleItems && (
                <Grid item xs={12} sm={4}>
                  <SelectBoxInput
                    stylingMode='outlined'
                    displayExpr='text'
                    valueExpr='id'
                    showClearButton={true}
                    disabled={disableVehicle}
                    items={VehicleItems ? VehicleItems : ''}
                    selectedItem={Vehicle}
                    label='Plant/Vehicle'
                    onSelectionChanged={(e: any) => _fieldChanged(e, 'Vehicle', 'VehicleItems')}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={4}>
                <SelectBoxInput
                  stylingMode='outlined'
                  displayExpr='text'
                  valueExpr='id'
                  showClearButton={true}
                  items={VehicleOperatorItems}
                  selectedItem={VehicleOperator}
                  label='Operator'
                  onSelectionChanged={(e: any) => _fieldChanged(e, 'VehicleOperator', 'VehicleOperatorItems')}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <div>
                  {!loading ? (
                    <div>
                      {assignVehicleItems && assignVehicleItems.length > 0 && (
                        <DataTable
                          //style={{ width: '100%' }}
                          filteralbe={true}
                          groupable={true}
                          dataSource={assignVehicleItems}
                          columns={_Vehiclescolumns}
                          fileName={'workorders'}
                          columnChooser={'workorderColumns'}
                          selectMode={false}
                          showHideColumnMenu={true}
                          callAfterInit={callAfterInit}
                          stopCommsAfterInit={() => stopCommsAfterInit()}
                          loading={loading}
                          deleteItem={(a: any) => deleteDialog(a)}
                          allowExportSelecteditems={false}
                          showNewBtn={false}
                          hideCommandEditBtn={true}
                          showCommandBtn={true}
                        />
                      )}
                    </div>
                  ) : (
                    // <CircularProgress className="loader" />
                    <Spinner size='large' label='Loading..' className='loader' />
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <div className='action-buttons'>
            <Button onClick={clearFilter} variant='outlined'>
              Clear
            </Button>
            <Button onClick={addAssignVehicle} variant='outlined' color='primary' className='primary-button'>
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AssignVehicle
