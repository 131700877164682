import global from './global'

interface JSONData {
  expires_at: string
  refresh_token: string
}

const utility = {
  async getTokenUsingAPI() {
    const currentUserEmail = localStorage.getItem('currentDetail')
    const storagedata = localStorage.getItem(global.accessToken)

    if (storagedata !== null) {
      const data: any = JSON.parse(storagedata)
      const currentDateTime = new Date()
      const endDate = data.expires_at

      let expiredate: Date = new Date(0)
      expiredate.setUTCSeconds(Number(endDate))
      expiredate = new Date(expiredate.getTime() - 30 * 60000)

      if (currentDateTime.getTime() >= expiredate.getTime()) {
        const res = await utility.tokenRefreshAPI(storagedata)
        if (res) return res
      } else {
        return data.access_token
      }
    }
  },

  async tokenAPIWithUserDetails(currentUserEmail: any, password: any) {
    const body = {
      username: currentUserEmail,
      password: password,
      grant_type: '',
      scope: '',
      client_id: '',
      client_secret: ''
    }

    const formBody = Object.keys(body)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key as keyof typeof body]))
      .join('&')

    try {
      const result = await fetch(`${global.AuthAPIURL}`, {
        method: 'POST',
        body: formBody,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
      })

      if (result.ok) {
        //localStorage.setItem(global.accessToken, JSON.stringify(result))
        const jsonResult = await result.json()
        return jsonResult
      } else {
        const text = await result.text()
        throw new Error(text)
        //throw new Error('Network response was not ok.')
      }
    } catch (error: any) {
      console.error(error)
      throw error
    }
  },

  async tokenRefreshAPI(oldToken: string) {
    //const apiname = `${Config.UserDetails}`
    try {
      const result = await fetch(`${global.spAPIURL}/auth/refresh-token`, {
        method: 'POST',
        body: oldToken,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (result.ok) {
        const jsonResult = await result.json()
        return jsonResult
      } else {
        const text = await result.text()
        throw new Error('Network response was not ok.' + text)
      }
    } catch (error: any) {
      console.error(error)
      throw error
    }
  },

  populateKeyTextforDD: (arr: any, keyCol: any, textCol: any) => {
    arr.forEach((element: any) => {
      element.id = element[keyCol]
      element.text = element[textCol]
    })
    arr.sort((a: any, b: any) => a[textCol].localeCompare(b[textCol]))
  },
  GetPathwayObject: (data: any, idColumn: any, id: any) => {
    let objPathway
    for (let i = 0; i < data.length; i++) {
      if (data[i][idColumn] === id) {
        objPathway = data[i]
        break
      }
    }

    return objPathway
  },
  // tokenAPI(currentUserEmail: any) {
  //   return new Promise(function (resolve) {
  //     const msalInstance = new msal.PublicClientApplication(msalConfig)
  //     const accounts: any = msalInstance.getAllAccounts()

  //     const ssoRequest = {
  //       scopes: [global.AzureScope],

  //       // scopes: ['https://graph.microsoft.com/Mail.Read'],
  //       loginHint: currentUserEmail
  //     }
  //     const tokenRequest = {
  //       scopes: [global.AzureScope]
  //     }

  //     if (accounts.length > 0) {
  //       msalInstance.setActiveAccount(accounts[0])
  //     }

  //     let currentAccount: any = msalInstance.getActiveAccount()
  //     if (!currentAccount) {
  //       currentAccount = msalInstance.getAccountByUsername(currentUserEmail)
  //     }

  //     if (currentAccount) {
  //       msalInstance
  //         .acquireTokenSilent({
  //           ...loginRequest,
  //           account: currentAccount
  //         })
  //         .then(response => {
  //           localStorage.setItem(global.accessToken, JSON.stringify(response))

  //           resolve(response.idToken)
  //         })
  //         .catch(err => {
  //           console.log(err)
  //           msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' })
  //         })
  //     } else {
  //       msalInstance
  //         .ssoSilent(ssoRequest)
  //         .then(response => {
  //           localStorage.setItem(global.accessToken, JSON.stringify(response))
  //           resolve(response.idToken)
  //         })
  //         .catch(error => {
  //           return msalInstance
  //             .acquireTokenPopup(tokenRequest)
  //             .then(response => {
  //               localStorage.setItem(global.accessToken, JSON.stringify(response))
  //               resolve(response.idToken)
  //             })
  //             .catch(err => {
  //               if (err) {
  //                 console.log('Error' + err)
  //               }
  //             })
  //         })
  //     }
  //   })
  // },

  // genericGetAPICall(apiname: string) {
  //   const promise = new Promise((resolve, reject) => {
  //     utility.getItem(apiname).then((result: any) => {
  //       if (result && result.length > 0) {
  //         resolve(result)
  //       } else {
  //         utility.genericGetAPICallResponse(apiname).then(
  //           (data: any) => {
  //             utility.setItem(apiname, data)
  //             resolve(data)
  //           },
  //           err => {
  //             console.log(err)
  //           }
  //         )
  //       }
  //     })
  //   })

  //   return promise
  // },

  async genericGetAPICall(apiname: any) {
    try {
      // const result = await utility.getItem(apiname)

      // if (result && Object.keys(result).length > 0) {
      //   return result
      // } else {
      const data = await utility.genericGetAPICallResponse(apiname)
      //await utility.setItem(apiname, data)
      return data
      // }
    } catch (err) {
      console.log(err)
      throw err
    }
  },

  async genericGetAPICallResponse(apiname: string) {
    try {
      const token = await utility.getTokenUsingAPI()

      const response = await fetch(`${global.spAPIURL}/${apiname}`, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
     
      const text = await response.text()

      if (response.ok) {
        // Parse the JSON only if the response is okay
        return JSON.parse(text)
      } else {
        throw new Error(`Network response was not ok: ${text}`)
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async LayerGetAPICallResponse(apiName: string) {
    try {
      const result = await fetch(`${global.isAzurefile}${apiName}`, {
        method: 'GET'
      })

      if (result.ok) {
        const resultJson = await result.json()
        return resultJson
      } else {
        throw new Error('Network response was not ok.')
      }
    } catch (error) {
      console.error(error)
    }
  },

  async genericGetAPICallForList(apiname: string, method?: any, data?: any) {
    try {
      const token = await utility.getTokenUsingAPI()
      const header: any = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        }
      }
      if (method === 'POST') {
        header.body = data ? JSON.stringify(data) : ''
      }
      const result = await fetch(`${global.spAPIURL}/${apiname}`, header)
      //console.log(result);
      if (result.ok) {
        const jsonResult = await result.json()
        return jsonResult
      } else {
        throw new Error('Network response was not ok.')
      }
    } catch (error) {
      console.error(error)
    }
  },

  async genericGetDataByIdAPICall(apiname: string, id: any) {
    try {
      const token = await utility.getTokenUsingAPI()

      const result = await fetch(`${global.spAPIURL}/${apiname}/${id}`, {
        method: 'GET',
        headers: {
          authorization: `Bearer ${token}`
        }
      })

      if (result.ok) {
        const contentType = result.headers.get('content-type')
        if (contentType && contentType.includes('application/json')) {
          const data = await result.json()
          return data
        } else {
          return null // Empty response, return null
        }
      } else {
        throw new Error('Network response was not ok.')
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async genericDeleteAPICall(apiname: string, id: any) {
    try {
      const token = await utility.getTokenUsingAPI()
      const result = await fetch(`${global.spAPIURL}/${apiname}${id}`, {
        method: 'DELETE',
        headers: {
          authorization: `Bearer ${token}`
        }
      })

      if (result.ok) {
        return result
      } else {
        throw new Error('Network response was not ok.')
      }
    } catch (error) {
      console.error(error)
    }
  },

  async deleteBulkWorkorder(apiname: string, data: any, spAPIURL: any) {
    try {
      const token = await utility.getTokenUsingAPI()

      const result = await fetch(`${spAPIURL}/${apiname}`, {
        method: 'DELETE',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (result.ok) {
        return result
      } else {
        throw new Error('Network response was not ok.')
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async genericUpdateAPICall(apiname: string, id: any, data: any) {
    try {
      const token = await utility.getTokenUsingAPI()

      const result = await fetch(`${global.spAPIURL}/${apiname}${id}`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (result.ok) {
        return result
      } else {
        throw new Error('Network response was not ok.')
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async genericUpdatePatchAPICall(apiname: string, id: any, data: any) {
    try {
      const token = await utility.getTokenUsingAPI()

      const result = await fetch(`${global.spAPIURL}/${apiname}${id}`, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (result.ok) {
        return result
      } else {
        throw new Error('Network response was not ok.')
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async genericUpdatePortalAPICall(apiname: string, data: any) {
    try {
      const token = await utility.getTokenUsingAPI()

      const result = await fetch(`${global.spAPIURL}/${apiname}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (result.ok) {
        return result
      } else {
        throw new Error('Network response was not ok.')
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async genericUpdateNatureStripAPICallForWO(apiname: string, woID: number, data: any) {
    try {
      const token = await utility.getTokenUsingAPI()

      const result = await fetch(`${global.spAPIURL}/${apiname}/${woID}`, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (result.ok) {
        return result
      } else {
        const text = await result.text()
        throw new Error(text)
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async genericUpdateNatureStripAPICall(apiname: string, data: any) {
    try {
      const token = await utility.getTokenUsingAPI()

      const result = await fetch(`${global.spAPIURL}/${apiname}`, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (result.ok) {
        return result
      } else {
        const text = await result.text()
        throw new Error(text)
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async genericGETAPIcallwithdata(apiName: string, data: any, spAPIURL: any) {
    try {
      const token = await utility.getTokenUsingAPI()

      const result = await fetch(`${spAPIURL}/${apiName}`, {
        method: 'GET',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (result.ok) {
        return result.json()
      } else {
        const jsonError = await result.json().catch(() => {
          throw new Error('Error parsing JSON result')
        })
        throw jsonError
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async genericPostAPIcall(apiName: string, data: any) {
    try {
      const token = await utility.getTokenUsingAPI()

      const result = await fetch(`${global.spAPIURL}/${apiName}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (result.ok) {
        const jsonResult = await result.json()
        return jsonResult
      } else {
        const jsonError = await result.json().catch(() => {
          throw new Error('Error parsing JSON result')
        })
        throw jsonError
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async genericPostAPIcallForWODocument(apiName: string, data: any) {
    try {
      const token = await utility.getTokenUsingAPI()

      const result = await fetch(`${global.spAPIURL}/${apiName}`, {
        method: 'POST',
        body: data,
        headers: {
          authorization: `Bearer ${token}`
        }
      })

      if (result.ok) {
        const jsonResult = await result.json()
        return jsonResult
      } else {
        const jsonError = await result.json().catch(() => {
          throw new Error('Error parsing JSON result')
        })
        throw jsonError
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async genericPostAPIcallForDowloadDoct(apiName: string, data: any) {
    try {
      const token = await utility.getTokenUsingAPI()

      const result = await fetch(`${global.spAPIURL}/${apiName}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`
        }
      })

      if (result.ok) {
        return result.text()
      } else {
        return result.text()
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async loadNaturalSearchFromApi(relativeUrl: string, apiName: string) {
    try {
      const result = await fetch(`${global.spAPIURL}/${apiName}?utterance=${relativeUrl}`, {
        method: 'GET'
      })

      if (result.ok) {
        const jsonResult = await result.json()
        return jsonResult
      } else {
        throw new Error('Network response was not ok.')
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  cuniq: () => {
    const currentdate = new Date()
    const datetime =
      currentdate.getFullYear() +
      '-' +
      (currentdate.getMonth() + 1) +
      '-' +
      currentdate.getDate() +
      '-' +
      currentdate.getHours() +
      '-' +
      currentdate.getMinutes() +
      '-' +
      currentdate.getSeconds()

    return datetime
  },

  goBack: () => {
    window.history.back()
  },

  _sortItems: (items: any[], sortBy: string, descending = false): any[] => {
    if (descending) {
      return items.sort((a: any, b: any) => {
        if (a[sortBy] < b[sortBy]) {
          return 1
        }
        if (a[sortBy] > b[sortBy]) {
          return -1
        }

        return 0
      })
    } else {
      return items.sort((a: any, b: any) => {
        if (a[sortBy] < b[sortBy]) {
          return -1
        }
        if (a[sortBy] > b[sortBy]) {
          return 1
        }

        return 0
      })
    }
  },

  groupBy(xs: any, key: any) {
    if (xs && xs.length > 0) {
      return xs.reduce(function (rv: any, x: any) {
        ;(rv[x[key]] = rv[x[key]] || []).push(x)

        return rv
      }, {})
    } else {
      return false
    }
  },

  validURL(str: any) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // fragment locator

    return !!pattern.test(str)
  },

  async getItem(storageName: any) {
    try {
      const indexedDB = window.indexedDB
      const request = indexedDB.open('worxOnline-saas', 2)

      const db = await new Promise((resolve, reject) => {
        request.onupgradeneeded = event => {
          const db = request.result
          if (!db.objectStoreNames.contains('worxOnline-saas-Store')) {
            db.createObjectStore('worxOnline-saas-Store', { keyPath: 'id' })
            resolve(db)
          }
        }

        request.onsuccess = event => {
          const db = request.result
          if (!db.objectStoreNames.contains('worxOnline-saas-Store')) {
            resolve(db)
          } else {
            const transaction = db.transaction('worxOnline-saas-Store', 'readonly')
            const stores = transaction.objectStore('worxOnline-saas-Store')
            const requestItem = stores.get(storageName)

            requestItem.onsuccess = function () {
              if (requestItem.result && requestItem.result.items) {
                const result = JSON.parse(requestItem.result.items)
                resolve(result)
              } else {
                resolve('')
              }
            }

            requestItem.onerror = function () {
              // handle error if needed
              // reject(requestItem.error);
            }

            transaction.oncomplete = function () {
              db.close()
            }
          }
        }

        request.onerror = event => {
          // handle error if needed
          console.log('Finished doing something, now closing')
          // resolve(true);
          reject()
        }
      })

      return db
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async setItem(storageName: any, data: any) {
    try {
      const indexedDB = window.indexedDB

      const request = indexedDB.open('worxOnline-saas', 2)

      request.onupgradeneeded = event => {
        const db = request.result
        if (!db.objectStoreNames.contains('worxOnline-saas-Store')) {
          db.createObjectStore('worxOnline-saas-Store', { keyPath: 'id' }) // create it
        }
      }

      const db: any = await new Promise((resolve, reject) => {
        request.onsuccess = event => {
          resolve(request.result)
        }

        request.onerror = event => {
          // handle error if needed
          console.log('Finished doing something, now closing')
          // resolve(true);
          reject()
        }
      })

      const transaction = db.transaction(['worxOnline-saas-Store'], 'readwrite')
      const stores = transaction.objectStore('worxOnline-saas-Store')

      const store = {
        id: storageName,
        items: JSON.stringify(data)
      }

      const requestItem = stores.add(store)

      await new Promise((resolve, reject) => {
        requestItem.onsuccess = function () {
          resolve(true)
        }

        requestItem.onerror = function () {
          console.log('Error', requestItem.error)
          reject(requestItem.error)
        }
      })

      // Close the database after transaction
      db.close()

      return true // Indicate success
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async removeItem(storeId: any) {
    try {
      const indexedDB = window.indexedDB
      const open = indexedDB.open('worxOnline-saas', 2)

      open.onsuccess = async function () {
        const db = open.result
        const transaction = db.transaction(['worxOnline-saas-Store'], 'readwrite')
        const store = transaction.objectStore('worxOnline-saas-Store')

        await store.delete(storeId)

        transaction.oncomplete = function () {
          db.close()
        }
      }

      open.onerror = function () {
        return
      }
    } catch (error: any) {
      console.error(error.message)
      throw error
    }
  }
}

export default utility
