import React, { useState } from 'react'
import {Popup, ToolbarItem} from 'devextreme-react/popup';

interface AlertDialogProps {
  showDialog: boolean
  message: string | any
  title: string | any
  onClose: () => void
  hideAlertButton?: any
  showCreateButton?: any
  link?: any
}

const AlertDialog: React.FC<AlertDialogProps> = props => {
  const [showDialog, setShowDialog] = useState(props.showDialog)

  const closeDialog = (): void => {
    setShowDialog(false)
    props.onClose()
  }

  return (
    <div>
      <Popup
        width={300}
        maxHeight={300}
        minHeight={200}
        height={'auto'}
        showTitle={true}
        showCloseButton={false}
        title={props.title}
        dragEnabled={false}
        hideOnOutsideClick={false}
        visible={showDialog}
        onVisibleChange={closeDialog}
      >
        <div className='popContentDiv' style={{ fontSize: '16px' }}>
          {props.message}
        </div>
        {!props.hideAlertButton && (
          <ToolbarItem
            widget='dxButton'
            toolbar='bottom'
            location='after'
            options={{
              text: 'Close',
              stylingMode: 'outlined',
              onClick: closeDialog
            }}
          />
        )}
      </Popup>
    </div>
  )
}

export default AlertDialog
