// DropZone.tsx

import React, { useState, useEffect } from 'react';
import { DropzoneComponent } from 'react-dropzone-component';

const DropZone = ({ cellData, editDetails, uploadedFiles, setUploadedFiles, fileType, fileSizeLimit  }: any) => {
  const componentConfig = {
    showFiletypeIcon: true,
    postUrl: 'no-url'
  };

  const djsConfig = {
    autoProcessQueue: false,
    addRemoveLinks: true,
    maxFiles: 1,
    // acceptedFiles: 'image/*',
    acceptedFiles: fileType,
    maxFilesize: fileSizeLimit,
  };

  let myDropzone: any;

  const handleEvent = (file: any) => {
    uploadedFiles.push({ file });
    setUploadedFiles(uploadedFiles);
    cellData.data.setValue(uploadedFiles);
  };

  const deleteFile = (file: any) => {
    const index = uploadedFiles.findIndex((x: any) => x.file === file);
    uploadedFiles.splice(index, 1);
    setUploadedFiles(uploadedFiles);
  };

  const eventHandlers = {
    accept: (file: any, done: any) => {
      // if (file.type !== "image/jpeg" && file.type !== "image/png") {
        if (!fileType.includes(file.type)) {
        done("Error! Files of this type are not accepted");
      } else { 
        done(); 
      }
    },
    init: function (dz: any) {
      myDropzone = dz;
      if (editDetails && editDetails.file_url) {
        const fileTypeToUse = fileType.split(',')[0]; // Use the first file type as the mock file type
        const mockFileSize = Math.min(editDetails.size || 12345, fileSizeLimit * 1024 * 1024); // Limit the mock file size based on the fileSizeLimit prop
        const mockFile = { name: editDetails.name, size: mockFileSize, type: fileTypeToUse };
        
        dz.emit("addedfile", mockFile);
        if (fileTypeToUse.includes("image")) {
          dz.emit("thumbnail", mockFile, editDetails.file_url);
        }
        dz.emit("complete", mockFile);
        dz.files.push(mockFile);
      }
    },
    addedfile: (file: any) => {
      handleEvent(file);
    },
    removedfile: (file: any) => {
      deleteFile(file);
    }
  }

  return (
    <DropzoneComponent
      eventHandlers={eventHandlers}
      djsConfig={djsConfig}
      config={componentConfig}
      className="dz-message icon ion-upload"
      action='Drop files here or click to upload.'
    />
  )
}

export default DropZone;
