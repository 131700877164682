import * as React from 'react'
import DateBox from 'devextreme-react/date-box'

type EditorStyle = 'outlined' | 'filled' | 'underlined'

interface Props {
  label?: string
  stylingMode?: EditorStyle | undefined
  placeholder?: string
  value: any
  showClearButton?: boolean
  readOnly?: boolean
  disabled?: boolean
  onValueChanged: (e: any) => void
  required?: any
  displayFormat?: string
  type?: string | any
  className?: string
}

const DateBoxInput: React.FC<Props> = ({
  label,
  stylingMode,
  placeholder,
  value,
  showClearButton,
  readOnly,
  disabled,
  onValueChanged,
  displayFormat,
  type,
  className
}) => {
  return (
    <DateBox
      stylingMode={stylingMode}
      placeholder={placeholder}
      value={value}
      onValueChanged={onValueChanged}
      showClearButton={showClearButton}
      labelMode='floating'
      label={label}
      displayFormat={displayFormat}
      type={type}
      readOnly={readOnly}
      disabled={disabled}
      className={className}
    />
  )
}

export default DateBoxInput
