import React, { useState, useEffect } from 'react'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import Spinner from '../../Common/ReusableComponents/Spinner'
import JsonCellRenderer from '../../Common/ReusableComponents/JsonCellRenderer';
import ModelEditor from '../../Common/ReusableComponents/ModelEditor';
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'
import TextAreaInput from '../../Common/ReusableComponents/TextArea';

interface LoadResult {
  data: any[]
  totalCount: number
}

const Forms = (props: any) => {
  // if (props.role !== global.worxOnline_Admin) {
  //   window.open('#/AccessDenied', '_self')
  // }
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);


  const columns = [
    {
      field: 'id',
      title: 'ID',
      width: 80,
      type: 'string',
      allowHiding: false,
      visibleColumn: false,
      formItem: {
        visible: false
      }
    },
    {
      field: 'title',
      title: 'Title',
      // width: 160,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    {
      field: 'form_name',
      title: 'Form Name',
      // width: 160,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    {
      field: 'type',
      title: 'Type',
      width: 280,
      type: 'string',
      isRequired: true
    },
    // {
    //   field: 'model',
    //   title: 'Model',
    //   width: 280,
    //   type: 'string',
    //   cellRenderer: JsonCellRenderer,
    //   editCellComponent: ModelEditor
    // },
    {
      field: 'model',
      title: 'Model',
      width: 250,
      type: 'string',
      allowHiding: false,
      visibleColumn: true,
      formItem: {
        visible: true,
        editorType: 'dxTextArea',
        editorOptions: {
          height: 100,
          render: (data: any) => (
            <div>
              <TextAreaInput
                stylingMode='outlined'
                value={data.model}
                onValueChanged={(e: any) => {
                  data.model = (e.value);
                }}
              />
            </div>
          )
        }
      }
    },
    {
      field: 'erp_form_guid',
      title: 'Guid',
      width: 280,
      type: 'string'
    },
    {
      field: 'is_active',
      title: 'is Active',
      width: 280,
      type: 'boolean',
      calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    },
    {
      field: 'created_by',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    }
  ]

  useEffect(() => {
    fetchItemsData()
  }, [])

  const fetchItemsData = async () => {
    setLoading(true)
    try {
      const itemsResponse = await utility.genericGetAPICall(Config.FormsListName)
      let items: any[] = []

      for (let r of itemsResponse) {
        items.push({
          ...r,
          model: JSON.stringify(r.model, null, 2),
          id: r.id,
          active: r.is_active ? true : false
        })
      }
      items = utility._sortItems(items, 'id', true)
      setItems(items)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      return {
        data: items,
        totalCount: items.length
      }
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded')
      setLoading(false)
      setDataLoaded(true)
    },
    insert: async (values: any) => {
      try {
        if (values.model && typeof values.model === 'string') {
          values.model = JSON.parse(values.model)
        }
        await utility.genericPostAPICall(Config.FormsListName, values)
        await fetchItemsData()
      } catch (error: any) {
        if (error instanceof Error && error.message.startsWith('Network response was not ok')) {
          try {
            const errorData = JSON.parse(error.message.replace('Network response was not ok: ', ''))
            const errorMessage = utility.extractErrorMessage(errorData)
            throw new Error(errorMessage)
          } catch (parseError) {
            throw new Error('Failed to process error response.')
          }
        } else {
          throw new Error('Failed to post data.')
        }
      }
    },
    update: async (key: any, values: any) => {
      try {
        if (values.model && typeof values.model === 'string') {
          values.model = JSON.parse(values.model)
        }
        await utility.genericUpdatePatchAPICall(Config.FormsListName, key, values)
        await fetchItemsData()
      } catch (error: any) {
        throw error
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.FormsListName, key)
        await fetchItemsData()
      } catch (error: any) {
        throw error
      }
    }
  })

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'Forms'}
              columnChooser={'FormColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              //defaultfiltervalue={filterValue}
              showMoreBtn={true}
              visible={dataLoaded}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  )
}

export default Forms
