import React, { useState, useEffect, ChangeEvent, useCallback } from 'react'
import * as PropTypes from 'prop-types'

import { Config } from '../../../classes/config'
import utility from '../../../classes/utility'
import SelectBoxInput from '../../Common/ReusableComponents/SelectInputBox'
import MultiSelect from '../../Common/ReusableComponents/MultiSelectBox'
import TextBoxInput from '../../Common/ReusableComponents/TextBox'
import NumberBoxInput from '../../Common/ReusableComponents/NumberBoxInput'
import CheckBoxInput from '../../Common/ReusableComponents/CheckBox'
import ButtonGroupItem from '../../Common/ReusableComponents/ButtonGroup'
import DataTable from '../../Common/CustomASDataGrid'
import Spinner from '../../Common/ReusableComponents/Spinner'
import AlertDialog from '../../Common/AlertDialog'
import AssetMapforDetail from './AssetMapForDetail'
import AssetMap from './AssetMap'

// ** MUI Imports
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import Radio, { RadioProps } from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import Icon from '@mui/material/Icon'
import { Input, Typography } from '@mui/material/'
import Checkbox from '@mui/material/Checkbox'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../Common/TabPanel'
import 'devextreme/dist/css/dx.light.css'

// import 'devextreme/dist/css/dx.common.css'
import { Value } from 'devextreme-react/range-selector'

//let allState: any = []
let _items: any = []
const selectedtab: any = 0
const searchResultsText = 'Please Search To Populate Results'

export const columns = [
  {
    field: 'AssetNumber',
    title: 'Asset ID',

    // width: 160,
    type: 'string',
    allowHiding: false,
    link: 'assetLink'
  },
  {
    field: 'erpAssetId',
    title: 'Erp Asset ID',

    // width: 360,
    type: 'string',
    allowHiding: true

    //link: 'asseticassetLink'
  },
  {
    field: 'AssetName',
    title: 'Asset Name',

    // width: 360,
    type: 'string',
    allowHiding: true
  },
  // {
  //   field: 'Register',
  //   title: 'Register',

  //   // width: 360,
  //   type: 'string',
  //   allowHiding: true
  // },
  {
    field: 'typeTitle',
    title: 'Type',

    // width: 160,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'subtypeTitle',
    title: 'Sub Type',

    // width: 160,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'Location',
    title: 'Location',

    // width: 120,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'maintenanceAssetType',
    title: 'Maintenance Type',

    // width: 120,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'Category',
    title: 'Category',

    // width: 120,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'departmentTitle',
    title: 'Department',

    // width: 120,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'teamTitle',
    title: 'Team',

    // width: 120,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'AssetClass',
    title: 'Class',

    // width: 120,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'assetsubclass',
    title: 'Sub Class',

    // width: 120,
    type: 'string',
    allowHiding: true
  },
  {
    field: 'AssetStatus',
    title: 'Status',

    // width: 180,
    type: 'string',
    allowHiding: true
  }
]

export const totalResultsList = [
  { id: 1, text: '100' },
  { id: 2, text: '200' },
  { id: 3, text: '300' },
  { id: 4, text: '500' },
  { id: 5, text: '1000' }
]

interface AssetStatusItem {
  id?: number
  text?: string
  'Select All'?: string
}

interface AssetTypeOrClassItem {
  id?: number
  text?: string
  'Select All'?: string
  type?: string
  level?: string
}

interface stateComponent {
  assetClassItem: AssetTypeOrClassItem[]
  assetSubClassItem: AssetTypeOrClassItem[]
  assetTypeItem: AssetTypeOrClassItem[]
  subAssetTypeItem: AssetTypeOrClassItem[]
  assetID: string
  assetName: string[]
  assetStatus: AssetTypeOrClassItem[]
  totalResults: number
  showAllAsset: boolean
  [key: string]: any
}

interface AssetProps {
  onClick?: (e: any) => any
  selectMode?: any
  location?: any
  selectCreateScheduleMode?: any
  selectScheduleMode?: any
  heading?: boolean
  hideAssetMapButton?: any
  myhttpclient?: any
  aadHttpClient?: any
  selectItem?: ((arg1: any) => void) | null
  selectWO?: boolean
  onAssetsSelected?: any
  customSelectRows?: boolean
  onSelectionChanged?: any;
  customToolbarButtonText?: string;
  toolbarItemsVisible?: boolean;
}

interface WOProps {
  role?: any
  Department?: any
  currentUser?:any
  pageListItems?:any
  isContractor?:any
  team?:any
}

const Assets = (props: AssetProps & WOProps) => {
  const [items, setItems] = useState(_items)
  const [resultText, setResultText] = useState(searchResultsText)
  const [assetClass, setAssetClass] = useState([])

  // columns as asset Columns
  const [assetColumns, setAssetColumns] = useState(columns)
  const [activeRadioTab, setActiveRadioTab] = useState(1)
  const [selectedAsset, setSelectedAsset] = useState([] as any)
  const [selectedAssets, setSelectedAssets] = useState([] as any)

  //const [showAllAsset, setshowAllAsset] = useState(false)
  //const [totalResults, setTotalResults] = useState(100)
  const [asset, setAsset] = useState('')
  const [assetNumber, setAssetNumber] = useState(columns)
  const [disabledAT, setDisabledAT] = useState<boolean>(true)
  const [disabledSC, setDisabledSC] = useState<boolean>(true)
  const [criteriaSelected, setCriteriaSelected] = useState(false)
  const [assetSearchCount, setAssetSearchCount] = useState(0)
  const [itemSelected, setItemSelected] = useState('')

  const [searchPath, setSearchPath] = useState('')

  //const [assetStatus, setAssetStatus] = useState({})
  const [naturalSearch, setNaturalSearch] = useState('')
  const [selectedTab, setSelectedTab] = useState(selectedtab)
  const [asseticAssetId, setAsseticAssetId] = useState('')
  // const [assetID, setAssetID] = useState('')
  // const [assetName, setAssetName] = useState('')
  const [assetdesc, setAssetdesc] = useState('')

  //const [assetTypeItem, setAssetTypeItem] = useState([] as any)

  const [selectMode, setSelectMode] = useState('')
  const [selectScheduleMode, setSelectScheduleMode] = useState('')
  const [selectCreateScheduleMode, setSelectCreateScheduleMode] = useState('')

  const [iFramePanelOpened, setIFramePanelOpened] = useState(false)
  const [selectModeMap, setSelectModeMap] = useState(false)
  const [selectParent, setSelectParent] = useState(true)
  const [selectMapDetail, setSelectMapDetail] = useState(false)
  const [iFramePanelOpenedforDetail, setIFramePanelOpenedforDetail] = useState(false)
  const [parentId, setParentId] = useState(null)
  const [parentDetail, setParentDetail] = useState('')
  const [selectAssets, setSelectAssets] = useState<any[]>([])

  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingResult, setLoadingResult] = useState(false)
  const [loadingFields, setLoadingFields] = useState(false)
  const [callAfterInit, setCallAfterInit] = useState(true)
  const [moreItem, setMoreItem] = useState([] as any)
  const [showMoreAlert, setShowMoreAlert] = useState(false)
  const [morealertMessage, setMorealertMessage] = useState('')
  const [state, setState] = useState<stateComponent>({
    assetClassItem: [],
    assetSubClassItem: [],
    assetTypeItem: [],
    subAssetTypeItem: [],
    assetID: '',
    assetName: [],
    assetStatus: [],
    totalResults: +totalResultsList[0].text,
    showAllAsset: false
  })

  // Asset Status Items
  const [assetClassItems, setAssetClassItems] = useState<AssetTypeOrClassItem[]>([])
  const [assetStatusItems, setAssetStatusItems] = useState<AssetStatusItem[]>([])
  const [allAssetStatusItems, setAllAssetStatusItems] = useState<AssetStatusItem[]>([])

  // Asset Type and class Items
  const [allAssetSubClassItems, setAllAssetSubClassItems] = useState<AssetTypeOrClassItem[]>([])

  //const [assetSubClassItem, setAssetSubClassItem] = useState<AssetTypeOrClassItem[]>([])
  //const [assetClassItem, setAssetClassItem] = useState<AssetTypeOrClassItem[]>([])
  const [assetSubClassItems, setAssetSubClassItems] = useState<AssetTypeOrClassItem[]>([])
  const [assetType, setAssetType] = useState<AssetTypeOrClassItem[]>([])
  const [subAssetType, setSubAssetType] = useState<AssetTypeOrClassItem[]>([])
  const [allsubAssetType, setAllSubAssetType] = useState<AssetTypeOrClassItem[]>([])
  const [allAssetType, setAllAssetType] = useState<AssetTypeOrClassItem[]>([])
  const [allAssetClassItems, setAllAssetClassItems] = useState<AssetTypeOrClassItem[]>([])

  useEffect(() => {
    loadState()
    
  }, [])

  const getMultiselectProps = (data: any, listData: any) => {
    const returnData: Array<any> = []
    if (listData && data.length > 0) {
      data.forEach((item: any) => {
        const tempItem = listData.find((l: any) => l.id === item.id)
        returnData.push(tempItem)
      })
    }

    return returnData
  }

  const getAssetStatusData = async () => {
    try {
      const data: any = await utility.genericGetAPICall(Config.assetStatus)
      let AssetStatus = []
      AssetStatus = data
        .filter((r: any) => r.is_active === true)
        .map((r: any) => ({ id: r.id, text: r.status, 'Select All': r.status }))
      const sortedAssetStaus = utility._sortItems(AssetStatus, 'status', false)
      setAssetStatusItems(sortedAssetStaus)
      setAllAssetStatusItems(sortedAssetStaus)
    } catch (err) {
      console.log(err)
    }
  }

  const getAssetTypesData = () => {
    utility
      .genericGetAPICall(Config.assetType)
      .then((data: any) => {
        let AssetType = []
        let AssetSubType = []
        AssetType = data
          .filter((r: any) => r.is_active === true && r.type === 'T')
          .map((r: any) => ({
            id: r.id,
            text: r.title,
            'Select All': r.title,
            type: r.type,
            level: r.level
          }))
        const sortedAssetType = utility._sortItems(AssetType, 'text', false)
        AssetSubType = data
          .filter((r: any) => r.is_active === true && r.type === 'ST')
          .map((r: any) => ({
            id: r.id,
            text: r.title,
            'Select All': r.title,
            type: r.type,
            level: r.level
          }))
        const sortedAssetSubType = utility._sortItems(AssetSubType, 'text', false)
        setAssetType(sortedAssetType)
        setAllAssetType(sortedAssetType)
        setSubAssetType(sortedAssetSubType)
        setAllSubAssetType(sortedAssetSubType)
        setDisabledAT(true)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getAssetClassData = () => {
    utility
      .genericGetAPICall(Config.assetClass)
      .then((data: any) => {
        let Assetclasses = []
        let Assetsubclasses = []
        Assetclasses = data
          .filter((r: any) => r.is_active === true && r.class_type === 'C')
          .map((r: any) => ({
            id: r.id,
            text: r.title,
            'Select All': r.title,
            class_type: r.class_type,
            level: r.level
          }))
        const sortedAssetclasses = utility._sortItems(Assetclasses, 'text', false)
        Assetsubclasses = data
          .filter((r: any) => r.is_active === true && r.class_type === 'SC')
          .map((r: any) => ({
            id: r.id,
            text: r.title,
            'Select All': r.title,
            class_type: r.class_type,
            level: r.level
          }))
        const sortedAssetsubclasses = utility._sortItems(Assetsubclasses, 'text', false)

        setAssetClassItems(sortedAssetclasses)
        setAllAssetClassItems(sortedAssetclasses)
        setAssetSubClassItems(sortedAssetsubclasses)
        setAllAssetSubClassItems(sortedAssetsubclasses)
        setDisabledSC(true)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const multiFieldChanged = (event: any, fieldName: string) => {
    setState(prevState => ({
      ...prevState,
      [fieldName]: event
    }))
  }

  const clearFilter = () => {
    setItems('')
    setAsset('')
    setSearchPath('')
    setState(prevState => ({ ...prevState, assetStatus: [] }))
    setSubAssetType([])
    setNaturalSearch('')
    setState(prevState => ({ ...prevState, assetTypeItem: [] }))
    setState(prevState => ({ ...prevState, assetClassItem: [] }))
    setAssetdesc('')
    setAssetSubClassItems([])
    setState(prevState => ({ ...prevState, totalResults: +totalResultsList[0].text, assetID: '' , assetName: []}))
    _items = []
    localStorage.removeItem('assetState')
    setResultText(resultText)
    setDisabledAT(true)
    setDisabledSC(true)
  }

  const alert = () => {
    return (
      <AlertDialog
        showDialog={showAlert}
        message={alertMessage}
        title={Config.productTitle}
        onClose={() => {
          setAlertMessage('')
          setShowAlert(false)
        }}
      />
    )
  }

  const loadState = () => {
    const tempstate = localStorage.getItem('assetState')
    setLoadingFields(true)

    const fetchData = async () => {
      try {
        await getAssetStatusData()
        await getAssetTypesData()
        await getAssetClassData()

        console.log('---all async/await done---')

        setLoadingFields(false)
        if (tempstate) {
          const allState = JSON.parse(tempstate)

          setAsset(allState.asset ? allState.asset : '')
          setSearchPath(allState.SearchPath ? allState.SearchPath : '')
          setNaturalSearch(allState.naturalSearch ? allState.naturalSearch : '')
          setSelectedTab(allState.selectedTab ? allState.selectedTab : 0)
          setAsseticAssetId(allState.AsseticAssetId ? allState.AsseticAssetId : '')

          //setAssetID(allState.AssetID ? allState.AssetID : '')
          setAssetdesc(allState.assetdesc ? allState.assetdesc : '')
          setState(prevState => ({
            ...prevState,
            assetClassItem: allState.assetStatus ? getMultiselectProps(allState.assetStatus, 'AssetStatusItems') : []
          }))

          setState(prevState => ({
            ...prevState,
            assetClassItem: allState.AssetClassItem
              ? getMultiselectProps(allState.AssetClassItem, 'AssetClassItems')
              : []
          }))
          setState(prevState => ({
            ...prevState,
            assetSubClassItem: allState.AssetSubClassItem
              ? getMultiselectProps(allState.AssetSubClassItem, 'AssetSubClassItems')
              : []
          }))
          setState(prevState => ({
            ...prevState,
            assetTypeItem: allState.AssetTypeItem ? getMultiselectProps(allState.AssetTypeItem, 'AssetType') : []
          }))
          setState(prevState => ({
            ...prevState,
            subAssetTypeItem: allState.subAssetTypeItem
              ? getMultiselectProps(allState.subAssetTypeItem, 'subAssetType')
              : []
          }))
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }

    fetchData()
  }

  const getAssetwithcriteria = () => {
    let criteriaSelected = false
    setResultText('')
    const dataToPassToService: any = {}
    if (state.assetName && state.assetName.length > 0) {
      dataToPassToService.title = state.assetName
      criteriaSelected = true
    }
    if (state.assetID && state.assetID.length > 0) {
      dataToPassToService.id = [state.assetID]
      criteriaSelected = true
    }
    if (state.assetStatus && state.assetStatus.length > 0) {
      //dataToPassToService.assetStatus = assetStatus.text
      const tempAssetStatus: any = []
      state.assetStatus.forEach((element: any) => {
        tempAssetStatus.push(+element.id)
      })
      dataToPassToService.asset_status = tempAssetStatus
      criteriaSelected = true
    }
    if (state.subAssetTypeItem && Object.keys(state.subAssetTypeItem).length > 0) {
      const tempsubAssetTyp: any = []
      state.subAssetTypeItem.forEach((element: any) => {
        tempsubAssetTyp.push(+element.id)
      })
      dataToPassToService.sub_type_id = tempsubAssetTyp
      criteriaSelected = true
    }
    if (state.assetTypeItem && state.assetTypeItem.length > 0) {
      const tempAssetType: any = []
      state.assetTypeItem.forEach((element: any) => {
        tempAssetType.push(+element.id)
      })
      dataToPassToService.type_id = tempAssetType
      criteriaSelected = true
    }
    if (state.assetSubClassItem && state.assetSubClassItem.length > 0) {
      const tempAssetSubClass: any = []
      state.assetSubClassItem.forEach((element: any) => {
        tempAssetSubClass.push(+element.id)
      })
      dataToPassToService.sub_class_id = tempAssetSubClass
      criteriaSelected = true
    }
    if (state.assetClassItem && state.assetClassItem.length > 0) {
      const tempAssetClass: any = []
      state.assetClassItem.forEach((element: any) => {
        tempAssetClass.push(+element.id)
      })
      dataToPassToService.class_id = tempAssetClass
      criteriaSelected = true
    }
    if (!criteriaSelected) {
      setAlertMessage('Please select search criteria')
      setShowAlert(true)

      return
    }

    setLoading(true)

    const skip = 0
    const limit = !state.showAllAsset ? `limit=${state.totalResults}` : ``
    const searchAssetAPI = `${Config.AssetSearchAPI}?skip=${skip}&${limit}`
    utility.genericPostAPICall(searchAssetAPI, dataToPassToService).then(
      (data: any) => {
        _items = []
        if (!data || data.length <= 0) {
          setAlertMessage('No results found for this criteria')
          setShowAlert(true)
          setItems(_items)
          setLoading(false)

          return
        }
        data.forEach((item: any) => {
          _items.push({
            //Register: item.registerTitle,
            AssetNumber: item.id,
            AssetID: item.id,
            id: item.id,
            masterAssetId: item.master_asset_id,
            parentAssetId: item.parent_asset_id,
            AssetType: item.asset_type?.title,
            AssetTypeId: item.asset_type?.id,
            AssetClass: item.asset_class?.title,
            AssetName: item.title,
            erpAssetId: item.erp_asset_id,
            erpExtAssetId: item.erp_asset_id !== null ? item.erp_asset_id : item.id,
            erpExtAssetName: item.erpExtAssetName,
            AssetStatus: item.asset_status ? item.asset_status.status : '',
            Description: item.description,
            Location: item.asset_location,
            maintenanceAssetType: item.maintenance_asset_type,
            Category: item.asset_category?.title,
            assetCriticality: item.asset_criticality,
            departmentTitle: item.asset_team ? item.asset_team.department.title : '',
            assetsubclass: item.asset_sub_class?.title,
            teamTitle: item.asset_team ? item.asset_team.title : '',
            typeTitle: item.asset_type.title,
            subtypeTitle: item.asset_sub_type?.title,
            Selected: false,
            accntSystemId: item.accnt_system_id,
            workGroupIds: item.work_group_ids,
            coordinates: item.coordinates ? item.coordinates : { lat: 0, lng: 0 }
          })
        })

        if (_items.length <= 0) {
          setAlertMessage('No results found for this criteria')
          setShowAlert(true)
        }

        const tempSelectedAssets = _items.filter((a: any) => a.Selected === true)
        setItems(_items)
        setLoading(false)
        setCallAfterInit(true)
        setItemSelected(tempSelectedAssets.length)

        const stateData = {
          items,
          loading,
          callAfterInit,
          itemSelected
        }
        localStorage.setItem('assetState', JSON.stringify(stateData))

        //console.log(_items);
      },
      err => {
        console.log(err)
        setLoading(false)
      }
    )
  }

  const handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      getAssetwithcriteria()
    }
  }

  const handleRadioTabsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveRadioTab(parseInt(event.target.value))
  }

  const handleNaturalValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNaturalSearch(event.target.value)
  }

  const handleValueChange = (e: any, fieldName: string) => {
    if (fieldName === 'totalResults') {
      setState(prevState => ({
        ...prevState,
        [fieldName]: +e.itemData.text
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        [fieldName]: e.value
      }))
    }
  }

  const handleKeyDownforsearch = (e: any) => {
    if (e.key === 'Enter') {
      getNaturalSearchData()
    }
  }

  const stopCommsAfterInit = (): void => {
    setCallAfterInit(false)
  }

  const handleSelectItem = (i: any) => {
    const item = i
    if (props.selectMode && props.onClick) {
      props.onClick(item)

      return
    }
    if (props.selectScheduleMode && props.onClick) {
      props.onClick(item)

      return
    }
  }

  const selectButton = () => {
    const selectAssetfromItems = items
    const tempSelectedAssets = selectAssetfromItems.filter((a: any) => a.Selected === true)
    if(props.onClick){
    props.onClick(tempSelectedAssets)
    }
  }

  const SearchMap = () => {
    setIFramePanelOpened(true)
    setSelectModeMap(false)
    setSelectParent(true)
  }

  const searchClicked = () => {
    setIFramePanelOpened(true)
    setSelectModeMap(false)
    setSelectParent(true)
    setSelectMapDetail(false)
  }

  const renderAssetMapforDetail = () => {
    return (
      <AssetMapforDetail
        iFramePanelOpenedforDetail={iFramePanelOpenedforDetail}
        onClose={() => onDismissMapforDetail()}
        selectParent={selectParent}
        selectModeMap={selectModeMap}
      />
    )
  }

  const onDismissMapforDetail = () => {
    setIFramePanelOpenedforDetail(false)
  }

  const renderAssetMap = () => {
    return (
      <AssetMap
        selectAssetsforParent={(Asset: any) => selectAssetsonMapforParent(Asset)}
        iFramePanelOpened={iFramePanelOpened}
        onClose={onDismissMap}
        selectParent={selectParent}
        selectModeMap={selectModeMap}
      />
    )
  }

  const onDismissMap = () => {
    setIFramePanelOpened(false)
  }

  const selectAssetsonMapforParent = (selectedAsset: any) => {
    // const Asset = selectedAsset[0]
    // const properties = JSON.parse(Asset.properties)
    // setParentId(Asset.id ? Asset.id : properties.ASSET_ID)
    // setParentDetail(Asset.name)
    // setIFramePanelOpened(false)
    // setSelectModeMap(false)
    // setSelectParent(false)
    // setSelectMapDetail(false)
  }

  const getNaturalSearchData = () => {
    if (!naturalSearch) {
      setAlertMessage('Please enter your search')
      setShowAlert(true)

      return
    }
    const utterance = naturalSearch.replace('&', '\\0026')
    const naturalSearchCriteria = `AssetSearchIntent?utterance=${utterance}`
    setLoading(true)
    setLoadingResult(true)

    utility
      .genericGetAPICall(naturalSearchCriteria)
      .then((resultRequests: any) => {
        if (resultRequests) {
          getAssetwithNaturalSearch(resultRequests)
        }
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
        setLoadingResult(false)
        setAlertMessage('Utterance is not related to Search Asset')
        setShowAlert(true)
        setAssetSearchCount(0)
      })
  }

  const getAssetwithNaturalSearch = (assetCriteria: any) => {
    let unitType = JSON.parse(assetCriteria.UnitType)
    unitType = unitType && unitType.join().replace('&', '\\0026')
    const pageNumber = 1
    const pageSize = state.totalResults ? state.totalResults : 200
    const assetsCriteia = `/assets/Natural?unitType=${unitType}&site=${assetCriteria.Site}&addressLatLong=&distanceUnit=${assetCriteria.DistanceUnit}&distanceValue=${assetCriteria.DistanceValue}&PageNumber=${pageNumber}&PageSize=${pageSize}&showAll=${state.showAllAsset}`

    setLoading(true)

    utility.genericGetAPICall(assetsCriteia).then(
      (assetItems: any) => {
        _items = []
        if (assetItems.length === 0) {
          setLoading(false)
          setLoadingResult(false)
          setAlertMessage('No results found for this search')
          setShowAlert(true)
          setAssetSearchCount(0)
          items(_items)

          return
        } else {
          assetItems.forEach((item: any) => {
            _items.push({
              Register: item.registerTitle,
              AssetID: item.erpExtAssetId,
              AssetNumber: item.id,
              id: item.id,
              masterAssetId: item.masterAssetId,
              parentAssetId: item.masterAssetId,
              AssetType: item.typeTitle,
              AssetTypeId: item.typeId,
              AssetClass: item.classTitle,
              AssetName: item.title,
              AssetStatus: item.asset_status ? item.asset_status.status : '',
              Description: item.description,
              Location: item.locationDescription,
              maintenanceAssetType: item.maintenanceAssetType,
              Category: item.assetCategory,
              assetCriticality: item.assetCriticality,
              departmentTitle: item.departmentTitle,
              assetsubclass: item.subclassTitle,
              teamTitle: item.teamTitle,
              typeTitle: item.typeTitle,
              subtypeTitle: item.subtypeTitle,
              Selected: false
            })
          })

          const stateData = {
            items: _items,
            loading: false,
            loadingResult: false,
            callAfterInit: true,
            assetSearchCount: assetItems.length
          }
          localStorage.setItem('assetState', JSON.stringify(stateData))

          // window.scrollTo(0, myRef.current.offsetTop);
        }
      },
      (err: any) => {
        console.log(err)
        setLoading(false)
        setLoadingResult(false)
        setAlertMessage('No result found. Please try again with different search')
        setShowAlert(true)
        setAssetSearchCount(0)
      }
    )
  }

  // function a11yProps(index: string) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`
  //   }
  // }

  const handleTotalResultsButtonClick = (e: any) => {
    setState(prevState => ({
      ...prevState,
      totalResults: +e.itemData.text
    }))
  }

  useEffect(() => {
    if (state.assetClassItem) {
      let filteredSCitems: any
      state.assetClassItem.forEach((element: any) => {
        filteredSCitems = allAssetSubClassItems.filter(
          (item: any) => item.class_type === 'SC' && item.level.includes(element.level)
        )
      })

      setAssetSubClassItems(filteredSCitems)
      setDisabledSC(false)
      setDisabledAT(true)
    }

    if (state.assetTypeItem) {
      let filteredATitems: any
      state.assetTypeItem.forEach((element: any) => {
        filteredATitems = allsubAssetType.filter(
          (item: any) => item.type === 'ST' && item.level.includes(element.level)
        )
      })

      setSubAssetType(filteredATitems)
      setDisabledAT(false)
    }
    
  }, [state])

  const handleDropdownChange = (e: any, fieldName: string) => {
    const chosenValue = e.selectedItem
    if (chosenValue !== state[fieldName]) {
      setState(prevState => ({
        ...prevState,
        [fieldName]: chosenValue
      }))
    }
  }

  const NaturalSearchContent = () => {
    return (
      <TabPanel value={activeRadioTab} index={0}>
        <div className=''>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={10}>
              <div className='naturalSearchBar'>
                <OutlinedInput
                  fullWidth
                  className='searchbar'
                  id='search-bar'
                  aria-describedby='search-bar'
                  value={naturalSearch}
                  onChange={handleNaturalValueChange}
                  // onKeyDown={(e: any) => handleKeyDownforsearch(e)}
                  placeholder={`find all 'Type of Asset' in 'Suburb'/'Town'/'State'/'Complete Address'`}
                  endAdornment={
                    <InputAdornment position='end'>
                      {/* <Icon>mdi:magnify</Icon> */}
                    </InputAdornment>
                  }
                />

                {/* <SearchIcon className='searchIcon' /> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={2}>
              <div className='actions-container'>
                {/* {state.totalRecords && <div className="totalRecordsDiv">Total records available for this search is {state.totalRecords}</div>} */}
                <div className='buttongroups-container displayFlex'>
                  <CheckBoxInput
                    value={state.showAllAsset}
                    onValueChanged={(e: any) => handleValueChange(e, 'showAllAsset')}
                    text={'Show All Assets'}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className='action-buttons'>
                <Button onClick={clearFilter} variant='outlined'>
                  Clear
                </Button>
                <Button onClick={getNaturalSearchData} variant='outlined' color='primary' className='primary-button'>
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </TabPanel>
    )
  }

  const AdvancedSearchContent = () => {
    return (
      <TabPanel value={activeRadioTab} index={1}>
        {showAlert && alert()}
        <div className={'searchFieldDiv '}>
          <div className='search-fields'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel shrink className='label bold'>
                  Asset Class
                </InputLabel>
                {/* <SelectBoxInput
                  stylingMode='outlined'
                  displayExpr='text'
                  valueExpr='id'
                  showClearButton={true}
                  items={assetClassItems}
                  // label='Asset Class'
                  selectedItem={state.assetClassItem}
                  onSelectionChanged={(e: any) => handleDropdownChange(e, 'assetClassItem')}
                /> */}
                <MultiSelect
                  items={assetClassItems}
                  onChange={(e: any) => multiFieldChanged(e, 'assetClassItem')}
                  selectedItem={state.assetClassItem}
                  stylingMode='outlined'
                  displayExpr='text'
                  valueExpr='id'
                  showClearButton={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel shrink className='label bold'>
                  Asset Subclass
                </InputLabel>
                <MultiSelect
                  items={assetSubClassItems}
                  onChange={(e: any) => multiFieldChanged(e, 'assetSubClassItem')}
                  selectedItem={state.assetSubClassItem}
                  //label='Asset Subclass'
                  stylingMode='outlined'
                  displayExpr='text'
                  showClearButton={true}
                  //placeholder='Select a value...'
                  disabled={disabledSC}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel shrink className='label bold'>
                  Asset Type
                </InputLabel>
                {/* <SelectBoxInput
                  stylingMode='outlined'
                  displayExpr='text'
                  valueExpr='id'
                  showClearButton={true}
                  items={assetType}
                  selectedItem={state.assetTypeItem}
                  //label='Asset Type'
                  onSelectionChanged={(e: any) => handleDropdownChange(e, 'assetTypeItem')}
                /> */}
                <MultiSelect
                  items={assetType}
                  onChange={(e: any) => multiFieldChanged(e, 'assetTypeItem')}
                  selectedItem={state.assetTypeItem}
                  stylingMode='outlined'
                  displayExpr='text'
                  valueExpr='id'
                  showClearButton={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel shrink className='label bold'>
                  Asset SubType
                </InputLabel>
                <MultiSelect
                  items={subAssetType}
                  onChange={(e: any) => multiFieldChanged(e, 'subAssetTypeItem')}
                  selectedItem={state.subAssetTypeItem}
                  //label='Asset SubType'
                  stylingMode='outlined'
                  displayExpr='text'
                  showClearButton={true}
                  disabled={disabledAT}

                  //placeholder='Select a value...'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel shrink className='label bold'>
                  Asset ID
                </InputLabel>
                <TextBoxInput
                  stylingMode='outlined'
                  //label='Asset ID'
                  // pattern={"[A-Za-z]+"}
                  // minLength={2}
                  value={state.assetID}
                  showClearButton={true}
                  onValueChanged={(e: any) => handleValueChange(e, 'assetID')}
                  onKeyDown={(e: any) => handleKeyDown(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel shrink className='label bold'>
                  Asset Name
                </InputLabel>
                <TextBoxInput
                  stylingMode='outlined'
                  //label='Asset Name'
                  value={state.assetName[0]}
                  showClearButton={true}
                  onValueChanged={(e: any) => handleValueChange(e, 'assetName')}
                  onKeyDown={(e: any) => handleKeyDown(e)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <InputLabel shrink className='label bold'>
                  Status
                </InputLabel>

                <MultiSelect
                  items={assetStatusItems}
                  onChange={(e: any) => multiFieldChanged(e, 'assetStatus')}
                  selectedItem={state.assetStatus}
                  stylingMode='outlined'
                  displayExpr='text'
                  showClearButton={true}
                />
              </Grid>
            </Grid>
          </div>
          <div className='actions-container'>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                {/* {state.totalRecords && <div className="totalRecordsDiv">Total records available for this search is {state.totalRecords}</div>} */}
                <div className='buttongroups-container displayFlex'>
                  <CheckBoxInput
                    value={state.showAllAsset}
                    onValueChanged={(e: any) => handleValueChange(e, 'showAllAsset')}
                    text={'Show All Assets'}
                  />

                  <span className='show-results bold'>show: </span>
                  <ButtonGroupItem
                    items={totalResultsList}
                    keyExpr='text'
                    stylingMode='text'
                    selectedItemKeys={[state.totalResults.toString()]}
                    onItemClick={(e: any) => handleValueChange(e, 'totalResults')}
                  />

                  {/* <NumberBoxInput
                    stylingMode='underlined'
                    label='Total Results to Display'
                    min={1}
                    value={state.totalResults}
                    onValueChanged={(e: any) => handleValueChange(e, 'totalResults')}
                  /> */}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className='action-buttons'>
                  <Button onClick={clearFilter} variant='outlined'>
                    Clear
                  </Button>
                  <Button onClick={getAssetwithcriteria} variant='outlined' color='primary' className='primary-button'>
                    Search
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </TabPanel>
    )
  }

  const DatatableContent = () => {
    return (
      <>
        {!loading && (
          <div className='test-datatable'>
            {items && items.length > 0 && activeRadioTab !== 2 && (
              <DataTable
                //style={{ width: '100%' }}
                filteralbe={true}
                groupable={true}
                dataSource={items}
                columns={assetColumns}
                fileName={'Asstes'}
                columnChooser={'assetColumns'}
                selectMode={props.selectMode}
                showHideColumnMenu={true}
                callAfterInit={callAfterInit}
                stopCommsAfterInit={() => stopCommsAfterInit()}
                //loading={loading}
                allowExportSelectedData={false}
                showNewBtn={false}
                showCommandBtn={false}
                showViewDetailBtn={false}
              />
            )}
          </div>
        )}
        {loading && <Spinner size='large' label={'Loading..'} className='loader' />}
      </>
    )
  }

  const MapSearchContent = () => {
    return (
      <>
        {/* {renderAssetMap()} */}
        {!props.selectCreateScheduleMode && !props.selectScheduleMode && (
          <TabPanel value={activeRadioTab} index={2}>
            <div className='map-search'>
              {AssetMapforDetail && (
                <AssetMapforDetail
                  selectMode={props.selectMode}
                  selectAssets={(e: any) => props.onClick && props.onClick(e)}
                  location={props.location}
                />
              )}
            </div>
          </TabPanel>
        )}
      </>
    )
  }

  const onAssetsSelected = ((rowData: any) => {
    if(rowData && rowData.length > 0){
      let selectedItemsToAdd:any = [];
      rowData.forEach((element: any) => {
          selectedItemsToAdd.push(element);
      });
      props.onAssetsSelected(selectedItemsToAdd);
    }
  });

  return (
    <>
      <div className='ui-container'>
        {/* <FormLabel  className='bold'>
          Type Of Search
        </FormLabel> */}
        <h3 id='asset-search-types'>Type of Search</h3>
        <RadioGroup row value={activeRadioTab} onChange={handleRadioTabsChange}>
        <FormControlLabel value='0' control={<Radio />} label='Natural Search' className='bold' />
        <FormControlLabel value='1' control={<Radio />} label='Advanced Search' className='bold' />
        {(!props.selectWO && props.selectWO) && <FormControlLabel value='2' control={<Radio />} label='Map Search' className='bold' />}
      </RadioGroup>

      <Typography component='div'>
        {activeRadioTab === 0 && <NaturalSearchContent />}
        {activeRadioTab === 1 && <AdvancedSearchContent />}
        {activeRadioTab === 2 && <MapSearchContent />}
      </Typography>
      </div>
      {/* <Divider sx={{ m: '0 !important' }} /> */}

      {activeRadioTab !== 2 && (
        <div className=''>
          <h2 className='pl-10'>Results</h2>
          <Typography>{resultText}</Typography>
          {!loading ? (
            <div>
              {items.length > 0 && (
                <div className='results-container'>
                  <DataTable
                    //style={{ width: '100%' }}
                    filteralbe={true}
                    groupable={true}
                    dataSource={items}
                    columns={columns}
                    fileName={'Assets'}
                    columnChooser={'assetColumns'}
                    showHideColumnMenu={true}
                    callAfterInit={callAfterInit}
                    stopCommsAfterInit={stopCommsAfterInit}
                    loading={loading}
                    allowExportSelectedData={false}
                    showNewBtn={false}
                    selectionMode={props.selectWO ? 'multiple' : false}
                    // viewItem={(a:any) => viewDetials(a)}
                    // showNewModal={() => _showModal()}
                    // deleteItem={(a:any) => DeleteDialog(a)}
                    // editItem={(a:any) => EditItemModal(a)}
                    showCommandBtn={false}
                    showViewDetailBtn={false}
                    selectItem={handleSelectItem}
                    selectMode={props.selectMode}
                    selectScheduleMode={props.selectScheduleMode}
                    selectCreateScheduleMode={props.selectCreateScheduleMode}
                    selectedRowKeys={props.selectWO ? state.selectedItemKeys : ''}
                    onSelectionChanged={props.selectWO ? onAssetsSelected : undefined}
                    customSelectRows={props.selectWO ? true : false}
                    customToolbarButtonText={props.selectWO ? 'Select Asset' : ''}
                    toolbarItemsVisible={true}
                  />
                </div>
              )}
            </div>
          ) : (
            <Spinner size='large' label={'Loading..'} className='loader' />
          )}
        </div>
      )}
    </>
  )
}

export default Assets

