import React, { useState, useEffect } from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../Common/CustomASDataGrid';
import Spinner from '../../Common/ReusableComponents/Spinner';
import global from '../../../classes/global';
import CustomStore from "devextreme/data/custom_store";
import { LoadOptions } from "devextreme/data";

interface LoadResult {
  data: any[];
  totalCount: number;
}

const Suburbs = (props: any) => {

    if (props.role !== global.worxOnline_Admin) {
      window.open('#/AccessDenied', '_self');
    }

    const [items, setItems] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const columns = [
      {
        field: 'id',
        title: 'ID',
        width: 80,
        type: 'string',
        allowHiding: false,
        visibleColumn: false,
        formItem: {
          visible: false
        }
      },
      {
        field: 'title',
        title: 'Title',
        // width: 160,
        type: 'string',
        allowHiding: false,
        isRequired: true
      },
      {
        field: 'code',
        title: 'Code',
        // width: 160,
        type: 'string',
        allowHiding: true,
        isRequired: true
      },
      {
        field: 'lat',
        title: 'Latitude',
        // width: 160,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'lng',
        title: 'Longitude',
        // width: 160,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'is_active',
        title: 'Active',
        // width: 160,
        type: 'boolean',
        allowHiding: true,
      },
      {
        field: 'created',
        title: 'Created',
        width: 160,
        type: 'datetime',
        visibleColumn: false,
        allowHiding: true,
        formItem: {
          visible: false
        }
      },
      {
        field: 'created_by',
        title: 'Created By',
        width: 160,
        type: 'string',
        visibleColumn: false,
        allowHiding: true,
        formItem: {
          visible: false
        }
      },
    ];
  
    useEffect(() => {
      itemsData();
    }, [])
  
    const itemsData = async () => {
      setLoading(true);
      try {
        const itemResponse = await utility.genericGetAPICall(Config.suburblistname);
        const itemData = itemResponse.map((r: any) => ({
          ...r,
          is_active: r.is_active ? true : false
        }));
        setItems(itemData);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
  
    const dataSource = new CustomStore({
      load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
        return {
          data: items,
          totalCount: items.length
        };
      },
      key: 'id',
      onLoaded: () => {
        console.log('Data loaded');
        setLoading(false);
        setDataLoaded(true);
      },
      insert: async (values: any) => {
        const { lat, lng, ...r } = values;
        const lat_long = `${lat},${lng}`;
        try {
          await utility.genericPostAPICall(Config.suburblistname, {...r, lat_long});
          await itemsData();
        } catch (error:any) {
          if (error instanceof Error && error.message.startsWith('Network response was not ok')) {
            try {
              const errorData = JSON.parse(error.message.replace('Network response was not ok: ', ''));
              const errorMessage = utility.extractErrorMessage(errorData);
              throw new Error(errorMessage);
            } catch (parseError) {
              throw new Error('Failed to process error response.');
            }
          } else {
            throw new Error('Failed to post data.');
          }
      
        }
      },
      update: async (key: any, values: any) => {
        const { lat, lng, ...r } = values;
        const lat_long = `${lat},${lng}`;
        try {
          await utility.genericUpdatePatchAPICall(Config.suburblistname, key, {...r, lat_long});
          await itemsData();
        } catch (error:any) {
          throw error;
        }
      },
      remove: async (key: any) => {
        try {
          await utility.genericDeleteAPICall(Config.suburblistname, key);
          await itemsData();
        } catch (error:any) {
          throw error;
        }
      }
    });
  
    return (
      <>
        <div>
          {!loading ? (
            <div className='results-container'>
              <DataTable
                dataSource={dataSource}
                style={{ width: '100%' }}
                filteralbe={true}
                groupable={true}
                columns={columns}
                fileName={'Suburbs'}
                columnChooser={'ssuburbColumns'}
                selectionMode={false}
                showHideColumnMenu={true}
                loading={loading}
                allowExportSelectedData={false}
                columnHidingEnabled={true}
                showCommandBtn={false}
                hideSearchPanel={false}
                hideCommandDeleteBtn={false}
                showNewBtn={false}
                showViewDetailBtn={false}
                hideNewBtn={true}
                //defaultfiltervalue={filterValue}
                showMoreBtn={true}
                visible={dataLoaded}
              />
            </div>
          ) : (
            <Spinner size='large' className='loader' />
          )}
        </div>
      </>
    );
  };
  

 export default Suburbs;