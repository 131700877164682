import React, { useEffect, useRef, useState } from 'react';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, { Selection, FilterRow, Scrolling } from 'devextreme-react/data-grid';

const gridColumns = ['Select All'];

interface Props {
  label?: string;
  stylingMode: string | any;
  placeholder?: string | any;
  displayExpr: string | any;
  valueExpr?: string | any;
  grouped?: Boolean | any;
  selectedItem: any;
  showClearButton?: Boolean | any;
  readOnly?: Boolean | any;
  disabled?: Boolean | any;
  onChange: any;
  required?: any;
  items: any;
  spellcheck?: Boolean | any;
}

const MultiSelect: React.FC<Props> = (props) => {
  const [items, setItems] = useState<any>(props.items);
  const [selectedItem, setSelectedItem] = useState<any>(props.selectedItem);
  const dataGridRef = useRef<any>(null);

  useEffect(() => {
    if (
      (props.items && !items) ||
      ((props.items && props.items.length) !== (items && items.length))
    ) {
      setItems(props.items);
    }
    if (
      (props.selectedItem && !selectedItem) ||
      ((props.selectedItem && props.selectedItem.length) !==
        (selectedItem && selectedItem.length))
    ) {
      setSelectedItem(props.selectedItem);
    }
  }, [props.items, props.selectedItem, items, selectedItem]);

  const dataGridRender = (items: any) => {
    return (
      <DataGrid
        height={430}
        dataSource={items}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={selectedItem}
        onSelectionChanged={dataGridOnSelectionChanged}
      >
        <Selection mode="multiple" />
        <Scrolling mode="virtual" />
        {/* <SearchPanel visible={true} width={'100%'} /> */}
        {/* <Paging enabled={true} pageSize={10} /> */}
        <FilterRow visible={true} />
      </DataGrid>
    );
  };

  const dataGridOnSelectionChanged = (e: any) => {
    setSelectedItem(e.selectedRowKeys.length && e.selectedRowKeys || []);
    let gridBoxValue = (e.selectedRowKeys.length && e.selectedRowKeys) || [];
    props.onChange(gridBoxValue);
  };

  const syncDataGridSelection = (e: any) => {
    setSelectedItem(e.value || []);
    let gridBoxValue = e.value || [];
    props.onChange(gridBoxValue);
  };

  return (
    <DropDownBox
      displayExpr={props.displayExpr}
      placeholder={props.placeholder}
      showClearButton={props.showClearButton}
      stylingMode={props.stylingMode}
      dataSource={items}
      value={selectedItem}
      onValueChanged={syncDataGridSelection}
      contentRender={() => dataGridRender(items)}
      label={props.label}
      labelMode={'floating'}
      disabled={props.disabled}
    />
  );
};

export default MultiSelect;
