import React, { useState, useRef } from 'react'
import { DataGrid } from 'devextreme-react'
import DataTable from '../../Common/CustomASDataGrid'
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'
import Spinner from '../../Common/ReusableComponents/Spinner'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'

interface Crews {
  crew_id: number
  crew_code: string
  crew_name: string
  team_id: number
  department_id: number
}

interface Dept {
  department_id: number
  department_code: string
  department_name: string
}

interface Teams {
  team_id: number
  team_code: string
  team_name: string
  department_id: number
}

interface Roles {
  role_id: number
  role_name: string
}

interface LoadResult {
  data: any[]
  totalCount: number
}

export interface User {
  first_name: string
  last_name: string
  full_name: string
  resource_code: string
  login_name: string
  email: string
  phone: string
  pin: string
  is_active: boolean
  is_contractor: boolean
  // userType: string;
  job_title: string
  absent_info: string
  photo: string
  erp_code: string
  department_id: number | null
  department_name: string
  department_code: string
  team_id: number | null
  team_name: string
  team_code: string
  crew_id: number | null
  crew_name: string
  crew_code: string
  role_id: number
  role_name: string
  // userRoleId: number;
  is_plant_operator: boolean
  reserved_by: string
  // reservedByFullName: string;
  reserved_on: Date
  id: string
  created_by: string
  // updated_by: string;
  created: Date
  // updated: Date;
  // tenantId: number;
}

const Employee: React.FC = props => {
  //const ClearStorageServiceInstance: any = new ClearStorageService();
  const dataGridRef = useRef<DataGrid>(null)
  const [loading, setLoading] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const crews: Crews[] = []
  const depts: Dept[] = []
  const teams: Teams[] = []
  const roles: Roles[] = []
  const filterValue = [['is_active', '=', true]]
  const userTypes = [
    { user_type: 'Portal',title: 'Portal'},
    {user_type: 'App', title: 'App'},
    {user_type: 'Portal & App', title: 'Portal & App'} 
  ];

  const getFilteredTeams = (options: { data?: User }) => ({
    store: teams,
    filter: options.data ? ['department_id', '=', options.data.department_id] : null
  })

  const getFilteredCrews = (options: { data?: User }) => ({
    store: crews,
    filter: options.data ? ['team_id', '=', options.data.team_id] : null
  })

  const getFilteredRoles = (options: { data?: User }) => ({
    store: roles,
    filter: options.data ? ['role_id', '=', options.data.role_id] : null
  })

  const setTeamValue = (rowData: User, value: number) => {
    rowData.team_id = value || null
    rowData.crew_id = null // Reset crew_id to null
    const team = teams.find(t => t.team_id === value)
    if (team) {
      rowData.team_name = team.team_name
      const filteredCrews = crews.filter(c => c.team_id === value)
      if (filteredCrews.length === 1) {
        rowData.crew_id = filteredCrews[0].crew_id
        rowData.crew_name = filteredCrews[0].crew_name
      }
    }
  }

  const setCrewValue = (rowData: User, value: number) => {
    rowData.crew_id = value || null
    const crew = crews.find(c => c.crew_id === value)
    if (crew) {
      rowData.crew_name = crew.crew_name
    }
  }

  const setDepartmentValue = (rowData: User, value: number) => {
    rowData.department_id = value || null
    rowData.team_id = null // Reset team_id to null
    rowData.crew_id = null // Reset crew_id to null
    const dept = depts.find(d => d.department_id === value)
    if (dept) {
      rowData.department_name = dept.department_name
      const filteredTeams = teams.filter(t => t.department_id === value)
      if (filteredTeams.length === 1) {
        rowData.team_id = filteredTeams[0].team_id
        rowData.team_name = filteredTeams[0].team_name
        const filteredCrews = crews.filter(c => c.team_id === filteredTeams[0].team_id)
        if (filteredCrews.length === 1) {
          rowData.crew_id = filteredCrews[0].crew_id
          rowData.crew_name = filteredCrews[0].crew_name
        }
      }
    }
  }

  const columns = [
    {
        field: 'first_name',
        title: 'First Name',
        type: 'string',
        allowHiding: false,
        isRequired: true,
        setCellValue: (newData: any, value: any) => {
          newData.first_name = value;
          // Update full_name whenever first_name changes
          if (newData.last_name) {
            newData.full_name = `${value} ${newData.last_name}`;
          } else {
            newData.full_name = value;
          }
        }
      },
      {
        field: 'last_name',
        title: 'Last Name',
        type: 'string',
        allowHiding: false,
        isRequired: true,
        setCellValue: (newData: any, value: any) => {
          newData.last_name = value;
          // Update full_name whenever last_name changes
          if (newData.first_name) {
            newData.full_name = `${newData.first_name} ${value}`;
          } else {
            newData.full_name = value;
          }
        }
      },
      {
        field: 'full_name',
        title: 'Full Name',
        type: 'string',
        allowHiding: false,
        isRequired: true,
        calculateCellValue: (data: any) => {
          return `${data.first_name || ''} ${data.last_name || ''}`.trim();
        }
      },
      
    {
      field: 'email',
      title: 'Email',
      // width: 160,
      type: 'string',
      allowHiding: true,
      isRequired: true
    },
    {
      field: 'login_name',
      title: 'Login Name',
      // width: 280,
      type: 'string',
      allowHiding: true,
      isRequired: true
    },
    {
      field: 'pin',
      title: 'Pin',
      // width: 280,
      type: 'string',
      allowHiding: true,
      isRequired: true,
      validationRules: [
        {
          type: 'custom',
          validationCallback: (e: any) => {
            const commonPins = ['0000', '1234', '1111', '2222', '3333', '4444', '5555', '6666', '7777', '8888', '9999'];
            return !commonPins.includes(e.value);
          },
          message: "The PIN cannot be a common sequence like '0000' or '1234'."
        }
    ]
    },
    {
      field: 'is_active',
      title: 'Active',
      // width: 280,
      type: 'boolean',
      allowHiding: true,
      calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
    },
    {
      field: 'role_id',
      title: 'Role',
      // width: 280,
      type: 'string',
      allowHiding: true,
      isRequired: true,
      Lookup: {
        dataSource: roles,
        valueExpr: 'role_id',
        displayExpr: 'role_name'
      }
    },
    {
        field: 'user_type',
        title: 'User Type',
        // width: 160,
        type: 'string',
        allowHiding: true,
        visible: false,
        isRequired: true,
        Lookup: {
          dataSource: userTypes,
          valueExpr: 'user_type',
          displayExpr: 'title'
        }
      },
    {
      field: 'department_id',
      title: 'Department',
      // width: 280,
      type: 'string',
      allowHiding: true,
      isRequired: true,
      setCellValue: setDepartmentValue,
      Lookup: {
        dataSource: depts,
        valueExpr: 'department_id',
        displayExpr: 'department_name'
      }
    },
    {
      field: 'team_id',
      title: 'Team',
      // width: 280,
      type: 'string',
      allowHiding: true,
      isRequired: true,
      setCellValue: setTeamValue,
      Lookup: {
        dataSource: getFilteredTeams,
        valueExpr: 'team_id',
        displayExpr: 'team_name'
      }
    },
    {
      field: 'crew_id',
      title: 'Crew',
      // width: 280,
      type: 'string',
      allowHiding: true,
      setCellValue: setCrewValue,
      Lookup: {
        dataSource: getFilteredCrews,
        valueExpr: 'crew_id',
        displayExpr: 'crew_name'
      }
    },
    {
      field: 'resource_code',
      title: 'Resource Code',
      // width: 160,
      type: 'string',
      allowHiding: true,
      formItem: {visible: false}
      //   visible: false,
         
    },
    {
      field: 'phone',
      title: 'Phone',
      // width: 160,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'job_title',
      title: 'Job Title',
      // width: 160,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'is_plant_operator',
      title: 'Is Plant Operator',
      // width: 160,
      type: 'boolean',
      allowHiding: true,
      calculateCellValue: (data: any) => data.is_plant_operator ?? false, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_plant_operator = value
      }
    },
  ]

  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      try {
        const deptResponse = await utility.genericGetAPICall(`${Config.DepartmentList}`)
        //const deptData = await deptResponse.load();
        const deptData = deptResponse
        deptData.forEach(({ id, code, title }: { id: number; code: string; title: string }) => {
          depts.push({ department_id: id, department_code: code, department_name: title })
        })

        const teamResponse = await utility.genericGetAPICall(`${Config.TeamList}`)
        const teamData = teamResponse
        teamData.forEach(
          ({ id, code, title, department_id }: { id: number; code: string; title: string; department_id: number }) => {
            teams.push({ team_id: id, team_code: code, team_name: title, department_id: department_id })
          }
        )

        const crewResponse = await utility.genericGetAPICall(`${Config.crewlistname}`)
        const crewData = crewResponse
        crewData.forEach(
          ({
            id,
            code,
            title,
            team
          }: {
            id: number
            code: string
            title: string
            team: { id: number; department: { id: number } }
          }) => {
            crews.push({
              crew_id: id,
              crew_code: code,
              crew_name: title,
              team_id: team.id,
              department_id: team.department.id
            })
          }
        )

        const roleResponse = await utility.genericGetAPICall(`${Config.roleList}`)
        const roleData = roleResponse
        roleData.forEach(({ id, title }: { id: number; title: string }) => {
          roles.push({ role_id: id, role_name: title })
        })

        const employeesResponse = await utility.genericGetAPICall(Config.SearchEmployeeApi)
        const employeeData = Array.isArray(employeesResponse.data) ? employeesResponse.data : employeesResponse

        const totalCount =
          employeesResponse.totalCount !== undefined ? employeesResponse.totalCount : employeeData.length

        setLoading(false)
        setDataLoaded(true)
        return {
          data: employeeData,
          totalCount: totalCount
        }
        // Combine and return the data from both API calls
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded')
      setLoading(false)
      setDataLoaded(true)
    },
    insert: async (values: any) => {
      try {
        await utility.genericPostAPIcallById(Config.CreateEmployeeAPI, `?role_id=${values.role_id}`, values)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    update: async (key: any, values: any) => {
      try {
        await utility.genericUpdatePatchAPICall(Config.UpdateEmployeeAPI, key, values)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.DeleteEmployeeAPI, key)
      } catch (error) {
        console.error(error)
        throw error
      }
    }
  })

  return (
    <>
      <div>
        {/* {!loading ? ( */}
        <div className='results-container'>
          <DataTable
            dataSource={dataSource}
            style={{ width: '100%' }}
            filteralbe={true}
            groupable={true}
            //dataSource={items}
            columns={columns}
            fileName={'Users'}
            columnChooser={'usersColumns'}
            selectionMode={false}
            showHideColumnMenu={true}
            loading={loading}
            allowExportSelectedData={false}
            columnHidingEnabled={true}
            // showNewModal={showNewModal}
            // deleteItem={openDeleteDialog}
            // editItem={EditItemModal}
            showCommandBtn={false}
            hideSearchPanel={false}
            hideCommandDeleteBtn={false}
            showNewBtn={false}
            showViewDetailBtn={false}
            hideNewBtn={true}
            defaultfiltervalue={filterValue}
            showMoreBtn={true}
            visible={dataLoaded}
          />
        </div>
        {/* ) : (
                    <Spinner size='large' className='loader' />
                )} */}
      </div>
    </>
  )
}

export default Employee
