import React, { useState, useEffect } from 'react'
import SelectBox from 'devextreme-react/select-box'

interface Props {
  label?: string
  stylingMode?: string | any
  placeholder?: string | any
  displayExpr: string | any
  valueExpr?: string | any
  grouped?: boolean | any
  selectedItem: any
  showClearButton?: boolean | any
  readOnly?: boolean | any
  disabled?: boolean | any
  onSelectionChanged: any
  required?: any
  items: any
  spellcheck?: boolean | any
  className?: string
}

const SelectInputBox: React.FC<Props> = (props: Props) => {
  const [items, setItems] = useState(props.items)
  const [selectedItem, setSelectedItem] = useState(props.selectedItem)

  useEffect(() => {
    setItems(props.items)
  }, [props.items])

  useEffect(() => {
    if (props.items && props.items.length > 0 && props.selectedItem) {
      const index = props.items.findIndex((obj: any) => obj.id === props.selectedItem.id)
      setSelectedItem(props.items[index])
    } else {
      setSelectedItem(props.selectedItem)
    }
  }, [props.items, props.selectedItem])

  return (
    <SelectBox
      dataSource={props.items}
      stylingMode={props.stylingMode}
      placeholder={props.placeholder}
      value={selectedItem}
      onSelectionChanged={props.onSelectionChanged}
      showClearButton={props.showClearButton}
      displayExpr={props.displayExpr}
      searchEnabled={true}
      searchMode={'contains'}
      searchExpr={'text'}
      label={props.label}
      labelMode={'floating'}
      grouped={props.grouped}
      readOnly={props.readOnly}
      disabled={props.disabled}
      className={props.className}
    />
  )
}

export default SelectInputBox
