import React, { useState, useEffect, useCallback } from 'react';
import global from '../../../classes/global';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

interface IAssetMapProps {
    selectModeMap?: boolean;
    selectParent?: boolean;
    selectAssets?: (assets: any) => void;
    selectAssetsforParent?: (assets: any) => void;
    coordinates?: any[];
    address?: string;
    al?: string;
    polylines?: any;
    department?: string;
    iFramePanelOpened: boolean;
    onClose: () => void;
}

const AssetMap: React.FC<IAssetMapProps> = (props) => {
    const [iFrameUrl, setIFrameUrl] = useState(global.AssetMapURL);

    const handleIframeTaskMap = useCallback((e: any) => {
        if (e.data.command === 'asset selected') {
            const assetData = e.data.assets;
            if (props.selectModeMap) {
                props.selectAssets?.(assetData);
            } else if (props.selectParent) {
                props.selectAssetsforParent?.(assetData);
            }
            props.onClose();
        }
    }, [props]);

    useEffect(() => {
        window.addEventListener('message', handleIframeTaskMap);
        return () => {
            window.removeEventListener('message', handleIframeTaskMap);
        };
    }, [handleIframeTaskMap]);

    const onIframeLoaded = useCallback(() => {
        window.addEventListener('message', handleIframeTaskMap);
    }, [handleIframeTaskMap]);

    let url = "";
    if (props.coordinates && props.coordinates.length > 0) {
        const firstCoordinate = props.coordinates[0];

        if (firstCoordinate.type === "Point") {
            const point = firstCoordinate.coordinates;
            url = `${iFrameUrl}?lat=${point[1]}&long=${point[0]}`;
        } else if (firstCoordinate.type === "MultiPolygon") {
            const multipolygonString = props.coordinates.map((coordinate: any) =>
                coordinate.coordinates.map((point: any) => `${point.lat},${point.lng}`).join(';')
            ).join(',');

            url = `${iFrameUrl}?multipolygon=${multipolygonString}`;
        }
        if (props.al) {
            url += `&al=${props.al}`;
        }
    } else if (props.address && props.al) {
        url = `${iFrameUrl}${props.address && props.address !== "" ? `?loc=${props.address}` : ''}${props.al ? `&al=${props.al}` : ''}`;
    } else if (props.address) {
        url = `${iFrameUrl}${props.address && props.address !== "" ? `?loc=${props.address}` : ''}`;
    } else {
        url = `${iFrameUrl}${props.al && props.al !== "" ? `?al=${props.al}` : ''}`;
    }

    return (
        <React.Fragment>
            <div>
                <Popup
                    width={'80vw'}
                    maxHeight={'90vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={props.polylines ? props.department : props.address}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={props.iFramePanelOpened}
                    onVisibleChange={props.onClose}
                    onShowing={onIframeLoaded}
                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                        <iframe
                            src={url}
                            allowFullScreen={true}
                            width={'100%'}
                            style={{ height: '70vh' }}
                            onLoad={onIframeLoaded}
                        />
                    </ScrollView>
                </Popup>
            </div>
        </React.Fragment>
    );
};

export default AssetMap;
