import * as React from 'react';
import utility from '../../../classes/utilityOld';
import { Config } from '../../../classes/config';
import Grid from '@mui/material/Grid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import AlertDialog from '../../Common/AlertDialog';
import Spinner from '../../Common/ReusableComponents/Spinner';
import TextBoxInput from '../../Common/ReusableComponents/TextBox';
import capitalize from '@mui/material/utils/capitalize';


export default class AddEditRole extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.updateRole = this.updateRole.bind(this);
        this.clearFilter = this.clearFilter.bind(this);

        this.state = {

        };
    }

    public componentDidMount() {
    }
    public didMount() {
        //console.log(this.props.details);
        this.setState({
            id: this.props.details.id,
            title: this.props.details.title,
        });
    }

    public updateRole() {
        //console.log(this.state.title);
        //console.log(this.props.currentUser);
        //genericPostAPIcallForAdminList
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label='Loading...' /></div>,
            hideAlertButton: true,
            showAlert: true
        });
        dataToPassToService.title = this.state.title;
        dataToPassToService.isActive = true;
        dataToPassToService.updatedBy = this.props.currentUser.Email;
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updated = new Date();
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            utility.genericUpdateAPICall(Config.roleList, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Role has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.roleList, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Role has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }

    }


    public clearFilter() {
        this.setState({ title: '' });
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }

    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.props.mode && capitalize(this.props.mode)} Role`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                        <div className='popContentDiv'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Title *"
                                        // pattern={"[A-Za-z]+"}
                                        // minLength={2}
                                        value={this.state.title}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                        onKeyDown={() => { }}
                                        required={true}
                                    />
                                </Grid>

                            </Grid>
                        </div>
                    </ScrollView>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.updateRole,
                        }}
                    />
                </Popup>
            </div>

        );
    }

}
