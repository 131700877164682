import React from 'react'
import LoadIndicator from 'devextreme-react/load-indicator'

interface Props {
  size?: any
  height?: number
  width?: number
  label?: string
  className?: string
}

const Spinner: React.FC<Props> = ({ size, height, width, label, className }) => {
  return (
    <div className='loader'>
      <LoadIndicator
        id={`${size ? size : 'medium'}-indicator`}
        height={height ? height : 40}
        width={width ? width : 40}
        className={className ? className : ''}
      />
      {label && <span>{label}</span>}
    </div>
  )
}

export default Spinner
