import React, { useState, useEffect } from 'react'
// import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from 'devextreme-react/accordion'
import SelectBox from 'devextreme-react/select-box'
import Grid from '@mui/material/Grid'
import CheckBoxInput from '../../Common/ReusableComponents/CheckBox'
import TextBoxInput from '../../Common/ReusableComponents/TextBox'
import TextAreaInput from '../../Common/ReusableComponents/TextArea'
import NumberBoxInput from '../../Common/ReusableComponents/NumberBoxInput'
import capitalize from '@mui/material/utils/capitalize'
import CancelIcon from '@mui/icons-material/Cancel'

interface CustomEditWorkTypeFieldProps {
  value: any
  onValueChanged: (value: any) => void
  workTypes: any[]
  detailColumns: any[]
  rowData: any
}

interface StateProps {
  control_name?: string
  sequence_no?: number
  parent_sequence_no?: string
  erp_field?: string
  erp_table?: string
  condition?: string
  is_required?: boolean
  is_active?: boolean
  extra_attribute?: string
  choices?: string
}

const Type: any = [
  { id: 'Asset', text: 'Asset' },
  { id: 'Choice', text: 'Choice' },
  { id: 'CheckBox', text: 'CheckBox' },
  { id: 'Date', text: 'Date' },
  { id: 'DateTime', text: 'DateTime' },
  { id: 'Heading', text: 'Heading' },
  { id: 'Number', text: 'Number' },
  { id: 'Radio', text: 'Radio' },
  { id: 'Section', text: 'Section' },
  { id: 'Sectable', text: 'Sectable' },
  { id: 'Text', text: 'Text' }
]
const triggerItemsData: any = [
  { id: 'Pre', text: 'Pre' },
  { id: 'Post', text: 'Post' },
  { id: 'Adhoc', text: 'Adhoc' }
]

const CustomEditWorkTypeField: React.FC<CustomEditWorkTypeFieldProps> = ({
  value,
  onValueChanged,
  workTypes,
  detailColumns,
  rowData
}) => {
  const [workTypeItems, setWorkTypeItems] = useState<any[]>([])
  const [allworkTypeItems, setAllWorkTypeItems] = useState([])
  const [triggerItems, setTriggerItems] = useState(triggerItemsData)
  const [allTriggerItems, setAllTriggerItems] = useState([])
  const [workTypeFieldsItems, setWorkTypeFieldsItems] = useState<any>([])
  const [allworkTypeFieldsItems, setAllWorkTypeFieldsItems] = useState<any>([])
  const [selectedWorkTypeItems, setSelectedWorkTypeItems] = useState([])
  const [selectedItems, setSelectedItems] = useState<any>([])
  const [selectedWorkTypeID, setSelectedWorkTypeID] = useState(0)
  const [title, setTitle] = useState({ id: '', text: '' })
  const [type, setType] = useState({ id: '', text: '' })
  const [controlName, setControlName] = useState('')
  const [condition, setCondition] = useState('')
  const [choices, setChoices] = useState('')
  const [hideAlertButton, setHideAlertButton] = useState(false)
  const [mode, setMode] = useState('')
  const [id, setId] = useState('')
  const [created, setCreated] = useState('')
  const [createdBy, setCreatedBy] = useState('')
  const [loadingTitle, setLoadingTitle] = useState(false)
  const [alertMessage, setAlertMessage] = useState<JSX.Element | string>('')
  const [showAlert, setShowAlert] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [DeleteAlertMessage, setDeleteAlertMessage] = useState<JSX.Element | string>('')
  const [removingWorkTypeItem, setRemovingWorkTypeItem] = useState<any>()
  const [removingWorkTypeIndex, setRemovingWorkTypeIndex] = useState<any>()
  const [WOWorkType, setWOWorkType] = useState<any>()
  const [selectedWorkType, setSelectedWorkType] = useState<any>()
  const [expanded, setExpanded] = useState<string | false>(false)
  const [state, setState] = useState<StateProps>({
    control_name: '',
    sequence_no: 0,
    parent_sequence_no: '',
    erp_field: '',
    erp_table: '',
    condition: '',
    is_required: false,
    is_active: false,
    extra_attribute: '',
    choices: ''
  })

  useEffect(() => {
    didMount()
  }, [])

  const didMount = () => {
    populateValue(rowData)
  }

  // const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //   setExpanded(isExpanded ? panel : false)
  // }

  // const handleActivityChange = (event: any) => {
  //   const selectedValue = event.target.value
  //   onValueChanged(selectedValue)
  // }

  const handleValueChange = (event: any, fieldName: string, isWorkType?: any) => {
    if (isWorkType) {
      let value: any = event.value
      //setState(prevState => ({ ...prevState, [fieldName]: event.value }))

      let selectedWorkType: any = selectedItems && selectedItems.length > 0 ? selectedItems[0] : {}
      let selectedWorkTypeID: any = selectedWorkTypeItems.findIndex(
        (wo: any) => wo.worktype_id === selectedWorkType.worktype_id
      )

      let selectedworktypeItemsData: any = [...selectedWorkTypeItems] // Make a copy to avoid direct mutation
      selectedWorkType[fieldName] = value
      if (selectedWorkTypeID >= 0) {
        selectedworktypeItemsData[selectedWorkTypeID] = selectedWorkType
      } else {
        selectedworktypeItemsData.push(selectedWorkType) // Add to array if not found
      }

      setSelectedItems(selectedWorkType ? [selectedWorkType] : [])
      setSelectedWorkTypeItems(selectedworktypeItemsData)
    } else {
      setState({ [fieldName]: event.value })

      if (workTypeFieldsItems?.some((wotype: any) => wotype.text.toLowerCase() === event.value.toLowerCase())) {
        setAlertMessage('Title already exists. Please choose another title')
      } else {
        setAlertMessage('')
      }
    }
  }

  const handleDropdownChange = (e: any, fieldName: string, isWorkType?: any) => {
    if (isWorkType) {
      let value: any = e.selectedItem
      if (fieldName === 'trigger' && e.selectedItem) {
        value = e.selectedItem.text
      }

      let selectedWorkType: any = selectedItems[0]
      let selectedWorkTypeID = selectedWorkTypeItems.findIndex((wo: any) => wo.id === selectedWorkType.id)

      let selectedWorkTypeItemsData: any = selectedWorkTypeItems
      selectedWorkType[fieldName] = value
      selectedWorkTypeItemsData[selectedWorkTypeID] = selectedWorkType

      setSelectedItems(selectedWorkType ? selectedWorkType : [])
      setSelectedWorkTypeItems(selectedWorkTypeItemsData)
    } else {
      if (fieldName === 'type') setType(e.selectedItem)
    }
  }

  const onCustomValueChange = (e: any) => {
    let item: any = e.text ? { id: e.text, text: e.text } : ''
    let workTypeFieldsItemsData: any = workTypeFieldsItems

    if (workTypeFieldsItemsData?.some((wotype: any) => wotype.text === e.text)) {
      workTypeFieldsItemsData.push(item)
    }
    setWorkTypeFieldsItems(workTypeFieldsItems)
    setTitle(e.text ? { id: e.text, text: e.text } : { id: '', text: '' })
    setLoadingTitle(true)

    setTimeout(() => setLoadingTitle(false), 100)
  }

  // const CustomItem = (data: any) => {
  //   return (
  //     <div>
  //       <Grid container spacing={3}>
  //         <Grid item xs={12} sm={6} md={4}>
  //           <SelectBox
  //             stylingMode='outlined'
  //             displayExpr='text'
  //             valueExpr='id'
  //             showClearButton={true}
  //             items={triggerItems}
  //             selectedItem={data.trigger ? { id: data.trigger, text: data.trigger } : ''}
  //             onSelectionChanged={e => handleDropdownChange(e, 'trigger', true)}
  //             label='Trigger Type *'
  //           />
  //         </Grid>

  //         <Grid item xs={12} sm={6} md={4}>
  //           <TextBoxInput
  //             stylingMode='outlined'
  //             label='Control Name *'
  //             value={data.control_name ? data.control_name : ''}
  //             onValueChanged={(e: any) => handleValueChange(e, 'control_name', true)}
  //             onKeyDown={() => {}}
  //           />
  //         </Grid>

  //         <Grid item xs={12} sm={6} md={4}>
  //           <NumberBoxInput
  //             stylingMode='outlined'
  //             label='Sequence # *'
  //             value={data.sequence_no ? data.sequence_no : 0}
  //             onValueChanged={(e: any) => handleValueChange(e, 'sequence_no', true)}
  //             onKeyDown={() => {}}
  //             min={0}
  //           />
  //         </Grid>
  //         <Grid item xs={12} sm={6} md={4}>
  //           <NumberBoxInput
  //             stylingMode='outlined'
  //             label='Parent Sequence #'
  //             mode='number'
  //             value={data.parent_sequence_no ? data.parent_sequence_no : ''}
  //             onValueChanged={(e: any) => handleValueChange(e, 'parentSequenceNo', true)}
  //             onKeyDown={() => {}}
  //             min={0}
  //           />
  //         </Grid>
  //         <Grid item xs={12} sm={6} md={4}>
  //           <TextBoxInput
  //             stylingMode='outlined'
  //             label='ERP Field *'
  //             value={data.erp_field ? data.erp_field : ''}
  //             onValueChanged={(e: any) => handleValueChange(e, 'erpField', true)}
  //             onKeyDown={() => {}}
  //           />
  //         </Grid>
  //         <Grid item xs={12} sm={6} md={4}>
  //           <TextBoxInput
  //             stylingMode='outlined'
  //             label='ERP Table *'
  //             value={data.erp_table ? data.erp_table : ''}
  //             onValueChanged={(e: any) => handleValueChange(e, 'erpTable', true)}
  //             onKeyDown={() => {}}
  //           />
  //         </Grid>

  //         <Grid item xs={12} sm={6} md={4}>
  //           <TextBoxInput
  //             stylingMode='outlined'
  //             label='Condition'
  //             value={data.condition ? data.condition : ''}
  //             onValueChanged={(e: any) => handleValueChange(e, 'condition', true)}
  //             onKeyDown={() => {}}
  //           />
  //         </Grid>
  //         <Grid item xs={6} sm={4} md={4}>
  //           <br />
  //           <CheckBoxInput
  //             value={data && data.is_required ? true : false}
  //             onValueChanged={(e: any) => handleValueChange(e, 'is_required', true)}
  //             text={'Is Required'}
  //           />
  //         </Grid>
  //         <Grid item xs={6} sm={4} md={4}>
  //           <br />
  //           <CheckBoxInput
  //             value={data && data.is_active ? true : false}
  //             onValueChanged={(e: any) => handleValueChange(e, 'isActive', true)}
  //             text={'Active'}
  //           />
  //         </Grid>
  //         <Grid item xs={12} sm={6} md={4}>
  //           <TextAreaInput
  //             stylingMode='outlined'
  //             label='Extra Attribute'
  //             value={data.extra_attribute ? data.extra_attribute : ''}
  //             onValueChanged={e => handleValueChange(e, 'extraAttribute', true)}
  //           />
  //         </Grid>
  //         {type && (type.id === 'Choice' || type.id === 'Radio' || type.id === 'CheckBox') && (
  //           <Grid item xs={12} sm={6} md={4}>
  //             <TextAreaInput
  //               stylingMode='outlined'
  //               label='Choices'
  //               value={data.choices ? data.choices : ''}
  //               onValueChanged={e => handleValueChange(e, 'choices', true)}
  //             />
  //           </Grid>
  //         )}
  //       </Grid>
  //     </div>
  //   )
  // }

  const CustomItem = (data: any) => {
    const visibleColumns = detailColumns.filter(column => 
      ['trigger', 'control_name', 'sequence_no', 'parent_sequence_no', 'erp_field', 'erp_table', 'is_required', 'is_active', 'erp_attribute'].includes(column.field)
    );
    return (
      <Grid container spacing={3}>
        {visibleColumns.map((column, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            {column.field === 'trigger' && (
              <SelectBox
                stylingMode='outlined'
                displayExpr={'text'}
                valueExpr={'id'}
                showClearButton={true}
                items={triggerItems}
                selectedItem={data[column.field]}
                onSelectionChanged={(e: any) => handleDropdownChange(e, column.field, true)}
                label={column.title}
              />
            )}
            {column.field === 'control_name' && (
              <TextBoxInput
                stylingMode='outlined'
                label={column.title}
                value={data[column.field]}
                onValueChanged={(e: any) => handleValueChange(e, column.field, true)}
                onKeyDown={() => {}}
              />
            )}
            {column.field === 'sequence_no' && (
              <NumberBoxInput
                stylingMode='outlined'
                label={column.title}
                value={data[column.field]}
                onValueChanged={(e: any) => handleValueChange(e, column.field, true)}
                onKeyDown={() => {}}
                min={0}
              />
            )}
            {column.field === 'parent_sequence_no' && (
              <TextBoxInput
                stylingMode='outlined'
                label={column.title}
                value={data[column.field]}
                onValueChanged={(e: any) => handleValueChange(e, column.field, true)}
                onKeyDown={() => {}}
              />
            )}
            {column.field === 'erp_field' && (
              <TextBoxInput
                stylingMode='outlined'
                label={column.title}
                value={data[column.field]}
                onValueChanged={(e: any) => handleValueChange(e, column.field, true)}
              />
            )}
            {column.field === 'erp_table' && (
              <TextBoxInput
                stylingMode='outlined'
                label={column.title}
                value={data[column.field]}
                onValueChanged={(e: any) => handleValueChange(e, column.field, true)}
              />
            )}
            {column.field === 'is_required' && (
              <CheckBoxInput
                value={data[column.field]}
                onValueChanged={(e: any) => handleValueChange(e, column.field, true)}
                text={column.title}
              />
            )}
            {column.field === 'is_active' && (
              <CheckBoxInput
                value={data[column.field]}
                onValueChanged={(e: any) => handleValueChange(e, column.field, true)}
                text={column.title}
              />
            )}
            {column.field === 'erp_attribute' && (
              <TextAreaInput
                stylingMode='outlined'
                label={column.title}
                value={data[column.field]}
                onValueChanged={(e: any) => handleValueChange(e, column.field, true)}
              />
            )}
          </Grid>
        ))}
      </Grid>
    );
  };
  

  const selectionChanged = (e: any) => {
    let newItems: any = []
    if (selectedItems) {
      newItems = [selectedItems]
    }

    e.removedItems.forEach((item: any) => {
      const index = newItems.indexOf(item)
      if (index >= 0) {
        newItems.splice(index, 1)
      }
    })
    if (e.addedItems && e.addedItems.length > 0) {
      newItems = e.addedItems
    }
    setSelectedItems(newItems)
  }

  const checkWorkTypeItemValidation = (item: any) => {
    let selectedItemsData: any = item

    if (selectedItemsData.trigger === 'PRE') {
      if (!selectedItemsData.destination) {
        return false
      }
    }

    if (selectedItemsData.trigger === 'POST') {
      if (!selectedItemsData.condition) {
        return false
      }

      if (!selectedItemsData.destination) {
        return false
      }
    }

    if (selectedItemsData.trigger === 'ADHOC') {
      if (!selectedItemsData.condition) {
        return false
      }
    }
    return true
  }

  const CustomTitle: React.FC<any> = (data, i) => {
    return (
      <div style={{ width: '100%' }} className='displayFlex'>
        <div className='workTypeListItemTitle'> {data.title}</div>
        <div
          className='displayFlex'
          style={{ float: 'right', marginRight: '25px', width: '47%', flexDirection: 'row', justifyContent: 'end' }}
        >
          <div
            style={{ width: 'fit-content' }}
            className={checkWorkTypeItemValidation(data) ? 'validatedworkTypeListItem' : 'workTypeListItem'}
          >
            {checkWorkTypeItemValidation(data) ? 'Validated' : 'Please validate all items'}
          </div>
          {/* <div style={{ marginLeft: '5px' }}>
            <CancelIcon onClick={() => DeleteDialogData(i, data)} />
          </div> */}
        </div>
      </div>
    )
  }

  const populateValue = (details: any, mode?: any) => {
    let selectedWorkType
    if (details.data && details.data.length > 0) {
      selectedWorkType = details.data
    }
    setWorkTypeFieldsItems(details.data)
    setAllWorkTypeFieldsItems(details.data)
    setId(details.data.id)
    setTitle(details.data?.title ? { id: details.data.title, text: details.data.title } : { id: '', text: '' })
    setType({ id: details.data?.type, text: details.data.type })
    setControlName(details.data?.control_name)
    setCondition(details.data?.condition)
    setChoices(details.data?.choices)
    setSelectedWorkTypeItems(details.data?.wotype_worktype_field ? details.data.wotype_worktype_field : [])
    setHideAlertButton(false)
    setSelectedItems(selectedWorkType ? selectedWorkType : [])
    setMode(mode)
    setSelectedWorkTypeID(0)
  }

  const clearFilter = (clearfield?: any, title?: any) => {
    setTitle({ id: '', text: '' })
    setType({ id: '', text: '' })
    setWOWorkType('')
    setMode(clearfield ? 'add' : 'edit')
    // if (!clearfield) props.onClose()
  }

  const _onWorkTypeSelect = (e: any, fieldName: string, options?: any, isWorkTypeFieldOption?: any) => {
    if (isWorkTypeFieldOption) {
      setTitle(
        e.selectedItem && e.selectedItem !== null
          ? { id: e.selectedItem.worktype_id, text: e.selectedItem.worktype_name }
          : e.selectedItem
      )

      if (Array.isArray(options) && e.selectedItem && options.some((s: any) => s.text === e.selectedItem.text)) {
        let selectedWTField = options.find((s: any) => s.text === e.selectedItem.text)
        populateValue(selectedWTField, 'edit')
      } else {
        clearFilter(true, e.selectedItem && e.selectedItem.text)
      }
    } else {
      let workTypeItemsData: any = workTypes
      let selectedWorkTypeItemsData: any = selectedWorkTypeItems ? selectedWorkTypeItems : []
      if (e.selectedItem) {
        let findIndex: any = workTypeItemsData.findIndex((wo: any) => wo.worktype_id === e.selectedItem.worktype_id)

        workTypeItemsData.splice(findIndex, 1)
        selectedWorkTypeItemsData.push({
          extra_attribute: null,
          erp_field: null,
          erp_table: null,
          sequence_no: null,
          parent_sequence_no: null,
          trigger: null,
          is_active: true,
          control_name: null,
          choices: null,
          condition: null,
          id: e.selectedItem.worktype_id,
          isRequired: false,
          title: e.selectedItem.worktype_name
        })
        setWorkTypeItems(workTypeItemsData)
        setSelectedWorkTypeItems(selectedWorkTypeItemsData)

        if (selectedWorkTypeItemsData && selectedWorkTypeItemsData.length === 1) {
          setSelectedItems(selectedWorkTypeItems[0])
        }
        // setTimeout(() => setLoading(false), 500)
      }
      if (fieldName === 'title') {
        setTitle({ id: e.selectedItem.worktype_id, text: e.selectedItem.worktype_name })
      } else if (fieldName === 'woworktype') {
        setWOWorkType(e.selectedItem)
      }
    }
  }

  return (
    <div>
      <SelectBox
        stylingMode='outlined'
        displayExpr='worktype_name'
        valueExpr='worktype_id'
        showClearButton={true}
        items={workTypes}
        selectedItem={rowData.data}
        onSelectionChanged={(e: any) => _onWorkTypeSelect(e, 'woworktype', 'worktypeItems')}
      />
      {selectedWorkTypeItems && selectedWorkTypeItems.length > 0 && (
        <Accordion
          dataSource={selectedWorkTypeItems}
          collapsible={true}
          multiple={false}
          animationDuration={300}
          selectedItems={selectedItems}
          onSelectionChanged={selectionChanged}
          itemTitleRender={CustomTitle}
          itemRender={CustomItem}
        />
      )}
    </div>
  )
}

export default CustomEditWorkTypeField
