import React from 'react'
import { TextBox } from 'devextreme-react/text-box'

interface Props {
  label?: string
  stylingMode: string | any
  placeholder?: string | any
  mask?: string | any
  useMaskedValue?: string | any
  mode?: string | any
  value: string
  showClearButton?: boolean | any
  readOnly?: boolean | any
  disabled?: boolean | any
  onValueChanged: any
  onKeyDown?: any
  required?: any
  spellcheck?: boolean | any
  className?: string
}

const TextBoxInput: React.FC<Props> = props => {
  return (
    <TextBox
      labelMode='floating'
      label={props.label}
      stylingMode={props.stylingMode}
      placeholder={props.placeholder}
      value={props.value}
      onValueChanged={props.onValueChanged}
      showClearButton={props.showClearButton}
      onEnterKey={props.onKeyDown}
      mask={props.mask}
      useMaskedValue={props.useMaskedValue}
      mode={props.mode}
      readOnly={props.readOnly}
      disabled={props.disabled}
      spellcheck={props.spellcheck}
      className={props.className}
    >
      {/* {showButton && (
        <Button
          name="test"
          location={buttonLocation}
          options={{
            icon: 'map',
            type: 'default',
            onClick: onClickButton,
          }}
        />
      )} */}
      {/* {required && (
        <Validator>
          <RequiredRule message="Address is required" />
        </Validator>
      )} */}
    </TextBox>
  )
}

export default TextBoxInput
