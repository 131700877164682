import React, { useState, useEffect, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import utility from '../../../classes/utility'
import global from '../../../classes/global'
import { Config } from '../../../classes/config'
import AlertDialog from '../../Common/AlertDialog'
import Datatable from '../../Common/CustomASDataGrid'
import DateBoxInput from '../../Common/ReusableComponents/DateBox'
import SelectBoxInput from '../../Common/ReusableComponents/SelectInputBox'
import TextBoxInput from '../../Common/ReusableComponents/TextBox'
import Spinner from '../../Common/ReusableComponents/Spinner'
import { Input, Typography } from '@mui/material/'
import InputLabel from '@mui/material/InputLabel'
import DisplayDetailsModal from '../../Common/DisplayDetailModal/DisplayDetailModal';
import moment from 'moment-timezone'

const _items: any[] = []

interface SearchTimesheetProps {
  Department: string;
  role: string;
}

interface StateProps {
  alertMessage?: string | JSX.Element;
  showAlert?: boolean;
  loading?: boolean;
  callAfterInit?: boolean;
}

const SearchTimesheet: React.FC<SearchTimesheetProps> = props => {
  const [items, setItems] = useState([])
  const searchResultsText = 'Please Search To Populate Results'
  const columns = [
    { field: 'Workordernumber', title: 'Work Order #', type: 'string', allowHiding: false, link: 'workorderLink' },
    { field: 'employee', title: 'Employee', type: 'string', allowHiding: false, groupIndex: 0 },
    { field: 'type', title: 'Type', type: 'string', allowHiding: true },
    { field: 'department', title: 'Department', type: 'string', allowHiding: true },
    { field: 'team', title: 'Team', type: 'string', allowHiding: true },
    { field: 'crew', title: 'Crew', type: 'string', allowHiding: true },
    { field: 'starttime', title: 'Start Time', type: 'datetime', allowHiding: true },
    { field: 'endtime', title: 'End Time', type: 'datetime', link: "customCellEditedData", allowHiding: true },
    { field: 'hoursworked', title: 'Hours Worked', type: 'string', link: 'customCellEditedData', allowHiding: true },
    // { field: '', title: '', type: 'string', allowHiding: true, link: 'timesheetLink' },
    { field: 'isWorkorderOvertime', title: 'is Overtime', type: 'string', allowHiding: true }
  ]

  const [employeeNameItems, setEmployeeNameItems] = useState<any[]>([])
  const [departmentItems, setDepartmentItems] = useState<any[]>([])
  const [teamItems, setTeamItems] = useState<any[]>([])
  const [crewItems, setCrewItems] = useState<any[]>([])
  const [allemployeenameitems, setAllEmployeeNameItems] = useState<any[]>([])
  const [alldepartmentItems, setAllDepartmentItems] = useState<any[]>([])
  const [tempTeamItems, setTempTeamItems] = useState<any[]>([])
  const [tempCrewItems, setTempCrewItems] = useState<any[]>([])
  const [dateCreatedfrom, setDateCreatedFrom] = useState<Date | null>(null)
  const [dateCreatedTo, setDateCreatedTo] = useState<Date | null>(null)
  const [workorderno, setWorkOrderNo] = useState<string>('')
  const [employeename, setEmployeeName] = useState<any>(null)
  const [crew, setCrew] = useState<any>(null)
  const [department, setDepartment] = useState<any>(
    props.role === global.worxOnline_Contractor || props.role === global.worxOnline_ContractorAdmin
      ? props.Department
      : ''
  )
  const [teams, setTeams] = useState<any>(null)
  const [resultText, setResultText] = useState(searchResultsText)
  const [details, setDetails] = useState<any>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [callAfterInit, setCallAfterInit] = useState<boolean>(false)
  const [disableTeam, setDisableTeam] = useState<boolean>(true)
  const [disableCrew, setDisableCrew] = useState<boolean>(true)
  const [state, setState] = useState<StateProps>({
    alertMessage: '',
    showAlert: false,
    loading: false,
    callAfterInit: false
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getEmployeeData()
        await getDepartmentData()
        await getTeamDataAsync()
        await getCrewDataAsync()
      } catch (err) {
        console.log(err)
      }
    }
    const tempstate = localStorage.getItem('timesheetState')
    if (tempstate) {
      const allState = JSON.parse(tempstate)
      setDateCreatedFrom(allState.dateCreatedfrom || null)
      setDateCreatedTo(allState.dateCreatedTo || null)
      setWorkOrderNo(allState.workorderno || '')
      setEmployeeName(allState.employeename || '')
      setCrew(allState.crew || '')
      setDepartment(allState.department || '')
      setTeams(allState.teams || '')
    }
    fetchData()
  }, [])

  const handleValueChange = (e: any, fieldName: string) => {
    switch (fieldName) {
      case 'dateCreatedfrom':
        setDateCreatedFrom(e.value)
        break
      case 'dateCreatedTo':
        setDateCreatedTo(e.value)
        break
      case 'workorderno':
        setWorkOrderNo(e.value)
        break
      default:
        break
    }
  }

  const handleDropdownChange = (e: any, fieldName: string) => {
    switch (fieldName) {
      case 'department':
        if (e.selectedItem !== null) {
          const tempEmployeeNameItems = allemployeenameitems.filter((f: any) => f.departmentId === e.selectedItem.id)
          const tempTeams = teamItems
          const filteredTeam = tempTeams.filter((t: any) => t.departmentId === e.selectedItem.id)

          setEmployeeNameItems(tempEmployeeNameItems)
          setDisableTeam(false)
          setTempTeamItems(filteredTeam)
          setDisableCrew(true)
          setTempCrewItems([])
          setTeams('')
          setCrew('')
          setEmployeeName('')
        } else {
          setEmployeeNameItems(allemployeenameitems)
          setDisableTeam(false)
          setTempTeamItems(teamItems)
          setDisableCrew(true)
          setTempCrewItems([])
          setTeams('')
          setCrew('')
          setEmployeeName('')
        }
        setDepartment(e.selectedItem)
        break
      case 'teams':
        if (e.selectedItem !== null) {
          const tempCrew = crewItems
          const filteredCrew = tempCrew.filter((c: any) => c.teamId === e.selectedItem.id)
          setTempCrewItems(filteredCrew)
          setDisableCrew(false)
          setCrew('')
        } else {
          setDisableCrew(true)
          setTempCrewItems([])
          setCrew('')
        }
        setTeams(e.selectedItem)
        break
      case 'crew':
        setCrew(e.selectedItem)
        break
      case 'employeename':
        setEmployeeName(e.selectedItem)
        break
      default:
        break
    }
  }

  const _handleKeyDown = (e: any) => {
    if (e.event.originalEvent.key === 'Enter') {
      getTimesheetwithcriteria()
    }
  }

  const clearFilter = () => {
    setItems([])
    setWorkOrderNo('')
    setEmployeeName(null)
    setDateCreatedFrom(null)
    setDateCreatedTo(null)
    setCrew(null)
    setDepartment('')
    setTeams('')
    setTempTeamItems(teamItems)
    setTempCrewItems(crewItems)
    setDisableCrew(true)
    localStorage.setItem('timesheetState', '')
    const datagrid = localStorage.getItem('datagridFilterValue')
    if (datagrid) {
      localStorage.removeItem('datagridFilterValue')
    }
  }

  const viewDetails = (item: any) => {
    setDetails(item)
    setShowModal(true)
  }

  const _closeModal = () => {
    setShowModal(false)
  }

  const getEmployeeData = async () => {
    try {
      const data: any = await utility.genericGetAPICall(Config.SearchEmployeeApi)
      const activeEmployees = data
        .filter((r: any) => r.is_active === true && r.department_code !== 'DEFAULT')
        .map((r: any) => ({
          id: r.id,
          text: r.full_name,
          departmentId: r.department_id
        }))
      const sortedEmployee = utility._sortItems(activeEmployees, 'text', false)
      setEmployeeNameItems(sortedEmployee)
      setAllEmployeeNameItems(sortedEmployee)
    } catch (err) {
      console.log(err)
    }
  }

  const getDepartmentData = async () => {
    try {
      const data: any = await utility.genericGetAPICall(`${Config.DepartmentList}`)
      const activeDepartments = data
        .filter((r: any) => r.is_active === true)
        .map((r: any) => ({
          id: r.id,
          text: r.title
        }))
      const sortedDepartments = utility._sortItems(activeDepartments, 'text', false)
      setDepartmentItems(sortedDepartments)
      setAllDepartmentItems(sortedDepartments)
    } catch (err) {
      console.log(err)
    }
  }

  const getTeamDataAsync = async () => {
    try {
      const data: any = await utility.genericGetAPICall(`${Config.TeamList}`)
      const activeTeams = data
        .filter((r: any) => r.is_active === true && r.department?.title !== 'DEFAULT')
        .map((r: any) => ({
          id: r.id,
          text: r.title,
          departmentId: r.department_id
        }))
      const sortedTeams = utility._sortItems(activeTeams, 'text', false)
      setTeamItems(sortedTeams)
    } catch (err) {
      console.log(err)
    }
  }

  const getCrewDataAsync = async () => {
    try {
      const data: any = await utility.genericGetAPICall(Config.crewlistname)
      const activeCrews = data
        .filter((r: any) => r.is_active === true)
        .map((r: any) => ({
          id: r.id,
          text: r.title,
          teamId: r.team?.id
        }))
      const sortedCrews = utility._sortItems(activeCrews, 'text', false)
      setCrewItems(sortedCrews)
    } catch (err) {
      console.log(err)
    }
  }

  const getTimesheetwithcriteria = async () => {
    let criteriaSelected = false;

    let dataToPassToService: any = {};
    if (workorderno) {
      dataToPassToService.workorder_id = [workorderno];
      criteriaSelected = true;
    }

    if (employeename) {
      dataToPassToService.employee_id = [employeename.id];
      criteriaSelected = true;
    }
    if (department) {
      dataToPassToService.department_id = [department.id];
      criteriaSelected = true;
    }
    if (crew) {
      dataToPassToService.crew_id =[crew.id];
      criteriaSelected = true;
    }
    if (teams) {
      dataToPassToService.team_id = [teams.id];
      criteriaSelected = true;
    }
    if (dateCreatedTo && dateCreatedfrom) {
      let dcreatedfrom = moment(dateCreatedfrom);
      let dcreatedTo = moment(dateCreatedTo);
      if (dcreatedTo.isBefore(dcreatedfrom, 'day')) {
        setState({
          alertMessage: 'Date To should be greater than Date From',
          showAlert: true,
          loading: false
        });
        return;
      }
    }

    if (dateCreatedfrom || dateCreatedTo) {
      if (dateCreatedfrom) {
        const startOfDay = moment(dateCreatedfrom).startOf('day')
        const startOfDayUTC = startOfDay.utc()
        dataToPassToService.date_started_from = startOfDayUTC.toISOString()
      }
      if (dateCreatedTo) {
        const endOfDay = moment(dateCreatedTo).endOf('day')
        const endOfDayUTC = endOfDay.utc()
        dataToPassToService.date_started_to = endOfDayUTC.toISOString()
      }
      criteriaSelected = true
    }

    if (!criteriaSelected) {
      setState({
        alertMessage: 'Please select search criteria',
        showAlert: true
      });
      return;
    }
    setState({
      callAfterInit: true,
      loading: true
    });
    
    try {
      const data: any = await utility.genericGetAPICallForList(Config.SearchTimesheetApi, 'POST', dataToPassToService);
        let _items: any = [];
      if (data.length === 0) {
        setResultText('No Results Found');
      } else {
        setResultText('');
        data.forEach((item: any) => {
          const userTimezone = moment.tz.guess();
          let startTime = null; let endTime = null; let Editedstarttime = null; let Editedendtime = null;

          if (item.start_time) {
            startTime = moment.utc(item.start_time).tz(userTimezone);
          }
          if (item.end_time) {
            endTime = moment.utc(item.end_time).tz(userTimezone);
          }
          if (item.edited_start_time) {
            Editedstarttime = moment.utc(item.edited_start_time).tz(userTimezone);
          }
          if (item.edited_finish_time) {
            Editedendtime = moment.utc(item.edited_finish_time).tz(userTimezone);
          }

          _items.push({
            Workordernumber: item.workorder_id,
            //asseticWONumber: item.erpWorkorderNumber,
            woid: item.workorder_id,
            type: item.type,
            employee: item.full_name,
            department: item.department,
            team: item.team,
            crew: item.crew,
            starttime: startTime ? startTime.format('DD/MM/YYYY hh:mm A') : '',
            endtime: endTime ? endTime.format('DD/MM/YYYY hh:mm A') : '',
            hoursworked: item.hours_worked ? item.hours_worked : '',
            Editedendtime: Editedendtime ? Editedendtime.format('DD/MM/YYYY hh:mm A') : '',
            Editedhoursworked: item.editedHoursWorked ? item.editedHoursWorked : '',
            isWorkorderOvertime: item.is_workorder_overtime ? "Yes" : "No",
          });
        });

      }
      setItems(_items)
    } catch (err) {
      console.log(err)
      setLoading(false)
    } 
  }

  const alert = () => {
    return (
      <AlertDialog
      showDialog={state.showAlert || false}
      message={state.alertMessage}
      title={Config.productTitle}
      onClose={() => {setState({ 
        showAlert: false,
        alertMessage: '' })}}
     
    />
    )
  }

  const renderDetailsModal = () => {
    return (
      <DisplayDetailsModal
      showModal={showModal}
      onClick={_closeModal}
      details={details}
      columns={columns}
    />
    )
  }

  return (
    <>
    {state.showAlert && alert()}
    {showModal && renderDetailsModal()}
      <div className='ui-container'>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel shrink className='label bold'>
                Date Created From
              </InputLabel>
              <DateBoxInput
                stylingMode='outlined'
                type={'date'}
                displayFormat={'dd/MM/yyyy'}
                showClearButton={true}
                value={dateCreatedfrom ? new Date(dateCreatedfrom) : null}
                onValueChanged={e => handleValueChange(e, 'dateCreatedfrom')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel shrink className='label bold'>
                Date Created To
              </InputLabel>
              <DateBoxInput
                stylingMode='outlined'
                type={'date'}
                displayFormat={'dd/MM/yyyy'}
                value={dateCreatedTo ? new Date(dateCreatedTo) : null}
                onValueChanged={e => handleValueChange(e, 'dateCreatedTo')}
                showClearButton={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel shrink className='label bold'>
                Work Order #
              </InputLabel>
              <TextBoxInput
                value={workorderno}
                onValueChanged={(e: any) => handleValueChange(e, 'workorderno')}
                className='form-control'
                onKeyDown={_handleKeyDown}
                stylingMode={'outlined'}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel shrink className='label bold'>
                Employee
              </InputLabel>
              <SelectBoxInput
                items={employeeNameItems}
                valueExpr='id'
                displayExpr='text'
                selectedItem={employeename}
                onSelectionChanged={(e: any) => handleDropdownChange(e, 'employeename')}
                className='form-control'
                stylingMode={'outlined'}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel shrink className='label bold'>
                Department
              </InputLabel>
              <SelectBoxInput
                items={departmentItems}
                valueExpr='id'
                displayExpr='text'
                selectedItem={department}
                onSelectionChanged={(e: any) => handleDropdownChange(e, 'department')}
                className='form-control'
                // disabled={
                //   props.role === global.worxOnline_Contractor || props.role === global.worxOnline_ContractorAdmin
                // }
                stylingMode={'outlined'}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel shrink className='label bold'>
                Team
              </InputLabel>
              <SelectBoxInput
                items={tempTeamItems}
                valueExpr='id'
                displayExpr='text'
                selectedItem={teams}
                onSelectionChanged={(e: any) => handleDropdownChange(e, 'teams')}
                className='form-control'
                disabled={disableTeam}
                stylingMode={'outlined'}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel shrink className='label bold'>
                Crew
              </InputLabel>
              <SelectBoxInput
                items={tempCrewItems}
                valueExpr='id'
                displayExpr='text'
                selectedItem={crew}
                onSelectionChanged={(e: any) => handleDropdownChange(e, 'crew')}
                className='form-control'
                disabled={disableCrew}
                stylingMode={'outlined'}
              />
            </Grid>
          </Grid>
        </div>
        <div className='actions-container'>
        <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className='action-buttons'>
              <Button variant='outlined' onClick={clearFilter}>
                Clear
              </Button>
              <Button variant='outlined' color='primary' onClick={getTimesheetwithcriteria} className='primary-button'>
                Search
              </Button>
            </div>
          </Grid>
          </Grid>
        </div>
      </div>
        <div className='p-10'>
          <h2>Results</h2>
          <Typography>{resultText}</Typography>
          {/* {!state.loading ? ( */}
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {items.length > 0 && (
                  <div className='results-container'>
                    <Datatable
                      columns={columns}
                      dataSource={items}
                      filteralbe={true}
                      groupable={true}
                      fileName={'TimeSheet'}
                      columnChooser={'timeSheetColumns'}
                      allowExportSelectedData={false}
                      showNewBtn={false}
                      showCommandBtn={false}
                      showViewDetailBtn={true}
                      hideCommandEditBtn={true}
                      hideCommandDeleteBtn={true}
                      viewItem={(e: any) => viewDetails(e)}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
      {/* ) :(
        <Spinner />
      )} */}
       </div>
    </>
  )
}

export default SearchTimesheet
