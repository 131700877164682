
export const AdminJson: any =
[
    {
       "Id":5,
       "Title":"Risk Area",
       "Category":"Risk Management",
       "Isactive":true,
       "AddEditField":[
          {
             "Type":"TextField",
             "ID":1000,
             "Label":"Title",
             "Placeholder":"Title",
             "StateValue":"",
             "Value":"",
             "addeditfield":"Title",
             "field":"Title",
             "Grid":{
                "sm":12,
                "md":12,
                "lg":6
             },
             "IsMandatory":true
          },
          {
             "Type":"CheckBox",
             "ID":1001,
             "Label":"Is Active",
             "Placeholder":"",
             "StateValue":"",
             "Value":"",
             "addeditfield":"isactive",
             "field":"IsActive",
             "Grid":{
                "sm":12,
                "md":12,
                "lg":6
             }
          }
       ],
       "apiInfo":{
          "create":{
             "name":"RiskAreas",
             "method":"POST",
             "dataToPassToService":"{}"
          },
          "update":{
             "name":"RiskAreas",
             "method":"PUT",
             "dataToPassToService":"{}"
          },
          "read":{
             "name":"RiskAreas",
             "method":"GET",
             "dataToPassToService":"{}"
          },
          "delete":{
             "name":"RiskAreas",
             "method":"DELETE"
          }
       },
       "Columns":[
          {
             "field":"Id",
             "title":"ID",
             "width":80,
             "type":"string",
             "allowHiding":false,
             "visibleColumn":true,
             "isHiddenForEditAdd":true,
             "forEdit":true,
             "forCreate":false
          },
          {
             "field":"Title",
             "title":"Title",
             "type":"string",
             "visibleColumn":true,
             "isHiddenForEditAdd":false,
             "forEdit":true,
             "forCreate":false
          },
          {
             "field":"Isactive",
             "title":"Active",
             "width":120,
             "type":"boolean",
             "allowHiding":true,
             "visibleColumn":true,
             "isHiddenForEditAdd":false,
             "forEdit":true,
             "forCreate":false
          },
          {
             "field":"Dateupdated",
             "title":"Updated",
             "width":160,
             "type":"datetime",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":true,
             "forCreate":true,
             "sortOrder":"desc"
          },
          {
             "field":"Userupdated",
             "title":"Updated By",
             "width":160,
             "type":"string",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":true,
             "forCreate":true
          },
          {
             "field":"Datecreated",
             "title":"Created",
             "width":160,
             "type":"datetime",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":false,
             "forCreate":true
          },
          {
             "field":"Usercreated",
             "title":"Created By",
             "width":160,
             "type":"string",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":false,
             "forCreate":true
          }
       ],
       "DataTable":{
          "filteralbe":true,
          "groupable":true,
          "selectionMode":true,
          "showHideColumnMenu":true,
          "allowExportSelectedData":true,
          "showCommandBtn":true,
          "showNewBtn":true,
          "showViewDetailBtn":true
       }
    },
    {
       "Id":3,
       "Title":"Risk Category",
       "Category":"Risk Management",
       "Isactive":true,
       "AddEditField":[
          {
             "Type":"TextField",
             "ID":1000,
             "Label":"Title",
             "Placeholder":"Title",
             "StateValue":"",
             "Value":"",
             "addeditfield":"Title",
             "field":"Title",
             "Grid":{
                "sm":12,
                "md":12,
                "lg":6
             },
             "IsMandatory":true
          }
       ],
       "apiInfo":{
          "create":{
             "name":"Riskcategories",
             "method":"POST",
             "dataToPassToService":"{}"
          },
          "update":{
             "name":"Riskcategories",
             "method":"PUT",
             "dataToPassToService":"{}"
          },
          "read":{
             "name":"Riskcategories",
             "method":"GET",
             "dataToPassToService":"{}"
          },
          "delete":{
             "name":"Riskcategories",
             "method":"DELETE"
          }
       },
       "Columns":[
          {
             "field":"Id",
             "title":"ID",
             "width":80,
             "type":"string",
             "allowHiding":false,
             "visibleColumn":true,
             "isHiddenForEditAdd":true,
             "forEdit":true,
             "forCreate":false
          },
          {
             "field":"Title",
             "title":"Title",
             "type":"string",
             "visibleColumn":true,
             "isHiddenForEditAdd":false,
             "forEdit":true,
             "forCreate":false
          },
          {
             "field":"Dateupdated",
             "title":"Updated",
             "width":160,
             "type":"datetime",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":true,
             "forCreate":true,
             "sortOrder":"desc"
          },
          {
             "field":"Userupdated",
             "title":"Updated By",
             "width":160,
             "type":"string",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":true,
             "forCreate":true
          },
          {
             "field":"Datecreated",
             "title":"Created",
             "width":160,
             "type":"datetime",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":false,
             "forCreate":true
          },
          {
             "field":"Usercreated",
             "title":"Created By",
             "width":160,
             "type":"string",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":false,
             "forCreate":true
          }
       ],
       "DataTable":{
          "filteralbe":true,
          "groupable":true,
          "selectionMode":true,
          "showHideColumnMenu":true,
          "allowExportSelectedData":true,
          "showCommandBtn":true,
          "showNewBtn":true,
          "showViewDetailBtn":true
       }
    },
    {
       "Id":4,
       "Title":"Risk Control Measure",
       "Category":"Risk Management",
       "Isactive":true,
       "AddEditField":[
          {
             "Type":"TextField",
             "ID":1000,
             "Label":"Title",
             "Placeholder":"Title",
             "StateValue":"",
             "Value":"",
             "addeditfield":"Controlmeasure",
             "field":"Controlmeasure",
             "Grid":{
                "sm":12,
                "md":12,
                "lg":12
             },
             "IsMandatory":true
          },
          {
             "Type":"Choices",
             "ID":1001,
             "Label":"Risks",
             "Placeholder":"Risks",
             "StateValue":"",
             "Value":"",
             "addeditfield":"Riskid",
             "field":"Riskid",
             "Grid":{
                "sm":12,
                "md":12,
                "lg":12
             },
             "ReadOnly":false,
             "IsMandatory":true,
             "APIListNameItems":[
                
             ],
             "APIListName":"Risks",
             "IsLocalData":false,
             "IsMultiple":true
          },
          {
             "Type":"CheckBox",
             "ID":1002,
             "Label":"Is Active",
             "Placeholder":"",
             "StateValue":"",
             "Value":"",
             "addeditfield":"Isactive",
             "field":"IsActive",
             "Grid":{
                "sm":12,
                "md":12,
                "lg":12
             }
          }
       ],
       "apiInfo":{
          "create":{
             "name":"Riskcontrolmeasures",
             "method":"POST",
             "dataToPassToService":"{}"
          },
          "update":{
             "name":"Riskcontrolmeasures",
             "method":"PUT",
             "dataToPassToService":"{}"
          },
          "read":{
             "name":"SearchControlMeasures",
             "method":"POST",
             "dataToPassToService":"{}"
          },
          "delete":{
             "name":"Riskcontrolmeasures",
             "method":"DELETE"
          }
       },
       "Columns":[
          {
             "field":"Id",
             "title":"ID",
             "width":80,
             "type":"string",
             "allowHiding":false,
             "visibleColumn":true,
             "isHiddenForEditAdd":true,
             "forEdit":true,
             "forCreate":false
          },
          {
             "field":"Controlmeasure",
             "title":"Title",
             "type":"string",
             "visibleColumn":true,
             "isHiddenForEditAdd":false,
             "forEdit":true,
             "forCreate":false
          },
          {
             "field":"Risks",
             "title":"Risks",
             "width":null,
             "type":"string",
             "allowHiding":true,
             "isArray":true
          },
          {
             "field":"Isactive",
             "title":"Active",
             "width":80,
             "type":"boolean",
             "allowHiding":true,
             "visibleColumn":true
          },
          {
             "field":"Dateupdated",
             "title":"Updated",
             "width":160,
             "type":"datetime",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":true,
             "forCreate":true,
             "sortOrder":"desc"
          },
          {
             "field":"Userupdated",
             "title":"Updated By",
             "width":160,
             "type":"string",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":true,
             "forCreate":true
          },
          {
             "field":"Datecreated",
             "title":"Created",
             "width":160,
             "type":"datetime",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":false,
             "forCreate":true
          },
          {
             "field":"Usercreated",
             "title":"Created By",
             "width":160,
             "type":"string",
             "visibleColumn":true,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":false,
             "forCreate":true
          }
       ],
       "DataTable":{
          "filteralbe":true,
          "groupable":true,
          "selectionMode":true,
          "showHideColumnMenu":true,
          "allowExportSelectedData":true,
          "showCommandBtn":true,
          "showNewBtn":true,
          "showViewDetailBtn":true
       }
    },
    {
       "Id":2,
       "Title":"Risk Form",
       "Category":"Risk Management",
       "Isactive":false,
       "AddEditField":[
          {
             "Type":"TextField",
             "ID":1000,
             "Label":"Title",
             "Placeholder":"Title",
             "StateValue":"",
             "Value":"",
             "addeditfield":"Title",
             "field":"Title",
             "Grid":{
                "sm":12,
                "md":12,
                "lg":6
             },
             "IsMandatory":true
          },
          {
             "Type":"Choices",
             "ID":1003,
             "Label":"Department",
             "Placeholder":"Department",
             "StateValue":"",
             "Value":"",
             "addeditfield":"Departmentid",
             "field":"DepartmentId",
             "Grid":{
                "sm":12,
                "md":12,
                "lg":6
             },
             "ReadOnly":false,
             "IsMandatory":true,
             "APIListNameItems":[
                
             ],
             "APIListName":"Departments",
             "IsLocalData":false,
             "IsMultiple":false
          },
          {
             "Type":"CheckBox",
             "ID":1001,
             "Label":"Is Active",
             "Placeholder":"",
             "StateValue":"",
             "Value":"",
             "addeditfield":"Isactive",
             "field":"IsActive",
             "Grid":{
                "sm":12,
                "md":12,
                "lg":6
             }
          },
          {
             "Type":"TextArea",
             "ID":1002,
             "Label":"Model",
             "Placeholder":"Model",
             "StateValue":"",
             "Value":"",
             "addeditfield":"Model",
             "field":"Model",
             "Grid":{
                "sm":12,
                "md":12,
                "lg":6
             }
          }
       ],
       "apiInfo":{
          "create":{
             "name":"Riskforms",
             "method":"POST",
             "dataToPassToService":"{}"
          },
          "update":{
             "name":"Riskforms",
             "method":"PUT",
             "dataToPassToService":"{}"
          },
          "read":{
             "name":"Riskforms",
             "method":"GET",
             "dataToPassToService":"{}"
          },
          "delete":{
             "name":"Riskforms",
             "method":"DELETE"
          }
       },
       "Columns":[
          {
             "field":"Id",
             "title":"ID",
             "width":80,
             "type":"string",
             "allowHiding":false,
             "visibleColumn":true,
             "isHiddenForEditAdd":true,
             "forEdit":true,
             "forCreate":false
          },
          {
             "field":"Title",
             "title":"Title",
             "type":"string",
             "visibleColumn":true,
             "isHiddenForEditAdd":false,
             "forEdit":true,
             "forCreate":false
          },
          {
             "field":"Department",
             "title":"Department",
             "width":null,
             "type":"string",
             "allowHiding":true,
             "visibleColumn":true
          },
          {
             "field":"Isactive",
             "title":"Active",
             "width":120,
             "type":"boolean",
             "allowHiding":true,
             "visibleColumn":true,
             "isHiddenForEditAdd":false,
             "forEdit":true,
             "forCreate":false
          },
          {
             "field":"Dateupdated",
             "title":"Updated",
             "width":160,
             "type":"datetime",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":true,
             "forCreate":true,
             "sortOrder":"desc"
          },
          {
             "field":"Userupdated",
             "title":"Updated By",
             "width":160,
             "type":"string",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":true,
             "forCreate":true
          },
          {
             "field":"Datecreated",
             "title":"Created",
             "width":160,
             "type":"datetime",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":false,
             "forCreate":true
          },
          {
             "field":"Usercreated",
             "title":"Created By",
             "width":160,
             "type":"string",
             "visibleColumn":false,
             "allowHiding":true,
             "isHiddenForEditAdd":true,
             "forEdit":false,
             "forCreate":true
          }
       ],
       "DataTable":{
          "filteralbe":true,
          "groupable":true,
          "selectionMode":true,
          "showHideColumnMenu":true,
          "allowExportSelectedData":true,
          "showCommandBtn":true,
          "showNewBtn":true,
          "showViewDetailBtn":true
       }
    },
    {
       "Id":1,
       "Title":"Risks",
       "Category":"Risk Management",
       "Isactive":true,
       "AddEditField":[
          {
             "Type":"TextField",
             "ID":1000,
             "Label":"Title",
             "Placeholder":"Title",
             "StateValue":"",
             "Value":"",
             "addeditfield":"Title",
             "field":"Title",
             "Grid":{
                "sm":12,
                "md":12,
                "lg":6
             },
             "IsMandatory":true
          },
          {
             "Type":"Choices",
             "ID":1001,
             "Label":"Risk Category",
             "Placeholder":"Risk Category",
             "StateValue":"",
             "Value":"",
             "addeditfield":"Category",
             "field":"CategoryID",
             "Grid":{
                "sm":12,
                "md":12,
                "lg":6
             },
             "ReadOnly":false,
             "IsMandatory":true,
             "APIListNameItems":[
                
             ],
             "APIListName":"Riskcategories",
             "IsLocalData":false,
             "IsMultiple":false
          },
          {
             "Type":"Choices",
             "ID":1002,
             "Label":"Risk Area",
             "Placeholder":"Risk Area",
             "StateValue":"",
             "Value":"",
             "addeditfield":"Riskareaid",
             "field":"RiskAreaID",
             "Grid":{
                "sm":12,
                "md":12,
                "lg":6
             },
             "ReadOnly":false,
             "IsMandatory":true,
             "APIListNameItems":[
                
             ],
             "APIListName":"RiskAreas",
             "IsLocalData":false,
             "IsMultiple":false
          },
          {
             "Type":"CheckBox",
             "ID":1003,
             "Label":"Is Active",
             "Placeholder":"",
             "StateValue":"",
             "Value":"",
             "addeditfield":"Isactive",
             "field":"IsActive",
             "Grid":{
                "sm":12,
                "md":12,
                "lg":6
             }
          }
       ],
       "apiInfo":{
          "read":{
             "name":"Risks",
             "method":"GET",
             "dataToPassToService":"{}"
          },
          "create":{
             "name":"Risks",
             "method":"POST",
             "dataToPassToService":"{}"
          },
          "delete":{
             "name":"Risks",
             "method":"DELETE"
          },
          "update":{
             "name":"Risks",
             "method":"PUT",
             "dataToPassToService":"{}"
          }
       },
       "Columns":[
          {
             "type":"string",
             "field":"Id",
             "title":"ID",
             "width":80,
             "forEdit":true,
             "forCreate":false,
             "allowHiding":false,
             "visibleColumn":true,
             "isHiddenForEditAdd":true
          },
          {
             "type":"string",
             "field":"Title",
             "title":"Title",
             "forEdit":true,
             "forCreate":false,
             "visibleColumn":true,
             "isHiddenForEditAdd":false
          },
          {
             "field":"CategoryName",
             "title":"Risk Cateogry",
             "width":null,
             "type":"string",
             "allowHiding":true,
             "visibleColumn":true
          },
          {
             "field":"RiskAreaTitle",
             "title":"Risk Area",
             "width":null,
             "type":"string",
             "allowHiding":true,
             "visibleColumn":true
          },
          {
             "field":"IsActive",
             "title":"Active",
             "width":80,
             "type":"boolean",
             "allowHiding":true,
             "visibleColumn":true
          },
          {
             "type":"datetime",
             "field":"Dateupdated",
             "title":"Updated",
             "width":160,
             "forEdit":true,
             "forCreate":true,
             "sortOrder":"desc",
             "allowHiding":true,
             "visibleColumn":false,
             "isHiddenForEditAdd":true
          },
          {
             "type":"string",
             "field":"Userupdated",
             "title":"Updated By",
             "width":160,
             "forEdit":true,
             "forCreate":true,
             "allowHiding":true,
             "visibleColumn":false,
             "isHiddenForEditAdd":true
          },
          {
             "type":"datetime",
             "field":"Datecreated",
             "title":"Created",
             "width":160,
             "forEdit":false,
             "forCreate":true,
             "allowHiding":true,
             "visibleColumn":false,
             "isHiddenForEditAdd":true
          },
          {
             "type":"string",
             "field":"Usercreated",
             "title":"Created By",
             "width":160,
             "forEdit":false,
             "forCreate":true,
             "allowHiding":true,
             "visibleColumn":false,
             "isHiddenForEditAdd":true
          }
       ],
       "DataTable":{
          "groupable":true,
          "filteralbe":true,
          "showNewBtn":true,
          "selectionMode":true,
          "showCommandBtn":true,
          "showViewDetailBtn":true,
          "showHideColumnMenu":true,
          "allowExportSelectedData":true
       }
    }
 ]
//     [
//         {
//             Id:1,
//             Title: 'Risks',
//             Category: 'Risk Management',
//             IsActive:true,
//             apiInfo: {
//                 create: {
//                     name: 'Risks',
//                     method: 'POST',
//                     dataToPassToService: '{}'
//                 },
//                 update: {
//                     name: 'Risks',
//                     method: 'PUT',
//                     dataToPassToService: '{}'
//                 },
//                 read: {
//                     name: 'Risks',
//                     method: 'GET',
//                     dataToPassToService: '{}'
//                 },
//                 delete: {
//                     name: 'Risks',
//                     method: 'DELETE'
//                 },

//             },
//             Columns: [
//                 {
//                     field: 'Id',
//                     title: 'Id',
//                     width: 80,
//                     type: 'string',
//                     allowHiding: false,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     visibleColumn: true,
//                     forCreate: false
//                 },
//                 {
//                     field: 'Title',
//                     title: 'Risk Name',
//                     // width: 160,
//                     type: 'string',
//                     isHiddenForEditAdd: false,
//                     forEdit: true,
//                     visibleColumn: true,
//                     forCreate: false
//                 },
//                 {
//                     field: 'CategoryName',
//                     title: 'Risk Category',
//                     // width: 160,
//                     type: 'string',
//                     visibleColumn: true,
//                     isHiddenForEditAdd: false,
//                     forEdit: true,
//                     forCreate: false
//                 },
//                 {
//                     field: 'RiskAreaTitle',
//                     title: 'Risk Area',
//                     // width: 160,
//                     type: 'string',
//                     visibleColumn: true,
//                     isHiddenForEditAdd: false,
//                     forEdit: true,
//                     forCreate: false
//                 },


//                 {
//                     field: 'IsActive',
//                     title: 'Active',
//                     width: 120,
//                     type: 'boolean',
//                     visibleColumn: true,
//                     allowHiding: true,
//                     isHiddenForEditAdd: false,
//                     forEdit: true,
//                     forCreate: false
//                 },

//                 {
//                     field: 'Dateupdated',
//                     title: 'Updated',
//                     width: 160,
//                     type: 'datetime',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     forCreate: true,
//                     sortOrder:'desc'
//                 },
//                 {
//                     field: 'Userupdated',
//                     title: 'Updated By',
//                     width: 160,
//                     type: 'string',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     forCreate: true
//                 },
//                 {
//                     field: 'Datecreated',
//                     title: 'Created',
//                     width: 160,
//                     type: 'datetime',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: false,
//                     forCreate: true
//                 },
//                 {
//                     field: 'Usercreated',
//                     title: 'Created By',
//                     width: 160,
//                     type: 'string',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: false,
//                     forCreate: true
//                 },
//             ],
//             AddEditField: [
//                 {
//                     ID:1,
//                     Type: 'TextField',
//                     Label: 'Title',
//                     Placeholder: 'Title',
//                     StateValue: 'title',
//                     field: 'Title',
//                     addeditfield: 'Title', //remove later
//                     IsMandatory: true,
//                     Value: '',
//                     Grid: { sm: 12, md: 12, lg: 6 }
//                 },
//                 { ID:2,
//                     Type: 'Choices',
//                     Label: 'Risk Category',
//                     Placeholder: 'Risk Category',
//                     StateValue: 'riskcategory',
//                     IsMultiple: false,
//                     APIListName: 'Riskcategories',
//                     APIListNameItems: [],
//                     field: 'CategoryID',
//                     addeditfield: 'Category', //remove later
//                     IsMandatory: true,
//                     Value: '',
//                     Grid: { sm: 12, md: 12, lg: 6 }
//                 },
//                 { ID:3,
//                     Type: 'Choices',
//                     Label: 'Risk Area',
//                     Placeholder: 'Risk Area',
//                     StateValue: 'riskarea',
//                     IsMultiple: false,
//                     APIListName: 'RiskAreas',
//                     APIListNameItems: [],
//                     field: 'RiskAreaID',
//                     addeditfield: 'Riskareaid', //remove later
//                     IsMandatory: true,
//                     Value: '',
//                     Grid: { sm: 12, md: 12, lg: 6 }
//                 },
//                 { ID:4,
//                     Type: 'CheckBox',
//                     Label: 'Is Active',
//                     StateValue: 'isactive',
//                     Value: true,
//                     field: 'IsActive',
//                     addeditfield: 'Isactive', //remove later
//                     Grid: { sm: 12, md: 12, lg: 6 }
//                 }
//             ],
//             DataTable:{
//                 filteralbe:true,
//                 groupable:true,
//                 selectionMode:true,
//                 showHideColumnMenu:true,
//                 allowExportSelectedData:true,
//                 showCommandBtn:true,
//                 showNewBtn:true,
//                 showViewDetailBtn:true,
//             }
//         },
//         {  
//             Id:2,
//             Title: 'Risk Category',
//             Category: 'Risk Management',
//             IsActive:true,
//             apiInfo: {
//                 create: {
//                     name: 'Riskcategories',
//                     method: 'POST',
//                     dataToPassToService: '{}'
//                 },
//                 update: {
//                     name: 'Riskcategories',
//                     method: 'PUT',
//                     dataToPassToService: '{}'
//                 },
//                 read: {
//                     name: 'Riskcategories',
//                     method: 'GET',
//                     dataToPassToService: '{}'
//                 },
//                 delete: {
//                     name: 'Riskcategories',
//                     method: 'DELETE'
//                 },

//             },
//             Columns: [
//                 {
//                     field: 'Id',
//                     title: 'ID',
//                     width: 80,
//                     type: 'string',
//                     allowHiding: false,
//                     visibleColumn: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     forCreate: false
//                 },
//                 {
//                     field: 'Title',
//                     title: 'Title',
//                     // width: 160,
//                     type: 'string',
//                     visibleColumn: true,
//                     isHiddenForEditAdd: false,
//                     forEdit: true,
//                     forCreate: false
//                 },
//                 {
//                     field: 'Dateupdated',
//                     title: 'Updated',
//                     width: 160,
//                     type: 'datetime',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     forCreate: true,
//                     sortOrder:'desc'
//                 },
//                 {
//                     field: 'Userupdated',
//                     title: 'Updated By',
//                     width: 160,
//                     type: 'string',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     forCreate: true
//                 },
//                 {
//                     field: 'Datecreated',
//                     title: 'Created',
//                     width: 160,
//                     type: 'datetime',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: false,
//                     forCreate: true
//                 },
//                 {
//                     field: 'Usercreated',
//                     title: 'Created By',
//                     width: 160,
//                     type: 'string',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: false,
//                     forCreate: true
//                 },
//             ],
//             AddEditField: [
//                 {
//                     Type: 'TextField',
//                     Label: 'Title',
//                     Placeholder: 'Title',
//                     StateValue: 'title',
//                     field: 'Title',
//                     addeditfield: 'Title', //remove later
//                     IsMandatory: true,
//                     Value: '',
//                     Grid: { sm: 12, md: 12, lg: 12 }
//                 }
//             ],
//             DataTable:{
//                 filteralbe:true,
//                 groupable:true,
//                 selectionMode:true,
//                 showHideColumnMenu:true,
//                 allowExportSelectedData:true,
//                 showCommandBtn:true,
//                 showNewBtn:true,
//                 showViewDetailBtn:true,
//             }
//         },
//         {
//             Id:3,
//             Title: 'Risk Area',
//             Category: 'Risk Management',
//             IsActive:true,
//             apiInfo: {
//                 create: {
//                     name: 'RiskAreas',
//                     method: 'POST',
//                     dataToPassToService: '{}'
//                 },
//                 update: {
//                     name: 'RiskAreas',
//                     method: 'PUT',
//                     dataToPassToService: '{}'
//                 },
//                 read: {
//                     name: 'RiskAreas',
//                     method: 'GET',
//                     dataToPassToService: '{}'
//                 },
//                 delete: {
//                     name: 'RiskAreas',
//                     method: 'DELETE'
//                 },

//             },
//             Columns: [
//                 {
//                     field: 'Id',
//                     title: 'ID',
//                     width: 80,
//                     type: 'string',
//                     allowHiding: false,
//                     visibleColumn: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     forCreate: false
//                 },
//                 {
//                     field: 'Title',
//                     title: 'Title',
//                     // width: 160,
//                     type: 'string',
//                     visibleColumn: true,
//                     isHiddenForEditAdd: false,
//                     forEdit: true,
//                     forCreate: false
//                 },
                
//                 {
//                     field: 'Isactive',
//                     title: 'Active',
//                     width: 120,
//                     type: 'boolean',
//                     allowHiding: true,
//                     visibleColumn: true,
//                     isHiddenForEditAdd: false,
//                     forEdit: true,
//                     forCreate: false
//                 },
//                 {
//                     field: 'Dateupdated',
//                     title: 'Updated',
//                     width: 160,
//                     type: 'datetime',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     forCreate: true,
//                     sortOrder:'desc'
//                 },
//                 {
//                     field: 'Userupdated',
//                     title: 'Updated By',
//                     width: 160,
//                     type: 'string',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     forCreate: true
//                 },
//                 {
//                     field: 'Datecreated',
//                     title: 'Created',
//                     width: 160,
//                     type: 'datetime',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: false,
//                     forCreate: true
//                 },
//                 {
//                     field: 'Usercreated',
//                     title: 'Created By',
//                     width: 160,
//                     type: 'string',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: false,
//                     forCreate: true
//                 },
//             ],
//             AddEditField: [
//                 {
//                     Type: 'TextField',
//                     Label: 'Title',
//                     Placeholder: 'Title',
//                     StateValue: 'title',
//                     field: 'Title',
//                     addeditfield: 'Title', //remove later
//                     IsMandatory: true,
//                     Value: '',
//                     Grid: { sm: 12, md: 12, lg: 12 }
//                 },
//                 {
//                     Type: 'CheckBox',
//                     Label: 'Is Active',
//                     StateValue: 'isactive',
//                     Value: true,
//                     field: 'IsActive',
//                     addeditfield: 'Isactive', //remove later
//                     Grid: { sm: 12, md: 12, lg: 12 }
//                 }
//             ],
//             DataTable:{
//                 filteralbe:true,
//                 groupable:true,
//                 selectionMode:true,
//                 showHideColumnMenu:true,
//                 allowExportSelectedData:true,
//                 showCommandBtn:true,
//                 showNewBtn:true,
//                 showViewDetailBtn:true,
//             }
//         },
//         {
//             Id:4,
//             Title: 'Risk Form',
//             Category: 'Risk Management',
//             IsActive:true,
//             apiInfo: {
//                 create: {
//                     name: 'Riskforms',
//                     method: 'POST',
//                     dataToPassToService: '{}'
//                 },
//                 update: {
//                     name: 'Riskforms',
//                     method: 'PUT',
//                     dataToPassToService: '{}'
//                 },
//                 read: {
//                     name: 'Riskforms',
//                     method: 'GET',
//                     dataToPassToService: '{}'
//                 },
//                 delete: {
//                     name: 'Riskforms',
//                     method: 'DELETE'
//                 },

//             },
//             Columns: [
//                 {
//                     field: 'Id',
//                     title: 'ID',
//                     width: 80,
//                     type: 'string',
//                     allowHiding: false,
//                     visibleColumn: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     forCreate: false
//                 },
//                 {
//                     field: 'Title',
//                     title: 'Title',
//                     // width: 160,
//                     type: 'string',
//                     visibleColumn: true,
//                     isHiddenForEditAdd: false,
//                     forEdit: true,
//                     forCreate: false
//                 },
//                 {
//                     field: 'Department',
//                     title: 'Department',
//                     // width: 160,
//                     type: 'string',
//                     visibleColumn: true,
//                     isHiddenForEditAdd: false,
//                     forEdit: true,
//                     forCreate: false
//                 },
                
//                 {
//                     field: 'Isactive',
//                     title: 'Active',
//                     width: 120,
//                     type: 'boolean',
//                     allowHiding: true,
//                     visibleColumn: true,
//                     isHiddenForEditAdd: false,
//                     forEdit: true,
//                     forCreate: false
//                 },
//                 {
//                     field: 'Dateupdated',
//                     title: 'Updated',
//                     width: 160,
//                     type: 'datetime',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     forCreate: true,
//                     sortOrder:'desc'
//                 },
//                 {
//                     field: 'Userupdated',
//                     title: 'Updated By',
//                     width: 160,
//                     type: 'string',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     forCreate: true
//                 },
//                 {
//                     field: 'Datecreated',
//                     title: 'Created',
//                     width: 160,
//                     type: 'datetime',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: false,
//                     forCreate: true
//                 },
//                 {
//                     field: 'Usercreated',
//                     title: 'Created By',
//                     width: 160,
//                     type: 'string',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: false,
//                     forCreate: true
//                 },
//             ],
//             AddEditField: [
//                 {
//                     Type: 'TextField',
//                     Label: 'Title',
//                     Placeholder: 'Title',
//                     StateValue: 'title',
//                     field: 'Title',
//                     addeditfield: 'Title', //remove later
//                     IsMandatory: true,
//                     Value: '',
//                     Grid: { sm: 12, md: 6, lg: 6 }
//                 },
//                 {
//                     Type: 'Choices',
//                     Label: 'Department',
//                     Placeholder: 'Department',
//                     StateValue: 'riskcategory',
//                     IsMultiple: false,
//                     APIListName: 'Departments',
//                     APIListNameItems: [],
//                     field: 'DepartmentId',
//                     addeditfield: 'Departmentid', //remove later
//                     IsMandatory: true,
//                     Value: '',
//                     Grid: { sm: 12, md: 6, lg: 6 }
//                 },                
//                 {
//                     Type: 'CheckBox',
//                     Label: 'Is Active',
//                     StateValue: 'isactive',
//                     Value: true,
//                     field: 'IsActive',
//                     addeditfield: 'Isactive', //remove later
//                     Grid: { sm: 12, md: 12, lg: 12 }
//                 },
//                 {
//                     Type: 'TextArea',
//                     Label: 'Model',
//                     Placeholder: 'Model',
//                     StateValue: 'riskcategory',
//                     field: 'Model',
//                     addeditfield: 'Model', //remove later
//                     Value: '',
//                     Grid: { sm: 12, md: 12, lg: 12 }
//                 },
//             ],
//             DataTable:{
//                 filteralbe:true,
//                 groupable:true,
//                 selectionMode:true,
//                 showHideColumnMenu:true,
//                 allowExportSelectedData:true,
//                 showCommandBtn:true,
//                 showNewBtn:true,
//                 showViewDetailBtn:true,
//             }
//         },
//         {
//             Id:5,
//             Title: 'Risk Control Measure',
//             Category: 'Risk Management',
//             IsActive:true,
//             apiInfo: {
//                 create: {
//                     name: 'Riskcontrolmeasures',
//                     method: 'POST',
//                     dataToPassToService: '{}'
//                 },
//                 update: {
//                     name: 'Riskcontrolmeasures',
//                     method: 'PUT',
//                     dataToPassToService: '{}'
//                 },
//                 read: {
//                     name: 'SearchControlMeasures',
//                     method: 'POST',
//                     dataToPassToService: '{}'
//                 },
//                 delete: {
//                     name: 'Riskcontrolmeasures',
//                     method: 'DELETE'
//                 },

//             },
//             Columns: [
//                 {
//                     field: 'Id',
//                     title: 'ID',
//                     width: 80,
//                     type: 'string',
//                     allowHiding: false,
//                     visibleColumn: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     forCreate: false
//                 },
//                 {
//                     field: 'Controlmeasure',
//                     title: 'Title',
//                     // width: 160,
//                     type: 'string',
//                     visibleColumn: true,
//                     isHiddenForEditAdd: false,
//                     forEdit: true,
//                     forCreate: false
//                 },
//                 {
//                     field: 'Risks',
//                     title: 'Risks',
//                     // width: 160,
//                     type: 'string',
//                     visibleColumn: true,
//                     isHiddenForEditAdd: false,
//                     forEdit: true,
//                     forCreate: false,
//                     isArray:true
//                 },
                
//                 {
//                     field: 'Isactive',
//                     title: 'Active',
//                     width: 120,
//                     type: 'boolean',
//                     allowHiding: true,
//                     visibleColumn: true,
//                     isHiddenForEditAdd: false,
//                     forEdit: true,
//                     forCreate: false
//                 },
//                 {
//                     field: 'Dateupdated',
//                     title: 'Updated',
//                     width: 160,
//                     type: 'datetime',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     forCreate: true,
//                     sortOrder:'desc'
//                 },
//                 {
//                     field: 'Userupdated',
//                     title: 'Updated By',
//                     width: 160,
//                     type: 'string',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: true,
//                     forCreate: true
//                 },
//                 {
//                     field: 'Datecreated',
//                     title: 'Created',
//                     width: 160,
//                     type: 'datetime',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: false,
//                     forCreate: true
//                 },
//                 {
//                     field: 'Usercreated',
//                     title: 'Created By',
//                     width: 160,
//                     type: 'string',
//                     visibleColumn: false,
//                     allowHiding: true,
//                     isHiddenForEditAdd: true,
//                     forEdit: false,
//                     forCreate: true
//                 },
//             ],
//             AddEditField: [
//                 {
//                     Type: 'TextField',
//                     Label: 'Title',
//                     Placeholder: 'Title',
//                     StateValue: 'title',
//                     field: 'Controlmeasure',
//                     addeditfield: 'Controlmeasure', //remove later
//                     IsMandatory: true,
//                     Value: '',
//                     Grid: { sm: 12, md: 12, lg: 12 }
//                 },
//                 {
//                     Type: 'Choices',
//                     Label: 'Risks',
//                     Placeholder: 'Risks',
//                     StateValue: 'Risks',
//                     IsMultiple: true,
//                     APIListName: 'Risks',
//                     APIListNameItems: [],
//                     field: 'Riskid',
//                     addeditfield: 'Riskid', //remove later
//                     IsMandatory: true,
//                     Value: '',
//                     Grid: { sm: 12, md: 12, lg: 12 }
//                 },
                
//                 {
//                     Type: 'CheckBox',
//                     Label: 'Is Active',
//                     StateValue: 'isactive',
//                     Value: true,
//                     field: 'IsActive',
//                     addeditfield: 'Isactive', //remove later
//                     Grid: { sm: 12, md: 12, lg: 12 }
//                 },
//             ],
//             DataTable:{
//                 filteralbe:true,
//                 groupable:true,
//                 selectionMode:true,
//                 showHideColumnMenu:true,
//                 allowExportSelectedData:true,
//                 showCommandBtn:true,
//                 showNewBtn:true,
//                 showViewDetailBtn:true,
//             }
//         },
//     ]
//     ;
