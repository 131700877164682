import * as React from 'react';
import utility from '../../../classes/utilityOld';
import { Config } from '../../../classes/config';
import Grid from '@mui/material/Grid';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';

import AlertDialog from '../../Common/AlertDialog';
import Spinner from '../../Common/ReusableComponents/Spinner';
import capitalize from '@mui/material/utils/capitalize';
import CheckBoxInput from '../../Common/ReusableComponents/CheckBox';
import SelectBoxInput from '../../Common/ReusableComponents/SelectInputBox';
import TextBoxInput from '../../Common/ReusableComponents/TextBox';

const Type: any = [
    { id: 'Date', text: 'Date' },
    { id: 'Text', text: 'Text' },
    { id: 'ChoiceBox', text: 'ChoiceBox' },
    { id: 'Choice', text: 'Choice' }
];
export default class EditDefectQuestion extends React.Component<any, any> {

    public defectTypeItems: any = [];

    constructor(props: any) {
        super(props);

        this.updateDefectQuestion = this.updateDefectQuestion.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.defectTypeItems = [];
        this.state = {
            isActive: false
        };
    }

    public componentDidMount() {
        //console.log('component load');
        this._getDefectTypeData();
    }
    public didMount() {
        if (this.props.details && this.props.mode === 'edit') {
            this.populateValue(this.props.details, this.props.mode);
        } else {
            this.setState({
                isActive: true,
                hideAlertButton: false,
                mode: this.props.mode
            })
        }
    }
    public populateValue(details: any, mode?: any) {
        this.setState({
            id: this.props.details.id,
            title: this.props.details.title,
            type: this.props.details.type ? { id: this.props.details.type, text: this.props.details.type } : '',
            sequenceno: this.props.details.sequenceno,
            controlName: this.props.details.controlName,
            defectType: this.props.details.defectTypeId ? { id: this.props.details.defectTypeId, text: this.props.details.defectType } : '',
            isActive: this.props.details.active,
            mode: this.props.mode
        });

    }
    private _getDefectTypeData() {

        utility.genericGetAPICall(`${Config.DefectCodeList}`).then((data: any) => {
            //console.log(data);
            this.defectTypeItems = [];

            for (let r of data) {
                this.defectTypeItems.push({ id: r.Id, text: r.Title });
            }
            this.setState({ defectTypeItems: this.defectTypeItems });
        }, (err) => {
            console.log(err);
        });
    }
    public updateDefectQuestion() {
        let dataToPassToService: any = {};

        if (!this.state.title) {
            this.setState({
                alertMessage: 'Please enter title',
                showAlert: true
            });
            return;
        }
        if (!this.state.type) {
            this.setState({
                alertMessage: 'Please select type',
                showAlert: true
            });
            return;
        }
        if (!this.state.sequenceno) {
            this.setState({
                alertMessage: 'Please enter sequence #',
                showAlert: true
            });
            return;
        }
        if (!this.state.controlName) {
            this.setState({
                alertMessage: 'Please enter control name',
                showAlert: true
            });
            return;
        }
        if (!this.state.defectType) {
            this.setState({
                alertMessage: 'Please select defect type',
                showAlert: true
            });
            return;
        }
        this.setState({
            alertMessage: <div className={'alertLoader'}><Spinner size='large' label="Loading..." /></div>,
            hideAlertButton: true,
            showAlert: true
        });

        dataToPassToService.Title = this.state.title;
        dataToPassToService.Type = this.state.type.id;
        dataToPassToService.Controlname = this.state.controlName;
        dataToPassToService.Sequenceno = +this.state.sequenceno;
        dataToPassToService.Defecttypeid = this.state.defectType.id;
        dataToPassToService.Isactive = this.state.isActive;
        //console.log("dataToPassToService", dataToPassToService);
        if (this.props.mode === 'edit') {
            dataToPassToService.id = this.state.id;
            dataToPassToService.updatedBy = this.props.currentUser.Email;
            dataToPassToService.updated = new Date();
            utility.genericUpdateAPICall(Config.defectQuestionList, this.state.id, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Defect question has been updated");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        } else {
            dataToPassToService.createdBy = this.props.currentUser.Email;
            dataToPassToService.created = new Date();
            utility.genericPostAPIcall(Config.defectQuestionList, dataToPassToService).then((data: any) => {
                //console.log(data);
                this.setState({ showAlert: false });
                this.clearFilter();
                this.props.onClick();
                this.props.message("Defect question has been created");
            }, (err: any) => {
                console.log(err);
                this.setState({
                    alertMessage: 'Something went wrong!',
                    hideAlertButton: false,
                    showAlert: true
                });
            });
        }
    }

    public clearFilter() {
        this.setState({
            title: '',
            type: '',
            controlName: '',
            sequenceno: '',
            defectType: ''
        });
        this.props.onClose();

    }
    private alert() {
        return (
            <AlertDialog
                showDialog={this.state.showAlert}
                message={this.state.alertMessage}
                title={Config.productTitle}
                onClose={() => this.setState({ alertMessage: '', showAlert: false })}
                hideAlertButton={this.state.hideAlertButton}
            />
        );
    }
    private handleValueChange = (event: any, fieldName: string) => {
        this.setState({ [fieldName]: event.value });
    }
    public handleDropdownChange(e: any, fieldName: string) {
        this.setState({ [fieldName]: e.selectedItem });
    }
    public render(): React.ReactElement<any> {

        return (
            <div>
                {this.state.showAlert && this.alert()}
                <Popup
                    width={'80vw'}
                    maxHeight={'85vh'}
                    minHeight={200}
                    height={'auto'}
                    showTitle={true}
                    showCloseButton={true}
                    title={`${this.props.mode && capitalize(this.props.mode)} Defect Question`}
                    dragEnabled={true}
                    hideOnOutsideClick={false}
                    visible={this.props.showModal}
                    // onContentReady={() => this.didMount()}
                    //closeOnOutsideClick={true}
                    onVisibleChange={this.clearFilter}
                    onShowing={() => this.didMount()}

                >
                    <ScrollView width='100%' height='100%' showScrollbar='onScroll' scrollByThumb={true}>
                        <div className='popContentDiv'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Title *"
                                        // pattern={"[A-Za-z]+"}
                                        // minLength={2}
                                        value={this.state.title}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'title')}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Control Name *"
                                        // pattern={"[A-Za-z]+"}
                                        // minLength={2}
                                        value={this.state.controlName}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'controlName')}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SelectBoxInput
                                        stylingMode='underlined'
                                        displayExpr="text"
                                        valueExpr="id"
                                        showClearButton={true}
                                        items={Type}
                                        selectedItem={this.state.type}
                                        label='Type'
                                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'type')}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextBoxInput
                                        stylingMode='underlined'
                                        label="Sequence # *"
                                        mode='number'
                                        // pattern={"[A-Za-z]+"}
                                        // minLength={2}
                                        value={this.state.sequenceno}
                                        onValueChanged={(e: any) => this.handleValueChange(e, 'sequenceno')}
                                        required={true}
                                    /></Grid>
                                <Grid item xs={12} sm={6}>
                                    <SelectBoxInput
                                        stylingMode='underlined'
                                        displayExpr="text"
                                        valueExpr="id"
                                        showClearButton={true}
                                        items={this.state.defectTypeItems}
                                        selectedItem={this.state.defectType}
                                        label='Defect Type'
                                        onSelectionChanged={(e: any) => this.handleDropdownChange(e, 'defectType')}

                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>

                                    <br />
                                    <br />
                                    <CheckBoxInput value={this.state.isActive} onValueChanged={(e: any) => this.handleValueChange(e, 'isActive')} text={'Is Active'} />

                                </Grid>
                            </Grid>
                        </div>
                    </ScrollView>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'close',
                            text: 'Cancel',
                            stylingMode: "outlined",
                            onClick: this.clearFilter,
                        }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            icon: 'save',
                            text: 'Save',
                            stylingMode: "contained",
                            onClick: this.updateDefectQuestion,
                        }}
                    />
                </Popup>
            </div >
        );
    }

}

