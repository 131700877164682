import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import Popup, { ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import Divider from '@mui/material/Divider'

interface DisplayDetailsModalProps {
  details?: any
  showModal: boolean
  onClick: () => void
  columns?: any[]
  data?:any
}

const DisplayDetailsModal: React.FC<DisplayDetailsModalProps> = props => {
  useEffect(() => {
    // Component did mount
    // console.log('component load');
  }, [])

  const getValue = (item: any) => {
    let value: any = ''
    if (props.details[item.field]) {
      if (item.type === 'datetime' || item.type === 'date') {
        const tempValue = props.details[item.field]
        value = moment(tempValue).format('DD/MM/YY h:mm a')
      } else {
        value = props.details[item.field]
      }
    }
    
return value
  }

  return (
    <div>
      <Popup
        width={'60vw'}
        maxHeight={'85vh'}
        minHeight={200}
        height={'auto'}
        showTitle={true}
        showCloseButton={true}
        title={'Details'}
        dragEnabled={false}
        hideOnOutsideClick={false}
        visible={props.showModal}
        closeOnOutsideClick={false}
        onVisibleChange={props.onClick}
      >
        <ScrollView width='100%' height='100%'>
          <div className='popContentDiv viewModal'>
            {!props.details && (
              <div>
                <h4 className='alignCenter'>No data available.</h4>
                <br />
              </div>
            )}

            {props.details && props.columns && (
              <div>
                {props.columns.map((item: any) => (
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                      {item.title}:
                    </Grid>
                    <Grid item xs={6} sm={9} className='ms-fontWeight-semibold'>
                      {getValue(item)}
                    </Grid>
                  </Grid>
                ))}
              </div>
            )}
          </div>
        </ScrollView>
        {/* <Divider /> */}
        <ToolbarItem
          widget='dxButton'
          toolbar='bottom'
          location='after'
          options={{
            icon: '',
            text: 'Cancel',
            stylingMode: 'outlined',
            onClick: props.onClick
          }}
        />
      </Popup>
    </div>
  )
}

export default DisplayDetailsModal
