import React, { useState, useEffect, useCallback } from 'react'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import Spinner from '../../Common/ReusableComponents/Spinner'
import global from '../../../classes/global'
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'

interface Dept {
  department_id: number
  department_code: string
  department_name: string
}

interface Teams {
  team_id: number
  team_ids: any
  team_code: string
  team_name: string
  department_id: number
}

interface LoadResult {
  data: any[]
  totalCount: number
}

const WorkRequestActivities = (props: any) => {

  if (props.role !== global.worxOnline_Admin) {
    window.open('#/AccessDenied', '_self')
  }
  const [dataLoaded, setDataLoaded] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [items, setItems] = useState<any[]>([])
  const [depts, setDepts] = useState<Dept[]>([])
  const [teams, setTeams] = useState<Teams[]>([])

  const getFilteredTeams = (options: { data?: any }) => {
    console.log('getFilteredTeams options:', options);
    if (options.data) {
      console.log('Department ID:', options.data.department_id);
    }
  
    return {
      store: teams,
      filter: options.data ? ['department_id', '=', options.data.department_id] : null
    };
  };

  const setTeamValue = (rowData: any, value: number) => {
    rowData.team_ids = value || []
    const team = teams?.find(t => t.team_ids === value)
    if (team) {
      rowData.team_name = team.team_name
    }
  }

  const setDepartmentValue = (rowData: any, value: number) => {
    rowData.department_id = value || null
    rowData.team_ids = null // Reset team_id to null
    const dept = depts.find(d => d.department_id === value)
    if (dept) {
      rowData.department_name = dept.department_name
      const filteredTeams = teams.filter(t => t.department_id === value)
      if (filteredTeams.length === 1) {
        rowData.team_ids = filteredTeams[0].team_ids
        rowData.team_name = filteredTeams[0].team_name
      }
    }
  }

  const columns = [
    {
      field: 'id',
      title: 'ID',
      width: 80,
      type: 'string',
      visibleColumn: false,
      allowHiding: false,
      formItem: {
        visible: false
      }
    },
    {
      field: 'title',
      title: 'Title',
      width: 360,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    {
      field: 'description',
      title: 'Description',
      width: 360,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    {
      field: 'department_id',
      title: 'Department',
      // width: 280,
      type: 'string',
      allowHiding: true,
      setCellValue: setDepartmentValue,
      Lookup: {
        dataSource: depts,
        valueExpr: 'department_id',
        displayExpr: 'department_name'
      }
    },
    {
      field: 'team_ids',
      title: 'Team',
      // width: 280,
      type: 'string',
      allowHiding: true,
      setCellValue: setTeamValue,
      Lookup: {
        dataSource: getFilteredTeams,
        valueExpr: 'team_ids',
        displayExpr: 'team_name'
      },
      cellRender: (cellData: any) => {
        return cellData.data.team_titles
      },
      formItem: {
        visible: true,
        editorType: 'dxTagBox',
        editorOptions: {
          displayExpr: 'team_name',
          valueExpr: 'team_ids',
          placeholder: 'Select Team',
          showSelectionControls: true
        }
      }
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    },
    {
      field: 'created_by',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const deptResponse = await utility.genericGetAPICall(`${Config.DepartmentList}`)
        const deptData = deptResponse.filter((r: any) => r.is_active === true).map((r: any) => ({
          department_id: r.id,
          department_code: r.code,
          department_name: r.title
        }))
        setDepts(deptData);

        const teamResponse = await utility.genericGetAPICall(`${Config.TeamList}`)
        const teamData = teamResponse.filter((r: any) => r.is_active === true).map((r: any) => ({
          team_ids: r.id,
          team_code: r.code,
          team_name: r.title,
          department_id: r.department_id
        }))
       setTeams(teamData);
      
        await fetchItemsData(teamData);

      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const fetchItemsData = async (teamData?: any) => {
    setLoading(true)
    try {
      const itemsResponse:any = await utility.genericGetAPICallForList(Config.SeachWorkrequesttypes, 'POST', {});
      let items: any[] = []

      for (let r of itemsResponse) {
        items.push({
          ...r,
          id: r.id,
          team_titles: r.team_ids ? r.team_ids.map((t: any) => teamData.find((td: any) => td.team_ids === t)?.team_name).join(', ') : '',
          active: r.is_active ? true : false,
        })
      }

      items = utility._sortItems(items, 'id', true)
      setItems(items)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
      return {
        data: items,
        totalCount: items.length
      }
    },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded')
      setLoading(false)
      setDataLoaded(true)
    },
    update: async (key: any, values: any) => {
      try {
        await utility.genericUpdatePatchAPICall(Config.WRTlistname, key, values)
        await fetchItemsData()
      } catch (error: any) {
        throw error
      }
    },
  })

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'Work Request Activities'}
              columnChooser={'workRequestActivityColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              //defaultfiltervalue={filterValue}
              showMoreBtn={true}
              showAddIcon={false}
              showDeleteIcon={false}
              visible={dataLoaded}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  )
}

export default WorkRequestActivities
