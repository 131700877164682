import React, { useState } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'

interface DeleteDialogProps {
  showDialog: any
  message: any
  title: any
  onClose: any
  hideAlertButton?: any
  CancelhideAlertButton?: any
  onOkButton: any
  onCancelButton: any
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  showDialog,
  message,
  title,
  onClose,
  hideAlertButton,
  CancelhideAlertButton,
  onOkButton,
  onCancelButton
}) => {
  const [showDialogState, setShowDialogState] = useState(showDialog)

  const closeDialog = (): void => {
    setShowDialogState(false)
    onCancelButton()
  }

  const OKcloseDialog = (): void => {
    setShowDialogState(false)
    onOkButton()
  }

  return (
    <div>
      <Popup
        width={300}
        maxHeight={300}
        minHeight={200}
        height={'auto'}
        showTitle={true}
        showCloseButton={false}
        title={title}
        dragEnabled={false}
        hideOnOutsideClick={false}
        visible={showDialogState}
        onVisibleChange={closeDialog}
      >
        <div className='popContentDiv' style={{ fontSize: '16px' }}>
          {message}
        </div>
        {!hideAlertButton && (
          <ToolbarItem
            widget='dxButton'
            toolbar='bottom'
            location='after'
            options={{
              text: 'Cancel',
              stylingMode: 'outlined',
              onClick: closeDialog
            }}
          />
        )}
        {!hideAlertButton && (
          <ToolbarItem
            widget='dxButton'
            toolbar='bottom'
            location='after'
            options={{
              icon: 'close',
              text: 'Ok',
              type: 'danger',
              stylingMode: 'contained',
              onClick: OKcloseDialog
            }}
          />
        )}
      </Popup>
    </div>
  )
}

export default DeleteDialog
