import React, { useEffect, useState } from 'react'
import global from '../../../classes/global'
import { Config } from '../../../classes/config'
import utility from '../../../classes/utilityOld'

interface ITestFrameState {
  iFramePanelOpenedforDetail: boolean
  iFrameUrl: string
}

const AssetMapforDetail: React.FC<any> = props => {
  const [state, setState] = useState<ITestFrameState>({
    iFramePanelOpenedforDetail: false,
    iFrameUrl: global.AssetMapURL
  })

  const _onIframeLoaded = () => {
    window.addEventListener('message', handleIframeTaskfordetail)
  }

  const handleIframeTaskfordetail = (e: any) => {
    if (e.data.command === 'asset selected') {
      const mapProps = e.data.assets[0]
      const properties = JSON.parse(mapProps.properties)

      if (props.selectMode === true) {
        props.selectAssets(properties)
        window.removeEventListener('message', handleIframeTaskfordetail)
      } else {
        const searchAssetAPi = `${Config.AssetSearchAPI}`
        let assetIDFromMap: any
        const dataToPassToService: any = {}
        dataToPassToService.erp_asset_id = [mapProps.id]
        utility.genericPostAPIcall(searchAssetAPi, dataToPassToService).then(
          (data: any) => {
            if (data && data.length > 0 && data[0].id) {
              assetIDFromMap = data[0].id
              const location = window.location.origin + '/search/assets/AssetDetail/?AssetID=' + assetIDFromMap
              window.removeEventListener('message', handleIframeTaskfordetail)
              window.open(location, '_self')
            }
          },
          err => {
            console.log(err)
            console.log('unable to open Asset Detail page. Please try again')
          }
        )
      }
      setState(prevState => ({
        ...prevState,
        iFramePanelOpenedforDetail: false
      }))
    }
  }

  return (
    <div>
      {/* eslint-disable-next-line */}
      <iframe
        src={state.iFrameUrl}
        allowFullScreen={true}
        width={'100%'}
        style={{ height: '82vh' }}
        onLoad={_onIframeLoaded}
      />
    </div>
  )
}

export default AssetMapforDetail
