import React, { useState, useEffect } from 'react'
import ArrayStore from 'devextreme/data/array_store'
import List from 'devextreme-react/list'
import { LoadIndicator } from 'devextreme-react/load-indicator'

interface SchedulerCrewListViewProps {
  crews: any[]
  workorders: any[]
  onCrewSelected: boolean
  selectedWorkorderbyCrew: (selectedWorkorder: any, selectedCrew: any) => void
  onDragOver: (e: any) => void
  onDrop: (e: any, crew: any) => void
  stopOnCrewSelected: () => void
  view?: any
}

const SchedulerCrewListView: React.FC<SchedulerCrewListViewProps> = props => {
  const [group, setGroup] = useState<string>('teamName')
  const [selectedItem, setSelectedItem] = useState<number[]>([])
  const [selectedWorkorderbyCrew, setSelectedWorkorderbyCrew] = useState<any[]>([])
  const [selectedCrewObj, setSelectedCrewObj] = useState<any | null>(null)

  useEffect(() => {
    const crewId = localStorage.getItem('selectedcrewId')
      ? Number(localStorage.getItem('selectedcrewId'))
      : props.crews[0].id

    const selectedCrewObj = props.crews.find(c => c.id === crewId)
    const selectedWorkorderbyCrew = props.workorders.filter(wo => wo.crew === crewId)

    setSelectedItem([crewId])
    setSelectedWorkorderbyCrew(selectedWorkorderbyCrew)
    setSelectedCrewObj(selectedCrewObj)

    props.selectedWorkorderbyCrew(selectedWorkorderbyCrew, selectedCrewObj)
  }, [props.crews, props.workorders])

  const handleGroupChange = (value: string) => {
    setGroup(value)
  }

  const handleListSelectionChange = (selectedCrew: any) => {
    const selectedWorkorderbyCrew = props.workorders.filter(wo => wo.crew === selectedCrew.id)

    localStorage.setItem('selectedcrewId', selectedCrew.id?.toString())
    setSelectedItem([selectedCrew.id])
    setSelectedWorkorderbyCrew(selectedWorkorderbyCrew)
    setSelectedCrewObj(selectedCrew)

    props.selectedWorkorderbyCrew(selectedWorkorderbyCrew, selectedCrew)
  }

  const afterInit = () => {
    const crewID = selectedItem.length > 0 ? selectedItem[0] : props.crews[0].id
    const selectedWorkorderbyCrew = props.workorders.filter(wo => wo.crew === crewID)

    setSelectedWorkorderbyCrew(selectedWorkorderbyCrew)
    props.selectedWorkorderbyCrew(selectedWorkorderbyCrew, selectedCrewObj)
    props.stopOnCrewSelected()
  }

  const dataSourceOptions = {
    store: new ArrayStore({
      data: props.crews,
      key: 'id'
    }),
    group: group,
    searchExpr: ['text']
  }

  useEffect(() => {
    if (props.onCrewSelected) {
      afterInit()
    }
  }, [props.onCrewSelected])

  const renderListGroup = (group: any, id: any) => {
    return <div className='city'>{group.key}</div>
  }

  const renderCrewListItem = (ev: any, index: any, props: any) => {
    const totalWOPerCrew = props.workorders.filter((wo: any) => wo.crew === ev.id)

    return (
      <div
        className='schedulerResourceCell'
        onClick={(ev: any) => handleListSelectionChange(ev)}
        onDragOver={(e: any) => props.onDragOver(e)}
        onDrop={(e: any) => props.onDrop(e, ev)}
      >
        <div className='displayFlex'>
          <div className='crewTitleColorScheduler' style={{ backgroundColor: ev.color ? ev.color : '#333' }}></div>
          <div>
            <div className='schedulerResourceCellText schedulerListCrewTitle' title={ev.text}>
              {ev.text}
            </div>
            <div className='schedulerResourceCellWO'>{`${totalWOPerCrew.length} workorder${
              totalWOPerCrew.length > 1 ? 's' : ''
            }`}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className={'ptlr-20'}>Crews ({props.crews.length})</div>
      {!props.onCrewSelected ? (
        <div className={'m-10 p-10'}>
          <List
            selectionMode='single'
            dataSource={dataSourceOptions}
            grouped={true}
            searchEnabled={true}
            selectedItemKeys={selectedItem}
            itemRender={(ev, index) => renderCrewListItem(ev, index, props)}
            groupRender={(group, Id) => renderListGroup(group, Id)}
            elementAttr={{ class: 'list' }}
            collapsibleGroups={true}
            activeStateEnabled={true}
            scrollingEnabled={true}
          />
        </div>
      ) : (
        <LoadIndicator id='large-indicator' height={60} width={60} />
      )}
    </div>
  )
}

export default SchedulerCrewListView
