import React from 'react'
import { HashRouter, Route, NavLink } from 'react-router-dom'
import { CacheSwitch } from 'react-router-cache-route'
import { ActiveTabProvider } from './ActiveTabContext'
// import PersistentDrawer from './DrawerList';

import AtenLogo from 'images/AtenLogo.png'
import AtenLogoLight from 'images/AtenLogoLight.png'

import global from '../../classes/global'
//import Home from './components/Home/Home';
import SignIn from '../Login/Login'
import Navigation from './Navigation'
import AppBar from './AppBar'
import Dashboard from '../Dashboard/Dashboard'
import Scheduler from '../Scheduler/Scheduler'
import CreateWO from '../Search/Workorders/CreateWorkOrder/CreateWO'
import Searchworkorder from '../Search/Workorders/SearchWorkOrders/SearchWorkOrders'
import Assets from '../Search/Assets/Assets'
import SearchWODetail from '../Search/Workorders/SearchWorkOrders/SearchWorkOrderDetail'
import AssetDetail from '../Search/Assets/AssetDetail/AssetDetail'
import SearchTimesheet from '../Search/Timesheet/SearchTimesheet'

// admin pages routing
import AdminHomePage from '../Administrator/AdminHome/AdminHome'
import AdminListBuilder from '../Administrator/AdminListBuilder/AdminListBuilder'

import Employees from '../Administrator/Employee/Employee'
import Crews from '../Administrator/Crew/Crew'
import Departments from '../Administrator/Department/Department'
import Teams from '../Administrator/Teams/Teams'
import Teams1 from '../Administrator/Teams/Teams1'
import Roles from '../Administrator/Role/Role'
import Activities from '../Administrator/Activities/Activities'
import Test from '../Administrator/Activities/TestTreeView'
import WorkRequestActivities from '../Administrator/WorkRequestActivities/WorkRequestActivities'
import Equipment from '../Administrator/Equipment/Equipment'
import Suburbs from '../Administrator/Suburbs/Suburbs'
import Status from '../Administrator/Status/Status'
import Priority from '../Administrator/Priority/Priority'
import Forms from '../Administrator/Forms/Forms'
import PrestartForms from '../Administrator/PrestartForms/PrestartForms'
import Materials from '../Administrator/Materials/Materials'

import Swms from '../Administrator/SWMS/swms'
import Tcp from '../Administrator/TCP/tcp'
import WorkTypeField from '../Administrator/WorkTypeFields/WorkTypeField'
import DefectCode from '../Administrator/DefectCodes/DefectCode'
import DefectQuestion from '../Administrator/DefectQuestions/DefectQuestion'
import AssetQuestion from '../Administrator/AssetQuestions/AssetQuestion'
import RiskCategory from '../Administrator/RiskCategories/Riskcategory'
import RiskForm from '../Administrator/RiskForms/RiskForm'
import RiskGroup from '../Administrator/RiskGroup/RiskGroup'
import ShowListItems from '../Administrator/RenderListItems/ShowListItems'
import RiskControlMeasure from '../Administrator/RiskControlMeasure/RiskControlMeasure'
import Counters from '../Administrator/Counter/Counter'
import Risks from '../Administrator/Risks/Risk'
import ClearStorage from '../Administrator/ClearStorage/ClearStorage'
import OnHoldReasons from '../Administrator/OnHoldReasons/OnHoldReason'
import Suppliers from '../Administrator/Suppliers/Suppliers'
import IntegrationStatus from '../Administrator/IntegrationStatus/IntegrationStatus'
import Events from '../Administrator/Events/Events'
import LoginDetails from '../Administrator/LoginDetails/LoginDetails'
import WeeklyTimesheet from '../Administrator/WeeklyTimesheet/WeeklyTimesheet'
import PlantUsage from '../Search/PlantUsage/PlantUsage'
import EquipmentAndVehicles from '../Search/EquipmentAndVehicles/EquipmentAndVehicles'
import PrestartChecklist from '../Search/PrestartChecklist/PrestartChecklist'
import InspectionForm from '../Search/InspectionForm/InspectionForm'

import 'devextreme/dist/css/dx.light.css'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import HomeIcon from '@mui/icons-material/Home'
import GridViewIcon from '@mui/icons-material/GridView'
import DonutSmallIcon from '@mui/icons-material/DonutSmall'
import SettingsIcon from '@mui/icons-material/Settings'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import { styled } from '@mui/system'


interface WOProps {
  role?: any
  Department?: any
  currentUser?: any
  pageListItems?: any
  isContractor?: any
  team?: any
  details?: any
}

type Anchor = 'top' | 'left' | 'bottom' | 'right'

const WorxOnline = React.memo((homeprops: WOProps) => {
  const handleMainContentClick  = (event: any) => {
    event.stopPropagation()
  }
  return (
    <>
    
    <div className='navigation-header-appbar'>
          <AppBar />
        </div>
    <main className='layout-page-content'>
    {/* <Card> */}
        <CacheSwitch>
          <Route exact path='/' render={(props: any) => <Dashboard {...props} {...homeprops} />} />
          <Route exact path='/SignIn' render={(props: any) => <SignIn {...props} />} />
          <Route exact path='/CreateWorkorder' render={(props: any) => <CreateWO {...props} {...homeprops} />} />
          <Route exact path='/Assets' render={(props: any) => <Assets {...props} {...homeprops} />} />
          <Route exact path='/SearchTimesheet' render={(props: any) => <SearchTimesheet {...props} {...homeprops} />} />
          <Route exact path='/AssetDetail' render={(props: any) => <AssetDetail {...props} {...homeprops} />} />
          <Route exact path='/EquipmentAndVehicles' render={(props: any) => <EquipmentAndVehicles {...props} {...homeprops} />} />
          <Route exact path='/PrestartChecklist' render={(props: any) => <PrestartChecklist {...props} {...homeprops} />} />
          <Route exact path='/InspectionForm' render={(props: any) => <InspectionForm {...props} {...homeprops} />} />
          <Route exact path='/Searchworkorder' render={(props: any) => <Searchworkorder {...props} {...homeprops} />} />
          <Route exact path='/WorkOrderDetail' render={(props: any) => <SearchWODetail {...props} {...homeprops} />} />
          <Route exact path='/Scheduler' render={(props: any) => <Scheduler {...props} {...homeprops} />} />

          {/* Admin Pages Routing */}
          <Route
            exact
            path='/AdminListBuilder'
            render={(props: any) => <AdminListBuilder {...props} {...homeprops} />}
          />
          <Route exact path='/AdminHomePage' render={(props: any) => <AdminHomePage {...props} {...homeprops} />} />
          <Route exact path='/Employees' render={(props: any) => <Employees {...props} {...homeprops} />} />
          <Route exact path='/Crews' render={(props: any) => <Crews {...props} {...homeprops} />} />
          <Route exact path='/Departments' render={(props: any) => <Departments {...props} {...homeprops} />} />
          <Route exact path='/Teams' render={(props: any) => <Teams {...props} {...homeprops} />} />
          {/* <Route exact path='/Teams1' render={(props: any) => <Teams1 {...props} {...homeprops} />} /> */}
          <Route exact path='/Roles' render={(props: any) => <Roles {...props} {...homeprops} />} />
          <Route exact path='/Activities' render={(props: any) => <Activities {...props} {...homeprops} />} />
          {/* <Route exact path='/Test' render={(props: any) => <Test {...props} {...homeprops} />} /> */}
          <Route
            exact
            path='/WorkRequestActivities'
            render={(props: any) => <WorkRequestActivities {...props} {...homeprops} />}
          />
          <Route exact path='/Equipment' render={(props: any) => <Equipment {...props} {...homeprops} />} />
          <Route exact path='/Events' render={(props: any) => <Events {...props} {...homeprops} />} />

          <Route exact path='/Suburbs' render={(props: any) => <Suburbs {...props} {...homeprops} />} />
          <Route exact path='/Status' render={(props: any) => <Status {...props} {...homeprops} />} />
          <Route exact path='/Priority' render={(props: any) => <Priority {...props} {...homeprops} />} />
          <Route exact path='/Forms' render={(props: any) => <Forms {...props} {...homeprops} />} />
          <Route exact path='/Materials' render={(props: any) => <Materials {...props} {...homeprops} />} />
          <Route exact path='/PrestartForms' render={(props: any) => <PrestartForms {...props} {...homeprops} />} />
          <Route exact path='/Tcp' render={(props: any) => <Tcp {...props} {...homeprops} />} />
          <Route exact path='/Swms' render={(props: any) => <Swms {...props} {...homeprops} />} />
          <Route exact path='/Counters' render={(props: any) => <Counters {...props} {...homeprops} />} />
          {/* <Route exact path="/ClearStorage" render={(props) => (<ClearStorage {...props} {...homeprops} />)} /> */}
          <Route exact path='/WorkTypeField' render={(props: any) => <WorkTypeField {...props} {...homeprops} />} />
          <Route exact path='/Suppliers' render={(props: any) => <Suppliers {...props} {...homeprops} />} />
          <Route exact path='/OnHoldReasons' render={(props: any) => <OnHoldReasons {...props} {...homeprops} />} />
          <Route exact path='/DefectCode' render={(props: any) => <DefectCode {...props} {...homeprops} />} />
          <Route exact path='/DefectQuestion' render={(props: any) => <DefectQuestion {...props} {...homeprops} />} />
          <Route exact path='/AssetQuestion' render={(props: any) => <AssetQuestion {...props} {...homeprops} />} />
          <Route exact path='/ShowListItems' render={(props: any) => <ShowListItems {...props} {...homeprops} />} />
          <Route
            exact
            path='/IntegrationStatus'
            render={(props: any) => <IntegrationStatus {...props} {...homeprops} />}
          />
          {/* <Route exact path="/SearchWorkRequest" render={(props: any) => (<SearchWorkRequest {...props} {...homeprops} />)} /> */}
          <Route exact path='/RiskGroup' render={(props: any) => <RiskGroup {...props} {...homeprops} />} />
          <Route exact path='/RiskCategory' render={(props: any) => <RiskCategory {...props} {...homeprops} />} />
          <Route exact path='/RiskForm' render={(props: any) => <RiskForm {...props} {...homeprops} />} />
          <Route exact path='/Risks' render={(props: any) => <Risks {...props} {...homeprops} />} />
          <Route exact path='/LoginDetails' render={(props: any) => <LoginDetails {...props} {...homeprops} />} />
          <Route
            exact
            path='/RiskControlMeasure'
            render={(props: any) => <RiskControlMeasure {...props} {...homeprops} />}
          />
          <Route exact path='/WeeklyTimesheet' render={(props: any) => <WeeklyTimesheet {...props} {...homeprops} />} />
          <Route exact path='/PlantUsage' render={(props: any) => <PlantUsage {...props} {...homeprops} />} />
        </CacheSwitch>
        {/* </Card> */}
    </main>
    
    </>
  )
})
export default WorxOnline
