import React, { useEffect, useState, useRef, useCallback } from 'react'
import utility from '../../../classes/utility'
import { Config } from '../../../classes/config'
import DataTable from '../../Common/CustomASDataGrid'
import Spinner from '../../Common/ReusableComponents/Spinner'
import CustomStore from 'devextreme/data/custom_store'
import { LoadOptions } from 'devextreme/data'
import TreeViewComponent from '../../Common/ReusableComponents/TreeViewComponent'
import CheckBox from '../../Common/ReusableComponents/CheckBox';

interface Dept {
  department_id: number
  department_name: string
}

interface Team {
  team_ids: number
  team_name: string
  department_id: number
}

interface LoadResult {
  data: any[]
  totalCount: number
}

interface RiskGroup {
  id: number
  title: string
  is_active: boolean
  category_id: number
}

interface RiskCategory {
  id: number
  title: string
  risk_groups: RiskGroup[]
}
let selectedRiskGroups: any = []

const Activities: React.FC<any> = props => {
  const [dataLoaded, setDataLoaded] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [forms, setForms] = useState<any[]>([])
  const [workGroups, setWorkGroups] = useState<any[]>([])
  const [riskForms, setRiskForms] = useState<any[]>([])
  const [depts, setDepts] = useState<Dept[]>([])
  const [teams, setTeams] = useState<Team[]>([])
  const [riskCategories, setRiskCategories] = useState<RiskCategory[]>([])
  const [parentActivities, setParentActivities] = useState<any[]>([])
  const [activity, setActivity] = useState<any[]>([])
  const filterValue = [['is_active', '=', true]]
  const [selectedKeys, setSelectedKeys] = useState<any[]>([])
  const treeViewRef = useRef(null)

  const transformToTreeStructure = (data: any, selectedIds: any) => {
    return data.map((category: any) => {
      const items = category.risk_groups.map((group: any) => ({
        id: `group-${group.id}`,
        title: group.title,
        is_active: group.is_active,
        category_id: group.category_id,
        parentId: category.id,
        selected: selectedIds.includes(group.id) // Mark as selected if in selectedIds
      }))
  
      // Determine if any item is selected
      const isExpanded = items.some((item: any) => item.selected)
  
      return {
        id: category.id,
        title: category.title,
        created: category.created,
        created_by: category.created_by,
        risk_groups: category.risk_groups,
        parentId: category.parentId,
        items: items,
        expanded: isExpanded,
        //selected: selectedIds.includes(category.id)
      }
    })
  }
  
  const woSubcategoryItems: any = [
    { category_id: 'Reactive', category_text: 'Reactive' },
    { category_id: 'Scheduled', category_text: 'Scheduled' }
  ]

  const getFilteredTeams = (options: { data?: any }) => ({
    store: teams,
    filter: options.data ? ['department_id', '=', options.data.department_id] : null
  })

  const setTeamValue = (rowData: any, value: number) => {
    rowData.team_ids = value || []
    const team = teams?.find(d => d.team_ids === value)
    if (team) {
      rowData.team_name = team.team_name
    }
  }

  const setDepartmentValue = (rowData: any, value: number) => {
    rowData.department_id = value || null
    const dept = depts.find(d => d.department_id === value)
    if (dept) {
      rowData.department_name = dept.department_name
    }
  }

  const toggleRiskAssessmentValue = (rowData: any, value: boolean) => {
    rowData.risk_assessment_required = value
    if (!value) {
      rowData.high_risk = false
      rowData.reuse_risk_assessment_for_days = false
    } else {
      rowData.reuse_risk_assessment_for_days = false;
    }
  }

  const toggleHighRiskValue = (rowData: any, value: boolean) => {
    rowData.high_risk = value
    if (value) {
      rowData.reuse_risk_assessment_for_days = false
    } else {
      rowData.reuse_risk_assessment_for_days = false
    }
  }

  const toggleReuseRiskAssessmentValue = (rowData: any, value: boolean) => {
    rowData.reuse_risk_assessment_for_days = value
    if (value) {
      rowData.high_risk = false
    } else {
      rowData.high_risk = false
    }
  }

  const setFormValue = (rowData: any, value: number) => {
    rowData.form_id = value || null
    const form = forms.find(d => d.form_id === value)
    if (form) {
      rowData.form_name = form.form_name
    }
  }

  const setRiskFormValue = (rowData: any, value: number) => {
    rowData.risk_form_id = value || null
    const riskForm = riskForms.find(d => d.risk_form_id === value)
    if (riskForm) {
      rowData.risk_form_name = riskForm.risk_form_name
    }
  }

  const setCategoryValue = (rowData: any, value: number) => {
    rowData.category_id = value || null
    const category = woSubcategoryItems.find((d: any) => d.category_id === value)
    if (category) {
      rowData.category_text = category.category_text
    }
  }

  const setRiskGroupIdValue = (rowData: any, value: number) => {
    rowData.risk_group_ids = value || []
  }

  const setWorkGroupValue = (rowData: any, value: number) => {
    rowData.work_group_ids = value || []
    const wrkgrp = workGroups?.find(d => d.work_group_ids === value)
    if (wrkgrp) {
      rowData.workgroup_name = wrkgrp.workgroup_name
    }
  }

  const onUpdateSelectedKeys = useCallback((selectedKeys: number[]) => {
    // console.log('Selected Keys:', selectedKeys)
    selectedRiskGroups = selectedKeys
 }, []);

  const columns = [
    {
      field: 'id',
      title: 'ID',
      width: 80,
      type: 'string',
      visibleColumn: false,
      allowHiding: false,
      formItem: {
        visible: false
      }
    },
    {
      field: 'title',
      title: 'Title',
      width: 360,
      type: 'string',
      isRequired: true
    },
    {
      field: 'code',
      title: 'Code',
      width: 220,
      type: 'string',
      allowHiding: false,
      isRequired: true
    },
    {
      field: 'work_request_type_id',
      title: 'Parent Activity',
      // width: 280,
      type: 'string',
      allowHiding: true,
      isReQuired: true,
      Lookup: {
        dataSource: parentActivities,
        valueExpr: 'id',
        displayExpr: 'text'
      }
    },
    {
      field: 'department_id',
      title: 'Department',
      type: 'string',
      allowHiding: true,
      isRequired: true,
      setCellValue: setDepartmentValue,
      Lookup: {
        dataSource: depts,
        valueExpr: 'department_id',
        displayExpr: 'department_name'
      }
    },
    {
      field: 'team_ids',
      title: 'Team',
      // width: 280,
      type: 'string',
      allowHiding: true,
      setCellValue: setTeamValue,
      Lookup: {
        dataSource: getFilteredTeams,
        valueExpr: 'team_ids',
        displayExpr: 'team_name'
      },
      cellRender: (cellData: any) => {
        return cellData.data.team_titles
      },
      formItem: {
        visible: true,
        editorType: 'dxTagBox',
        editorOptions: {
          //dataSource: getFilteredTeams,
          displayExpr: 'team_name',
          valueExpr: 'team_ids',
          placeholder: 'Select Team',
          showSelectionControls: true
        }
      }
    },
    {
      field: 'work_group_ids',
      title: 'Work Group',
      // width: 280,
      type: 'string',
      allowHiding: true,
      setCellValue: setWorkGroupValue,
      Lookup: {
        dataSource: workGroups,
        valueExpr: 'work_group_ids',
        displayExpr: 'workgroup_name'
      },
      visibleColumn: false,
      formItem: {
        visible: true,
        editorType: 'dxTagBox',
        editorOptions: {
          //dataSource: getFilteredTeams,
          displayExpr: 'workgroup_name',
          valueExpr: 'work_group_ids',
          placeholder: 'Select Work Group',
          showSelectionControls: true
        }
      }
    },
    {
      field: 'category_id',
      title: 'Activity Category',
      width: 120,
      type: 'string',
      allowHiding: true,
      setCellValue: setCategoryValue,
      Lookup: {
        dataSource: woSubcategoryItems,
        valueExpr: 'category_id',
        displayExpr: 'category_text'
      }
    },
    {
      field: 'project_id',
      title: 'Project ID',
      width: 120,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'task_id',
      title: 'Task ID',
      width: 120,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'activity',
      title: 'Activity',
      width: 120,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'cost_centre',
      title: 'Cost Centre',
      width: 120,
      type: 'string',
      allowHiding: true
    },
    {
      field: 'tcp',
      title: 'TCP',
      width: 120,
      type: 'boolean',
      allowHiding: true,
      calculateCellValue: (data: any) => data.tcp ?? false, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.tcp = value
      }
    },
    {
      field: 'risk_assessment_required',
      title: 'Site Risk Assessment',
      width: 120,
      type: 'boolean',
      allowHiding: true,
      setCellValue: toggleRiskAssessmentValue,
      calculateCellValue: (data: any) => data.risk_assessment_required ?? false,
    },
    {
      field: 'high_risk',
      title: 'High Risk',
      width: 120,
      type: 'boolean',
      allowHiding: true,
      setCellValue: toggleHighRiskValue,
      calculateCellValue: (data: any) => data.high_risk ?? false,
    },
    {
      field: 'reuse_risk_assessment_for_days',
      title: 'Reuse Risk Assessment',
      width: 120,
      type: 'boolean',
      allowHiding: true,
      setCellValue: toggleReuseRiskAssessmentValue,
      calculateCellValue: (data: any) => data.reuse_risk_assessment_for_days ?? false,
    },
    {
      field: 'photo_required_before',
      title: 'Before Photo Required',
      width: 120,
      type: 'boolean',
      allowHiding: true,
      formItem: {
        visible: true,
        colSpan: 4
      },
      calculateCellValue: (data: any) => data.photo_required_before ?? false,
    },
    {
      field: 'photo_required_after',
      title: 'After Photo Required',
      width: 120,
      type: 'boolean',
      allowHiding: true,
      formItem: {
        visible: true,
        colSpan: 4
      },
      calculateCellValue: (data: any) => data.photo_required_after ?? false,
    },
    {
      field: 'allow_fast_complete',
      title: 'Fast Complete',
      width: 120,
      type: 'boolean',
      allowHiding: true,
      calculateCellValue: (data: any) => data.tcp ?? false, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.tcp = value
      }
    },
    {
      field: 'allow_fast_create',
      title: 'Fast Create',
      width: 120,
      type: 'boolean',
      allowHiding: true,
      calculateCellValue: (data: any) => data.allow_fast_create ?? false, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.allow_fast_create = value
      }
    },
    {
      field: 'soft_complete',
      title: 'Soft Complete',
      width: 120,
      type: 'boolean',
      visibleColumn: false,
      allowHiding: true,
      calculateCellValue: (data: any) => data.soft_complete ?? false, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.soft_complete = value
      }
    },
    {
      field: 'is_active',
      title: 'Active',
      width: 120,
      type: 'boolean',
      allowHiding: true,
      calculateCellValue: (data: any) => data.is_active ?? true, // Default to true
      setCellValue: (newData: any, value: any) => {
        newData.is_active = value
      }
    },
    {
      field: 'form_id',
      title: 'Form',
      width: 120,
      type: 'string',
      allowHiding: true,
      visibleColumn: false,
      formItem: {
        visible: true
      },
      setCellValue: setFormValue,
      Lookup: {
        dataSource: forms,
        valueExpr: 'form_id',
        displayExpr: 'form_name'
      }
    },
    {
      field: 'risk_form_id',
      title: 'Risk Form',
      width: 120,
      type: 'string',
      allowHiding: true,
      visibleColumn: false,
      formItem: {
        visible: true,
      },
      setCellValue: setRiskFormValue,
      Lookup: {
        dataSource: riskForms,
        valueExpr: 'risk_form_id',
        displayExpr: 'risk_form_name'
      }
    },
    {
      field: 'risk_group_ids',
      title: 'Risk Group',
      width: 120,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
       setCellValue: setRiskGroupIdValue,
      editCellComponent: (props: any) => (
        <TreeViewComponent
          cellData={props}
          treeViewItems={props.data?.data?.transformedRiskCategories}
          onUpdateSelectedKeys={onUpdateSelectedKeys}
          selectedKeys={selectedKeys}
        />
      ),
      formItem: {
        visible: true,
        colspan: 2
      }
    },
    {
      field: 'updated',
      title: 'Updated',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      sortOrder: 'desc',
      formItem: {
        visible: false
      }
    },
    {
      field: 'updated_by',
      title: 'Updated By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    },
    {
      field: 'created',
      title: 'Created',
      width: 160,
      type: 'datetime',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    },
    {
      field: 'created_by',
      title: 'Created By',
      width: 160,
      type: 'string',
      visibleColumn: false,
      allowHiding: true,
      formItem: {
        visible: false
      }
    }
  ]

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const formResponse = await utility.genericGetAPICall(`${Config.SearchFormList}`)
        const formData = formResponse
          .filter((r: any) => r.is_active === true)
          .map((r: any) => ({
            form_id: r.id,
            form_name: r.form_name,
            'Select All': r.form_name
          }))
        const sortedFormData = utility._sortItems(formData, 'text', false)
        setForms(sortedFormData)

        const workGroupResponse: any = await utility.genericGetAPICall(Config.workGroup)
        const workGroupData = workGroupResponse.map((r: any) => ({
          work_group_ids: r.id,
          workgroup_name: r.title,
          description: r.description,
          erp_id: r.erp_id,
          default_skill_id: r.default_skill_id,
          'Select All': r.title
        }))
        const sortedWorkgroupData = utility._sortItems(workGroupData, 'workgroup_name', false)
        setWorkGroups(sortedWorkgroupData)

        const riskFormResponse: any = await utility.genericGetAPICall(Config.RiskForms)
        const riskFormData = riskFormResponse
          .filter((r: any) => r.is_active === true)
          .map((r: any) => ({ risk_form_id: r.id, risk_form_name: r.title }))
        const sortedItems: any = utility._sortItems(riskFormData, 'risk_form_name', false)
        setRiskForms(sortedItems)

        const deptResponse = await utility.genericGetAPICall(`${Config.DepartmentList}`)
        const deptData = deptResponse
          .filter((r: any) => r.is_active === true)
          .map((r: any) => ({
            department_id: r.id,
            department_name: r.title
          }))
        setDepts(deptData)

        const teamResponse = await utility.genericGetAPICall(Config.TeamList)
        const teamData = teamResponse.map((r: any) => ({
          team_ids: r.id,
          team_name: r.title,
          department_id: r.department_id
        }))
        setTeams(teamData)

        const workReqResponse: any = await utility.genericGetAPICall(Config.WRTlistname)
        let parentActivityItemsData = workReqResponse.map((item: any) => {
          return {
            id: item.id,
            text: item.title,
            departmentId: item.department_id,
            code: item.code
          }
        })
        const sortedIWRtems: any = utility._sortItems(parentActivityItemsData, 'text', false)
        setParentActivities(sortedIWRtems)

        setLoading(false)
        setDataLoaded(true)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const onEditorPreparing = (e: any) => {
    if (e.parentType === 'dataRow') {
      const rowData = e.row.data;
    
      if (e.dataField === 'risk_assessment_required') {
        e.editorOptions.disbled = false;
      }
  
      if (e.dataField === 'high_risk') {
       e.editorOptions.disabled =  rowData.reuse_risk_assessment_for_days;
      }
  
      if (e.dataField === 'reuse_risk_assessment_for_days') {
        e.editorOptions.disabled = (rowData.high_risk || !rowData.risk_assessment_required);
      }
    }
  };
  
  const dataSource = new CustomStore({
    load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
        const riskCategoriesData: any = await utility.genericGetAPICall(Config.RiskCategorieswithGroups)
        const riskCategoriesDataStructure = transformToTreeStructure(riskCategoriesData, [])
        console.log('Initial risk categories data:', riskCategoriesDataStructure)
        //setRiskCategories(riskCategoriesDataStructure)

        return await utility.genericGetAPICallForList(Config.SearchActivitiesApi, 'POST', {}).then((data: any) => {
          console.log('Fetched data from API:', data)
          const transformedData = data.map((item: any) => {
              const selectedRiskGroupIds = item.risk_group_ids;
              const transformedRiskCategories = selectedRiskGroupIds && selectedRiskGroupIds.length > 0 
              ? transformToTreeStructure(riskCategoriesData, selectedRiskGroupIds) 
              : riskCategoriesDataStructure; // Use default structure if no selection
            return {
              ...item,
              transformedRiskCategories,
              team_titles: item.team_ids
            ? item.team_ids.map((t: any) => teams?.find((td:any) => td.team_ids === t)?.team_name).join(', ')
            : ''
            }
          })

          console.log('Transformed data with risk categories:', transformedData)

          return {
            data: transformedData,
            totalCount: transformedData.length
          }
        })
      },
    key: 'id',
    onLoaded: () => {
      console.log('Data loaded')
      setLoading(false)
      setDataLoaded(true)
    },
    insert: async (values: any) => {
      try {
        if (values.risk_group_ids) {
          values.risk_group_ids = values.risk_group_ids.map((item: any) => {
            if (typeof item === 'string' && item.startsWith("group-")) {
              return Number(item.replace("group-", ""));
            }
            return Number(item); // Ensure that item is returned as a number
          });
        }
        await utility.genericPostAPICall(Config.WTlistname, values)
        //await fetchActivitiesData()
      } catch (error: any) {
        if (error instanceof Error && error.message.startsWith('Network response was not ok')) {
          try {
            const errorData = JSON.parse(error.message.replace('Network response was not ok: ', ''))
            const errorMessage = utility.extractErrorMessage(errorData)
            throw new Error(errorMessage)
          } catch (parseError) {
            throw new Error('Failed to process error response.')
          }
        } else {
          throw new Error('Failed to post data.')
        }
      }
    },
    update: async (key: any, values: any) => {
      try {
        if (values.risk_group_ids) {
          values.risk_group_ids = values.risk_group_ids.map((item: any) => {
            if (typeof item === 'string' && item.startsWith("group-")) {
              return Number(item.replace("group-", ""));
            }
            return Number(item); // Ensure that item is returned as a number
          });
        }
        
        await utility.genericUpdatePatchAPICall(Config.WTlistname, key, values)
        //await fetchActivitiesData()
      } catch (error: any) {
        throw error
      }
    },
    remove: async (key: any) => {
      try {
        await utility.genericDeleteAPICall(Config.WTlistname, key)
        //await fetchActivitiesData()
      } catch (error: any) {
        throw error
      }
    }
  })

  return (
    <>
      <div>
        {!loading ? (
          <div className='results-container'>
            <DataTable
              dataSource={dataSource}
              style={{ width: '100%' }}
              filteralbe={true}
              groupable={true}
              columns={columns}
              fileName={'Activities'}
              columnChooser={'ActivityColumns'}
              selectionMode={false}
              showHideColumnMenu={true}
              loading={loading}
              allowExportSelectedData={false}
              columnHidingEnabled={true}
              showCommandBtn={false}
              hideSearchPanel={false}
              hideCommandDeleteBtn={false}
              showNewBtn={false}
              showViewDetailBtn={false}
              hideNewBtn={true}
              defaultfiltervalue={filterValue}
              showMoreBtn={true}
              visible={dataLoaded}
              onEditorPreparing={onEditorPreparing}
            />
          </div>
        ) : (
          <Spinner size='large' className='loader' />
        )}
      </div>
    </>
  )
}

export default Activities
