import React, { useState, useEffect } from 'react';
import utility from '../../../classes/utility';
import { Config } from '../../../classes/config';
import DataTable from '../../Common/CustomASDataGrid';
import Spinner from '../../Common/ReusableComponents/Spinner';
import global from '../../../classes/global';
import CustomStore from "devextreme/data/custom_store";
import { LoadOptions } from "devextreme/data";

interface LoadResult {
    data: any[];
    totalCount: number;
  }

interface DefectCodeProps {
    currentUser: any;
    role: string;
    hideNewBtn: boolean;
}


const DefectCode = (props: DefectCodeProps) => {

    const [items, setItems] = useState<any>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
   
    const columns = [
        {
            field: 'id',
            title: 'ID',
            width: 80,
            type: 'string',
            allowHiding: false,
            visibleColumn: false,
            formItem: {
                visible: false,
            }
        },
        {
            field: 'code',
            title: 'Code',
            // width: 280,
            type: 'string',
            allowHiding: false,
            isRequired: true,
        },
        {
            field: 'title',
            title: 'Title',
            // width: 160,
            type: 'string',
            allowHiding: false,
            isRequired: true,
        },
        {
            field: 'asset_register_id',
            title: 'Asset Register',
            // width: 160,
            type: 'string',
            allowHiding: true,
            formItem: {
                colSpan: 4,
                editorType: 'dxTextBox',
                editorOptions: {
                    value: '',
                    valueExpr: 'asset_register_id',
                    displayExpr: 'asset_register_title',
                    dataSource: items
                }
            }
        },
        {
            field: 'asset_class',
            title: 'Asset Class',
            // width: 160,
            type: 'string',
            allowHiding: true
        },
        {
            field: 'asset_classification_type',
            title: 'Asset Classification Type',
            // width: 160,
            type: 'string',
            allowHiding: true,
        },
        {
            field: 'created',
            title: 'Created',
            width: 160,
            type: 'datetime',
            visibleColumn: false,
            allowHiding: true,
            sortOrder: 'desc',
            formItem: {
                visible: false,
            }
        },
        {
            field: 'created_by',
            title: 'Created By',
            width: 160,
            type: 'string',
            visibleColumn: false,
            allowHiding: true,
            formItem: {
                visible: false,
            }
        },
    ];

    useEffect(() => {
      fetchItemsData()
    }, [])

    const fetchItemsData = async() => {
      setLoading(true)
      try {
        const itemsResponse = await utility.genericGetAPICall(Config.DefectCodeList);
        let items: any[] = []
  
        for (let item of itemsResponse) {
          items.push({
            ...item,
            asset_register_id: item.asset_register ? item.asset_register.id : null,
            asset_register_title: item.asset_register ? item.asset_register.title : '',
          })
        }
        items = utility._sortItems(items, 'id', true)
        setItems(items)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    const dataSource = new CustomStore({
        load: async (loadOptions: LoadOptions): Promise<LoadResult> => {
            // return await utility.genericGetAPICall(Config.DefectCodeList).then((defectData) => {
            // const transformedData = defectData.map((item:any) => ({
            //     ...item,
            //     asset_register_id: item.asset_register ? item.asset_register.id : null,
            //     asset_register_title: item.asset_register ? item.asset_register.title : '',
            // }));
            // const sortedItems = utility._sortItems(transformedData, 'created', true);
            // setDefects(sortedItems);
            // setLoading(false);
            return {
                data: items,
                totalCount: items.length
            };
            // });
          
        },
        key: 'id',
        onLoaded: () => {
          console.log('Data loaded');
          setLoading(false);
          setDataLoaded(true);
        },
        insert: async (values: any) => {
          try {
            await utility.genericPostAPICall(Config.DefectCodeList, values);
          } catch (error:any) {
            if (error instanceof Error && error.message.startsWith('Network response was not ok')) {
              try {
                const errorData = JSON.parse(error.message.replace('Network response was not ok: ', ''));
                const errorMessage = utility.extractErrorMessage(errorData);
                throw new Error(errorMessage);
              } catch (parseError) {
                throw new Error('Failed to process error response.');
              }
            } else {
              throw new Error('Failed to post data.');
            }
        
          }
        },
        update: async (key: any, values: any) => {
          try {
            await utility.genericUpdatePatchAPICall(Config.DefectCodeList, key, values);
          } catch (error:any) {
            throw error;
          }
        },
        remove: async (key: any) => {
          try {
            await utility.genericDeleteAPICall(Config.DefectCodeList, key);
          } catch (error:any) {
            throw error;
          }
        }
      });
    
      return (
        <>
          <div>
            {/* {!loading ? ( */}
              <div className='results-container'>
                <DataTable
                  dataSource={dataSource}
                  style={{ width: '100%' }}
                  filteralbe={true}
                  groupable={true}
                  columns={columns}
                  fileName={'DefectCodes'}
                  columnChooser={'defectCodeColumns'}
                  selectionMode={false}
                  showHideColumnMenu={true}
                  loading={loading}
                  allowExportSelectedData={false}
                  columnHidingEnabled={true}
                  showCommandBtn={false}
                  hideSearchPanel={false}
                  hideCommandDeleteBtn={false}
                  showNewBtn={false}
                  showViewDetailBtn={false}
                  hideNewBtn={true}
                  //defaultfiltervalue={filterValue}
                  showMoreBtn={true}
                  visible={dataLoaded}
                />
              </div>
            {/* ) : (
              <Spinner size='large' className='loader' />
            )} */}
          </div>
        </>
      );
    };
   
export default DefectCode;