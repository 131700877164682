import React from 'react'
import { ButtonGroup } from 'devextreme-react'

interface Props {
  items?: any
  keyExpr?: string | any
  stylingMode?: string | any
  selectedItemKeys?: any
  onItemClick: any
}

const ButtonGroupItem = (props: Props) => {
  return (
    <ButtonGroup
      items={props.items}
      keyExpr={props.keyExpr}
      stylingMode={props.stylingMode}
      selectedItemKeys={props.selectedItemKeys}
      onItemClick={props.onItemClick}
    />
  )
}

export default ButtonGroupItem
