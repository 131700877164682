import React from 'react';
export default class ClearStorageService {
    clearStorage() {
      sessionStorage.clear();
      var indexedDB = window.indexedDB;
      var request = indexedDB.deleteDatabase("coa-worxOnline");
      request.onsuccess = () => {
        console.log('session & indexdb storage has been cleared successfully.');
      };
      request.onerror = () => {
        console.error('Cannot clear session & indexdb storage.');
      };
    }
  }