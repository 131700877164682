import React, { version } from 'react'

interface Global {
  homeURL: string
  spAPIURL: any
  AuthAPIURL: any
  AssetMapURL: string
  mapIconFolderURL: string
  //singInB2C: string
  //singInB2CKnownAuthority: string
  // AzureClientAppId: string
  // AzureTenantId: string
  // AzureScope: string
  AsseticAssetURL: string
  accessToken: string
  isAzurefile: string
  removesession: string
  sessionenv: string
  version: string
  filterWOType: string
  topicName: string
  subscriptionName: string
  AsseticWOLink: string
  AsseticWRLink: string
  worxOnline_Admin: string
  worxOnline_Writer: string
  worxOnline_Contractor: string
  worxOnline_Reader: string
  worxOnline_ContractorAdmin: string
  Assigned: string
  Unassigned: string
  Reassigned: string
  Complete: string
  Closed: string
  defaultResultCount: number
  googleAPIKey: string
  showPathwaylink: string
  HelpfulLinks: string
  ResultCount: number
  Userid: string
  costCenter: string

}

const global: Global = {
  homeURL: 'http://localhost:3000/',
  //homeURL: 'http://dev.worx.online/ ',

  //const homeURL: 'https://coa-uat-worx-portal.azurewebsites.net/'
  //spAPIURL: 'https://coa-uat-worx-api.azurewebsites.net',
  spAPIURL: 'https://dev.worx.online/api',
  AuthAPIURL: 'https://dev.worx.online/api/auth/access-token', //uat
  AssetMapURL: 'https://coauatworxstr.z26.web.core.windows.net/',
  mapIconFolderURL: 'https://coauatworxstr.blob.core.windows.net/worxonline-container-uat/Images/MapIcon',
  //singInB2C: 'https://w0rx.b2clogin.com/w0rx.onmicrosoft.com/B2C_1_COA_SignupAndSignin',
  //singInB2CKnownAuthority: 'w0rx.b2clogin.com',
  //AzureClientAppId: 'a4288a2c-9c74-414d-8555-3508489d1d88',
  //AzureTenantId: '777c851c-c2df-4f6c-b1ad-0ba6f8a4059e',
  //AzureScope: 'https://w0rx.onmicrosoft.com/Coa-api/.default',
  AsseticAssetURL: 'https://coasandbox.assetic.net/assets/ComplexAsset/',
  accessToken: 'uataccesstoken',
  isAzurefile: 'https://coauatworxstr.blob.core.windows.net/',
  removesession: '-prod|',
  sessionenv: '-test',
  version: '2.4',
  filterWOType: 'Reactive',
  topicName: 'workordercreated',
  subscriptionName: 's1',
  AsseticWOLink: 'https://coasandbox.assetic.net/maintenance/Workorder/',
  AsseticWRLink: 'https://coasandbox.assetic.net/maintenance/Workrequest/',
  worxOnline_Admin: 'Admin',
  worxOnline_Writer: 'Write',
  worxOnline_Contractor: 'Contractor',
  worxOnline_Reader: 'Read',
  worxOnline_ContractorAdmin: 'ContractorAdmin',
  Assigned: 'Assigned',
  Unassigned: 'Unassigned',
  Reassigned: 'Reassigned',
  Complete: 'Complete',
  Closed: 'Closed',
  defaultResultCount: 100,
  googleAPIKey: 'AIzaSyCbfOSuVu7s0bTruq2ZRClKVuX56RUKFtI',
  showPathwaylink: 'No',
  HelpfulLinks: 'Helpful Links',
  ResultCount: 150,
  Userid: '11726',
  costCenter: '26020',
}

export default global
